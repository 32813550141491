import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { Tag } from '@app/models/tag.model';

const ApiPath = '/tags';

@Injectable({
    providedIn: 'root'
})

export class TagService {

    constructor(private http: HttpClient) {
    }

    get(id: number) {
        return this.http.get<DataApiResponse<Tag>>(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    list(page: number, count: number, searchText: string, sort?: SortModel) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        
        return this.http.get<ListResponse<Tag[]> | ApiResponse>(
            `${environment.apiUrl}${ApiPath}?page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}`,)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<Tag[]>;
            }), catchError(handleApiError));
    }

    save(tag: Tag) {
        return this.http.post<DataApiResponse<Tag>>(
            `${environment.apiUrl}${ApiPath}`, tag)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }

    delete(id: number) {
        return this.http.delete(
            `${environment.apiUrl}${ApiPath}/delete/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    getTagsByModule(module: string, count: any) {
        return this.http.get<DataApiResponse<Tag[]>>(
            `${environment.apiUrl}${ApiPath}/${module}?count=${count}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    assignTags(tags: any) {
        return this.http.post<DataApiResponse<Tag>>(
            `${environment.apiUrl}${ApiPath}/assign`, tags)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }
    
    removeAssignTags(tags: any) {
        return this.http.post<DataApiResponse<Tag>>(
            `${environment.apiUrl}${ApiPath}/remove`, tags)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }
}
