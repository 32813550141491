import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

import { environment } from '@environments/environment';

type KEYS = 'short' | 'long';
type OBJECTS = 'now' | 'minute' | 'minutes' | 'hour' | 'hours' | 'days' | 'day';

const labels: Record<KEYS, Record<OBJECTS, string>> = {
    short: {
        now: 'Now',
        minute: 'm',
        minutes: 'm',
        hour: 'h',
        hours: 'h',
        day: 'd',
        days: 'd',
    },
    long: {
        now: 'Now',
        minute: 'minute',
        minutes: 'minutes',
        hour: 'hour',
        hours: 'hours',
        day: 'day',
        days: 'days',
    },
};

@Pipe({ name: 'timeSince' })
export class TimeSincePipe implements PipeTransform {
    transform(value: string, short = false, past = false): string {
        if (value == null) {
            return undefined;
        }
        const key = short ? 'short' : 'long';
        const spacer = short ? '' : ' ';

        const date = environment.apiDatesUTC ? moment.utc(value).local() : moment(value).local();
        const now = moment().local();
        let diffMinutes = now.diff(date, 'minutes');
        if (past) {
            diffMinutes = Math.abs(diffMinutes);
        }

        if (diffMinutes === 0) {
            return labels[key].now;
        }

        let diffHours = now.diff(date, 'hours');
        if (past) {
            diffHours = Math.abs(diffHours);
        }


        if (diffMinutes > 0 && diffHours === 0) {
            return `${diffMinutes}${spacer}${diffMinutes === 1 ? labels[key].minute : labels[key].minutes}${past ? ' ago' : ''}`;
        }

        let diffDays = now.diff(date, 'days');
        if (past) {
            diffDays = Math.abs(diffDays);
        }

        if (diffHours > 0 && diffDays === 0) {
            return `${diffHours}${spacer}${diffHours === 1 ? labels[key].hour : labels[key].hours}${past ? ' ago' : ''}`;
        }

        return `${diffDays}${spacer}${diffDays === 1 ? labels[key].day : labels[key].days}${past ? ' ago' : ''}`;
    }
}
