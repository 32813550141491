<input class="form-control m-l-10 search-contact-input"
       type="text"
       autocomplete="off"
       [placeholder]="placeholder"
       [title]="placeholder"
       #searchContactText
       [(ngModel)]="autocompleteInput"
       [ngModelOptions]="{standalone: true}"
       [resultFormatter]="formatContact"
       [inputFormatter]="nullFormatter"
       [ngbTypeahead]="search"
       (selectItem)="onTypeaheadSelected($event)"
       [resultTemplate]="rt"
>
<!--
<button class="btn btn-primary btn-sm m-t-10 m-b-10 m-l-10" type="button" placement="top" *ngIf="autocompleteInput"
        ngbTooltip="Create new contact" (click)="createContact()">Create contact
</button>-->

<ng-template #rt let-r="result" let-t="term">
    <app-feather-icons class="m-r-5" [icon]="'user'" *ngIf="r.i_contact"></app-feather-icons>
    <app-feather-icons class="m-r-5" [icon]="'target'" *ngIf="r.i_crm_lead"></app-feather-icons>
    <ngb-highlight [result]="formatTypeahead(r)" [term]="t"></ngb-highlight>
</ng-template>
