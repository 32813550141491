import { Pipe, PipeTransform } from '@angular/core';
import { EsAgent } from '@app/models/es-agent.model';
import { CandidateSelection } from '@app/models/agent-selection.model';

@Pipe({ name: 'candidate_selection' })
export class CandidateSelectionPipe implements PipeTransform {
    transform(value: CandidateSelection): string {
        if (value == null) {
            return undefined;
        }
        return `Request #${value.i_candidate_selection}`;
    }
}
