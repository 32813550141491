import { Call, ChatMessage, ChatUser, Direction } from '@app/models/chat.model';
import * as moment from 'moment';
import { DateFormat } from '@app/shared/helpers/util';

export function transformPhone(num: string) {
    return (num ?? '').replace('_', '+');
}

export function transformSubject(subject: string) {
    return (subject ?? '')
        .replace('__', ' +')
        .replace(/_/g, ' ')
        .replace(' from New JustCall', ' ');
}

export function transformUrl(url: string) {
    return (url ?? '').replace(/_/g, '.');
}

export function getDisplayName(user: ChatUser | Call) {
    return (user?.contact ? `${user?.contact?.first_name} ${user?.contact?.last_name}`.trim() : null) ||
        (user?.lead ? `${user?.lead?.first_name} ${user?.lead?.last_name}`.trim() : null) ||
        user?.agent?.agent_name || user?.contact_name;
}

export function getDisplayDate(str: string) {
    const today = moment().format(DateFormat);
    const yesterday = moment().subtract(1, 'days').format(DateFormat);

    if (str == null) {
        return str;
    }
    if (str === today) {
        return 'Today';
    } else if (str === yesterday) {
        return 'Yesterday';
    } else {
        return str;
    }
}

export function isIncoming(message: ChatMessage) {
    return message.direction === Direction.Incoming;
}
