<input class="form-control"
       type="text"
       autocomplete="off"
       [placeholder]="placeholder"
       [title]="placeholder"
       #searchContactText
       [(ngModel)]="autocompleteInput"
       [ngModelOptions]="{standalone: true}"
       [resultFormatter]="formatAgent"
       [inputFormatter]="nullFormatter"
       [ngbTypeahead]="search"
       (selectItem)="onTypeaheadSelected($event)"
       [resultTemplate]="rt"
>

<ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="formatTypeahead(r)" [term]="t"></ngb-highlight>
</ng-template>
