import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '@app/models/address.model';
import * as moment from 'moment';
import { percentFormatter } from '@app/shared/helpers/util';

@Pipe({ name: 'rrpercent' })
export class RRPercentPipe implements PipeTransform {
    transform(value: number): string {
        if (value == null) {
            return undefined;
        }
        return percentFormatter({ value });
    }
}
