import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';

import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

import {AuthenticationService} from '@app/shared/services/auth.service';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public newUser = false;
    public loginForm: FormGroup;
    public formErrors: FormErrors = {
        email: '',
        password: '',
    };
    public errorMessage: any;
    public returnUrl: string;
    public showLoader = false;

    constructor(
        public toaster: ToastrService,
        public authService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
    ) {
        this.loginForm = fb.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required]
        });
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    }

    ngOnInit() {
        const token = this.route.snapshot.queryParamMap.get('token');
        if (token != null) {
            this.authService.trySetToken(token).subscribe( () => this.router.navigate(['/']));
        }
    }

    get f() {
        return this.loginForm.controls;
    }

    login() {
        this.showLoader = true;
        this.authService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.showLoader = false;
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    console.log(error);
                    this.showLoader = false;
                    this.errorMessage = error;
                    this.toaster.error(error);
                });
    }

    loginGoogle() {
        this.authService.loginGoogle();
    }
}
