import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatImage } from '@app/shared/components/chat-image/chat-image.component';
import { AttachmentService } from '@app/shared/services/attachment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-mms',
    templateUrl: './mms.component.html',
})
export class MmsComponent {
    @Input() uploadedFiles: ChatImage[] = [];
    @Output() filesUploaded: EventEmitter<ChatImage[]> = new EventEmitter<ChatImage[]>();

    constructor(
        private attachmentService: AttachmentService,
        private toaster: ToastrService,
    ) {
    }

    onSelect(event) {
        for (const file of event.addedFiles) {
            this.attachmentService.uploadAttachment(file).subscribe(
                url => {
                    const item = {
                        url,
                        filename: file.name,
                        mimetype: file.type,
                    };
                    this.uploadedFiles.push(item);
                    this.filesUploaded.next([item]);
                },
                err => this.toaster.error(err),
            );
        }
    }
}
