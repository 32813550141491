import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormulaOperation } from '@app/shared/components/formula-editor/formula-editor.component';

@Component({
    selector: 'app-formula-item',
    templateUrl: './formula-item.component.html',
    styleUrls: ['./formula-item.component.scss']
})
export class FormulaItemComponent implements OnInit{
    @Input() index = 0;
    @Input() operation: FormulaOperation;
    @Input() isLast = false;
    @Input() level;
    @Input() isActive = false;

    ngOnInit(): void {
    }

    toggleOperation() {
        if (this.operation === 'AND') {
            this.operation = 'OR';
        } else {
            this.operation = 'AND';
        }
    }
}
