<!-- Right sidebar Start-->
<div (mouseleave)="closeSidebar()">

    <div class="container p-0">
        <div class="modal-header p-l-20 p-r-20">
            <div class="col-sm-8 p-0">
                <h6 class="modal-title font-weight-bold">Messages</h6>
            </div>
            <div class="col-sm-4 text-right p-0 onhover-dropdown">
                <i class="mr-2" data-feather="settings"></i>
                <ul class="settings-dropdown onhover-show-div p-20">
                    <li><a (click)="markAllRead()">Mark All as Read</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="friend-list-search mt-0">
        <input class="form-control" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
            (keyup)="searchTerm(searchText)" type="text" placeholder="search"><i class="fa fa-search"></i>
    </div>
    <div class="p-l-10 p-r-5">

        <div class="chat-box">
            <div class="people-list friend-list custom-scrollbar">
                <ul class="list messages-list">
                    <ng-container *ngFor="let user of searchUsers">
                        <li class="clearfix">
                            <a (click)="openUserChat(user)">
                                <app-avatar class="float-left"
                                            [src]="utilService.getAvatar(user)"
                                            [riseOnHover]="false"
                                            [name]="utilService.getDisplayName(user)"
                                            (avatarClicked)="utilService.userAvatarClicked(user, $event)"></app-avatar>
                                <div class="unread-count" *ngIf="user?.sms_unread_count">{{ user?.sms_unread_count }}</div>
                                <div class="unread-count-email" *ngIf="user?.email_unread_count"><i class="fa fa-envelope"></i></div>
                                <div class="about">
                                    <div class="name">{{ utilService.getDisplayName(user) }}</div>
                                    <div class="status">
                                        <div *ngIf="user.latest_message_content">
                                            <i class="fa fa-share font-success" *ngIf="user?.latest_direction === Outgoing"></i> <i
                                                class="fa fa-reply font-danger"
                                                *ngIf="user?.latest_direction === Incoming"></i>
                                            <span [innerHTML]="user.latest_message_content | ellipsis: 15 | unicode2html | safetext"></span></div>
                                        <div *ngIf="!user.latest_message_content && user.agent?.scanner.brokerage"
                                             [innerHTML]="user.agent?.scanner.brokerage | ellipsis: 15 | unicode2html | safetext">
                                        </div>
                                    </div>
                                </div>
                                <div class="txt-dark f-14 m-t-5 m-r-5 opacity-0-5 text-right m-l-a" *ngIf="user?.latest_message_datetime">
                                    {{ user?.latest_message_datetime | timeSince: true}}
                                </div>
                                <div class="m-r-5 clickable text-right m-l-a" title="New chat" *ngIf="!user?.latest_message_datetime">
                                    <i class="fa fa-pencil-square fa-2x font-primary"></i>
                                </div>
                            </a>

                        </li>
                    </ng-container>
                    <ng-container *ngIf="notFound">
                        <div class="image-not-found">
                            <div class="">
                                <img src="assets/images/search-not-found.png" alt="">
                                <p class="mb-0">Sorry, We didn't find any results matching this search</p>
                            </div>
                        </div>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>
    <!-- Right sidebar Ends-->