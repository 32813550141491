// tslint:disable:variable-name

export const TaskStatuses = ['Not Started', 'In Progress', 'Completed'] as const;
export type TaskStatus = typeof TaskStatuses[number];

export const TaskRelatedToOptions = ['Contact', 'Agent', 'Request', 'Address', 'Deal'] as const;
export type TaskRelatedTo = typeof TaskRelatedToOptions[number];

type PriorityValue = 0 | 1 | 2;

interface TaskPriority {
    readonly value: PriorityValue;
    readonly name: string;
}

export const TaskPriorities: readonly TaskPriority[] = Object.freeze([
    { value: 0, name: 'Low' },
    { value: 1, name: 'Medium' },
    { value: 2, name: 'High' },
]);

export interface Task {
    i_task: number;
    i_contact: number;
    i_request_contact?: number;
    contact?: string;
    address?: string;
    agent?: string;
    address_key: string;
    agent_id: string;
    title: string;
    related_to: TaskRelatedTo;
    status: TaskStatus;
    description: string;
    i_task_type: number;
    i_user: number;
    priority: number;
    due_date: string;
    isSelected?: boolean;
    request_contact?: string;
    i_candidate_selection?: number;
    notify_user: boolean;
}
