import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { Observable, ReplaySubject } from 'rxjs';
import { EmailTemplate } from '@app/models/email-template.model';

const ApiPath = '/email_template';

@Injectable({ providedIn: 'root' })
export class EmailTemplateService {
    constructor(private http: HttpClient) {
    }

    private listSubject = new ReplaySubject<EmailTemplate[]>(1);

    public get emailTemplates$(): Observable<EmailTemplate[]> {
        return this.listSubject.asObservable();
    }

    public fetchEmailTemplates() {
        this.list(0, 1000, '', { colId: 'name', sort: 'asc' })
            .subscribe(data => this.listSubject.next(data.data));
    }

    get(id: number) {
        return this.http.get<DataApiResponse<EmailTemplate>>(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    list(page: number, count: number, searchText: string, sort?: SortModel) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<ListResponse<EmailTemplate[]> | ApiResponse>(
            `${environment.apiUrl}${ApiPath}?page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}`,)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<EmailTemplate[]>;
            }), catchError(handleApiError));
    }

    update(emailTemplate: EmailTemplate) {
        return this.http.put<DataApiResponse<EmailTemplate>>(
            `${environment.apiUrl}${ApiPath}/${emailTemplate.i_email_template}`, emailTemplate)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(id: number) {
        return this.http.delete(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(emailTemplate: EmailTemplate) {
        return this.http.post<DataApiResponse<EmailTemplate>>(
            `${environment.apiUrl}${ApiPath}`, emailTemplate)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }
}
