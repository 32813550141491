import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { MessageTemplate } from '@app/models/message-template.model';
import { Observable, ReplaySubject } from 'rxjs';

const ApiPath = '/message_template';

@Injectable({ providedIn: 'root' })
export class MessageTemplateService {
    constructor(private http: HttpClient) {
    }

    private listSubject = new ReplaySubject<MessageTemplate[]>(1);

    public get messageTemplates$(): Observable<MessageTemplate[]> {
        return this.listSubject.asObservable();
    }

    public fetchMessageTemplates() {
        this.list(0, 1000, '', { colId: 'name', sort: 'asc' })
            .subscribe(data => this.listSubject.next(data.data));
    }

    get(id: number) {
        return this.http.get<DataApiResponse<MessageTemplate>>(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    list(page: number, count: number, searchText: string, sort?: SortModel) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<ListResponse<MessageTemplate[]> | ApiResponse>(
            `${environment.apiUrl}${ApiPath}?page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}`,)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<MessageTemplate[]>;
            }), catchError(handleApiError));
    }

    update(messageTemplate: MessageTemplate) {
        return this.http.put<DataApiResponse<MessageTemplate>>(
            `${environment.apiUrl}${ApiPath}/${messageTemplate.i_message_template}`, messageTemplate)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(id: number) {
        return this.http.delete(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(messageTemplate: MessageTemplate) {
        return this.http.post<DataApiResponse<MessageTemplate>>(
            `${environment.apiUrl}${ApiPath}`, messageTemplate)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }
}
