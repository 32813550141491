import { Pipe, PipeTransform } from '@angular/core';
import { formatMoney } from '@app/shared/helpers/util';

@Pipe({ name: 'thousandSuffix' })
export class ThousandSuffixPipe implements PipeTransform {
    transform(input: number): string {
        if (input == null) {
            return undefined;
        }

        if (isNaN(input)) {
            return undefined;
        }
        return formatMoney(input);
    }
}
