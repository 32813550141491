<div class="card user-profile" [ngClass]="{ 'condensed': isCondensed }">
    <div class="card-header" *ngIf="!isCondensed">
        <h5>Contact Information
            <button *ngIf="!isEdit" class="btn btn-primary btn-xs" type="button"
                    (click)="startEdit()">Edit
            </button>
            <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button"
                    (click)="save()">Save
            </button>
            <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button"
                    (click)="cancelEdit()">Cancel
            </button>
            <div class="pull-right">
                <app-toggle [state]="contact.is_email_enabled" (toggleClick)="emailEnabledChanged($event)"></app-toggle>
            </div>
            <div class="pull-right automation-label m-l-10">
                Email subscription
            </div>
            <div class="pull-right">
                <app-toggle [state]="contact.is_automation" (toggleClick)="automationChanged($event)"></app-toggle>
            </div>
            <div class="pull-right automation-label">
                Automation
            </div>
        </h5>
    </div>
    <div class="card-header" *ngIf="isCondensed">
        <h5>Contact Information<br>
            <button *ngIf="!isEdit" class="btn btn-primary btn-xs" type="button"
                    (click)="startEdit()">Edit
            </button>
            <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button"
                    (click)="save()">Save
            </button>
            <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button"
                    (click)="cancelEdit()">Cancel
            </button>
        </h5>
    </div>
    <div class="card-body">
        <div class="row">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>Preferred Name
                    </h6>
                    <div *ngIf="!isEdit">
                        {{ contact.preferred_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div>
                            <input class="form-control" type="text" [(ngModel)]="contact.preferred_name"
                                   ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div  [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>First Name
                    </h6>
                    <div *ngIf="!isEdit">
                        {{ contact.first_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div>
                            <input class="form-control" type="text" [(ngModel)]="contact.first_name"
                                   ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>Last Name
                    </h6>
                    <div *ngIf="!isEdit">
                        {{ contact.last_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div>
                            <input class="form-control" type="text" [(ngModel)]="contact.last_name"
                                   ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }" *ngIf="contact.location?.address_object && !isCondensed">
                <div class="ttl-info text-left">
                    <h6>Address
                    </h6>
                    <a href="javascript:void(0)" (click)="addressClicked()">{{ contact.location?.address_object | address}}</a>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Phone Numbers
                        <button *ngIf="isEdit" class="btn btn-primary btn-xs" type="button"
                                (click)="addPhone()">Add
                        </button>
                    </h6>
                    <div *ngIf="!isEdit">
                        <div *ngFor="let phone of phones" class="m-b-5">
                            <a *ngIf="config?.sms_voice_provider === 'Justcall'" class="d-md-inline d-none"
                               [href]="'justcall://' + phone.display | safeurl"><img src="assets/images/contact.png"
                                                                                     alt="" width="20"></a>
                            <app-twilio-caller *ngIf="config?.sms_voice_provider === 'Twilio'"
                                               [from]="contact?.twilio_number_assignment?.twilio_number?.twilio_phone ?? this.config.twilio_contacts_number"
                                               [to]="phone.value"></app-twilio-caller>
                            {{ phone.display | phone }} [{{ phone.type }}]
                            <span *ngIf="phone.value === primaryPhone">[primary]</span>
                        </div>
                    </div>
                    <ng-container *ngIf="isEdit">
                        <ng-container *ngFor="let phone of contact.phones; index as i">
                            <div class="row m-l-0 m-b-5">
                                <div class="col-sm-1">
                                    <div class="form-group">
                                        <div class="checkbox m-l-10">
                                            <input [(ngModel)]="phone.is_primary"
                                                   [ngModelOptions]="{standalone: true}"
                                                   type="checkbox"
                                                   [id]="'isPreferredPhone'+i"
                                                   (ngModelChange)="onPrimaryPhoneChange(phone)"
                                            />
                                            <label title="Primary" [for]="'isPreferredPhone'+i"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <input title="Phone" class="form-control edit-input" type="text" [(ngModel)]="phone.value"
                                                   ngModelOptions="{ standalone: true }" placeholder="Phone Number">
                                        </div>
                                        <div class="col-sm-12">
                                            <input title="Type" class="form-control edit-input" type="text" [(ngModel)]="phone.type"
                                                   ngModelOptions="{ standalone: true }" placeholder="Description">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5 m-t-10" type="button"
                                            (click)="deletePhone(phone)">Delete
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Email Addresses
                        <button *ngIf="isEdit" class="btn btn-primary btn-xs" type="button"
                                (click)="addEmail()">Add
                        </button>
                    </h6>
                    <div *ngIf="!isEdit">
                        <div *ngFor="let email of emails" class="m-b-5">
                            {{ email.value }} <span *ngIf="email.type">[{{ email.type }}]</span>
                            <span *ngIf="email.value === primaryEmail">[primary]</span>
                        </div>
                    </div>
                    <ng-container *ngIf="isEdit">
                    <ng-container *ngFor="let email of contact.emails; index as i">
                        <div class="row m-l-0 m-b-5">
                            <div class="col-sm-1">
                                <div class="form-group">
                                    <div class="checkbox m-l-10">
                                        <input [(ngModel)]="email.is_primary"
                                               [ngModelOptions]="{standalone: true}"
                                               type="checkbox"
                                               [id]="'isPreferredEmail'+i"
                                               (ngModelChange)="onPrimaryEmailChange(email)"
                                        />
                                        <label title="Primary" [for]="'isPreferredEmail'+i"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input class="form-control edit-input" type="text" [(ngModel)]="email.value"
                                               ngModelOptions="{ standalone: true }" placeholder="Email Address">
                                    </div>
                                    <div class="col-sm-12">
                                        <input class="form-control edit-input" type="text" [(ngModel)]="email.type"
                                               ngModelOptions="{ standalone: true }" placeholder="Description">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5 m-t-10" type="button"
                                        (click)="deleteEmail(email)">Delete
                                </button>
                            </div>
                        </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
