import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'normalizeFloat' })
export class NormalizeFloatPipe implements PipeTransform {
    transform(value: string): string {
        return value != null && typeof value === 'string'
            ? value.replace(/0+$/, '').replace(/\.$/, '')
            : value;
    }
}
