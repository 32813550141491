import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertNumberRandom' })
export class ConvertNumberRandomPipe implements PipeTransform {
    transform(value: string | number): number {
        if (value == null) {
            return undefined;
        }
        return typeof value === 'string'
            ? Number(value) + (Math.random() / 100000)
            : value;
    }
}
