import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { Lead } from '@app/models/lead.model';
import { Contact } from '@app/models/contact.model';
import { CandidateSelection } from '@app/models/agent-selection.model';
import { LineType } from '@app/lib/ranking/common';

@Injectable({ providedIn: 'root' })
export class LeadService {

    constructor(private http: HttpClient) {
    }

    list(page: number, count: number, searchText: string, sort?: SortModel, leadId?: number) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        const leadStr = leadId != null ? `&i_crm_lead=${leadId}` : '';
        return this.http.get<ListResponse<Lead[]> | ApiResponse>(
            `${environment.apiUrl}/lead?page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}${leadStr}`,)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<Lead[]>;
            }), catchError(handleApiError));
    }

    search(searchText: string, addressOnly = false) {
        return this.http.get<DataApiResponse<Lead[]>>(`${environment.apiUrl}/search/lead?address_only=${addressOnly ? 1 : 0}&search_text=${searchText}`)
            .pipe(map(data => data.data));
    }

    update(lead: Lead) {
        return this.http.put<DataApiResponse<Lead>>(
            `${environment.apiUrl}/lead/${lead.i_crm_lead}?tz_offset=${new Date().getTimezoneOffset()}`, lead)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    convert(lead: Lead, contact: Contact, lineType: LineType = 'region') {
        return this.http.post<DataApiResponse<{ contact: Contact, request: CandidateSelection, }>>(
            `${environment.apiUrl}/lead/${lead.i_crm_lead}/convert`, { contact, lineType })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    get(leadId: number) {
        return this.http.get<DataApiResponse<Lead>>(
            `${environment.apiUrl}/lead/${leadId}?tz_offset=${new Date().getTimezoneOffset()}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(leadId: number) {
        return this.http.delete(
            `${environment.apiUrl}/lead/${leadId}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(lead: Lead) {
        return this.http.post<DataApiResponse<Lead>>(
            `${environment.apiUrl}/lead?tz_offset=${new Date().getTimezoneOffset()}`, lead)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }

}
