<swal #editTag
      [showCancelButton]="false"
      [showConfirmButton]="false"
      [showCloseButton]="false"
      [width]="600"
      [heightAuto]="true"
      [focusCancel]="false"
      [focusConfirm]="false">
    <div *swalPortal>
        <div *ngIf="isShown">
            <div class="form-group row text-left align-items-center" *ngIf="tags">
                <div class="col-12 d-flex align-items-center">
                    <span class="m-r-10 tags-revert"><i class="fa fa-tags"></i></span>
                    <ng-select
                        [items]="tags"
                        [multiple]="true"
                        bindLabel="name"
                        bindValue="id"
                        placeholder="Select tag"
                        [clearable]="false"
                        [clearOnBackspace]="false"
                        [(ngModel)]="selectedTags"
                        class="w-100">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="tag-ng-value badge badge-{{ item.color }}" *ngFor="let item of items">
                                <span class="ng-value-label">
                                    {{ item.name }}
                                </span>
                                <span class="badge badge-{{ item.color }} ng-value-icon right" (click)="clear(item)" aria-hidden="true"> 
                                    <b>x</b>
                                </span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="float-right">
                <button class="btn btn-secondary m-b-0 m-r-10" (click)="cancel()">Cancel</button>
                <button class="btn btn-primary m-b-0" (click)="submit()">Save</button>
            </div>
        </div>
    </div>
</swal>
