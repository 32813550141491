import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { DataApiResponse, handleApiError } from '@app/shared/helpers/api';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
    constructor(
        private http: HttpClient,
    ) {
    }

    public uploadAttachment(file: File): Observable<string> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<DataApiResponse<string>>(`${environment.apiUrl}/attachment/upload`, formData)
            .pipe(map(data => {
                    return data.data;
                }),
                catchError(handleApiError));
    }

}

