<div class="row m-t-5 m-l-5">
    <div class="col-xs-1 m-r-5">
        <a [routerLink]="getLink()" target="_blank">
            <app-avatar [src]="avatar"
                        [name]="agentName"></app-avatar>
        </a>
    </div>
    <div class="col-xs-11 m-l-15 tl-agent-name">
        <div class="row"><span [innerHTML]="agentName | safetext"></span>&nbsp;({{ type }})</div>
        <div class="row" [innerHTML]="officeName | safetext"></div>
    </div>
</div>
