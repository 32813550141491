import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { CandidateSelection } from '@app/models/agent-selection.model';
import { Observable } from 'rxjs';
import { Task } from '@app/models/task.model';

@Injectable({ providedIn: 'root' })
export class SelectionService {

    constructor(private http: HttpClient) {
    }

    list(page: number, count: number, searchText: string, contactId: number,
         agentId: string, sort?: SortModel): Observable<ListResponse<CandidateSelection[]>> {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        const contactIdStr = contactId != null ? `&i_contact=${contactId}` : '';
        const agentIdStr = agentId != null ? `&agent_id=${agentId}` : '';
        return this.http.get<ListResponse<CandidateSelection[]>>(
            `${environment.apiUrl}/candidate_selection?page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}${contactIdStr}${agentIdStr}`)
            .pipe(
                catchError(handleApiError),
                map(data => data));
    }

    approve(item: CandidateSelection) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/candidate_selection/${item.i_candidate_selection}/approve`, {})
            .pipe(
                catchError(handleApiError),
                map(data => data));
    }

    get(candidateSelectionId: number) {
        return this.http.get<DataApiResponse<CandidateSelection>>(
            `${environment.apiUrl}/candidate_selection/${candidateSelectionId}/selection`,
        ).pipe(
            catchError(handleApiError),
            map(res => res.data)
        );
    }

    getByAddress(contactId: number, addressId: string) {
        return this.http.get<DataApiResponse<CandidateSelection[]>>(
            `${environment.apiUrl}/candidate_selection/${contactId}/address/${addressId}`,
        ).pipe(
            catchError(handleApiError),
            map(res => res.data)
        );
    }

    getByContact(contactId: number, type: 'buyer' | 'all') {
        return this.http.get<DataApiResponse<CandidateSelection[]>>(
            `${environment.apiUrl}/candidate_selection/${contactId}/contact/${type}`,
        ).pipe(
            catchError(handleApiError),
            map(res => res.data)
        );
    }

    getLatest() {
        return this.http.get<DataApiResponse<CandidateSelection[]>>(
            `${environment.apiUrl}/candidate_selection/latest?tz_offset=${new Date().getTimezoneOffset()}`,
        ).pipe(
            catchError(handleApiError),
            map(res => {
                return res.data;
            })
        );
    }

    markRead(id: number) {
        return this.http.put<DataApiResponse<Task>>(
            `${environment.apiUrl}/candidate_selection/${id}/read`, {})
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

}
