import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertNumber' })
export class ConvertNumberPipe implements PipeTransform {
    transform(value: string | number): number {
        if (value == null) {
            return undefined;
        }
        return typeof value === 'string'
            ? Number(value)
            : value;
    }
}
