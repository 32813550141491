import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeimage'
})
export class SafeImagePipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}
    transform(value: any) {
        if (value == null) {
            return value;
        }
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    }
}