import { Component, Input, OnInit } from '@angular/core';
import { Call, Device } from '@twilio/voice-sdk';
import { TwilioService } from '@app/shared/services/twilio.service';

@Component({
    selector: 'app-twilio-caller',
    templateUrl: './twilio-caller.component.html',
    styleUrls: ['./twilio-caller.component.scss']
})
export class TwilioCallerComponent implements OnInit {
    @Input() from: string;
    @Input() to: string;
    device: Device;
    currentCall: Call;
    status: 'idle' | 'calling' | 'accepted';

    constructor(private twilioService: TwilioService) {
    }

    ngOnInit(): void {
        this.status = 'idle';
    }

    call(to: string, from: string, $event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.twilioService.getToken().subscribe(async (res) => {
            this.device = new Device(res.token, {
                logLevel: 0,
                codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
                edge: 'ashburn',
            });
            this.currentCall = await this.device.connect({
                params: {
                    To: to, From: from
                }
            });
            this.currentCall.on('accept', this.onAccept.bind(this));
            this.currentCall.on('disconnect', this.onDisconnect.bind(this));
            this.currentCall.on('cancel', this.onCancel.bind(this));
            this.status = 'calling';
            this.device.audio.availableInputDevices.forEach((v, k) => {
                console.log(`Input device: ${k}, Label: ${v.label}, Kind: ${v.kind}, ID: ${v.deviceId}`);
            });
            this.device.audio.availableOutputDevices.forEach((v, k) => {
                console.log(`Output device: ${k}, Label: ${v.label}, Kind: ${v.kind}, ID: ${v.deviceId}`);
            });
        });
    }

    onAccept() {
        this.status = 'accepted';
    }

    onDisconnect() {
        this.status = 'idle';
    }

    onCancel() {
        this.status = 'idle';
    }

    hangup($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.currentCall.disconnect();
    }
}
