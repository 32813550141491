import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, SortModel } from '@app/shared/helpers/api';
import { Config } from '@app/models/config.model';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    constructor(private http: HttpClient) {
    }

    get() {
        return this.http.get<DataApiResponse<Config>>(`${environment.apiUrl}/settings`).pipe(map(data => {
            handleApplicationError(data as ApiResponse);
            return data.data;
        }), catchError(handleApiError));
    }

    save(config: Config) {
        return this.http.put<DataApiResponse<Config>>(`${environment.apiUrl}/settings`, config)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }
}
