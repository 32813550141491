<swal #convertDialog
      title="Convert lead to contact"
      [showCancelButton]="false"
      [showConfirmButton]="false"
      [showCloseButton]="false"
      [width]="600"
      [focusCancel]="false"
      [focusConfirm]="false"
>
    <div *swalPortal>
        <form class="theme-form">
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">First Name</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="newContact.first_name" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Last Name</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="newContact.last_name" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="form-group row text-left" *ngFor="let phone of newContact.phones">
                <label class="col-sm-4 col-form-label">{{ phone.type }}
                    <input type="checkbox" [(ngModel)]="phone.isSelected" [ngModelOptions]="{standalone: true}">
                </label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="phone.value" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div>
                <button class="btn btn-primary m-b-0 m-r-10" [disabled]="!canConvert() || isProcessing" (click)="onConvertSubmit()">Convert</button>
                <button class="btn btn-secondary m-b-0" [disabled]="isProcessing" (click)="cancelConvert()">Cancel</button>
            </div>
        </form>

    </div>
</swal>
