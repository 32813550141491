<img *ngIf="src"
     class="img-{{size}} rounded-circle clickable"
     [src]="src"
     (click)="onClick($event)"
     alt="{{ name }}"
     container="body"
     [ngbTooltip]="tooltip !== undefined ? tooltip : name">
<div *ngIf="!src"
     class="avatar clickable avatar-{{size}}"
     [ngClass]="{'hover-rise': riseOnHover}"
     (click)="onClick($event)"
     container="body"
     [ngbTooltip]="tooltip !== undefined ? tooltip : name">
    <div class="inner"
    >{{ initials }}</div>
</div>
