import { Injectable, NgZone } from '@angular/core';
import { EsAgent } from '@app/models/es-agent.model';
import { Router } from '@angular/router';
import { ChatUser } from '@app/models/chat.model';
import { PickedAgentInfo } from '@app/lib/ranking/common';

import {
    getDisplayName,
    getDisplayDate,
} from '@app/shared/helpers/chat';
import { Contact } from '@app/models/contact.model';

@Injectable({ providedIn: 'root' })
export class UtilService {
    constructor(
        private ngZone: NgZone,
        private router: Router,
    ) {
    }

    onAvatarClicked(agent: EsAgent) {
        this.ngZone.run(() => {
            const url = this.router.createUrlTree(['/agent', agent._id]);
            window.open(url.toString(), '_blank');
        });
    }

    getAvatar(user: ChatUser | EsAgent | PickedAgentInfo | Contact) {
        if ((user as EsAgent)?.scanner != null) {
            let avatar = (user as EsAgent)?.scanner?.avatar;
            if (avatar != null && !Array.isArray(avatar)) {
                avatar = [avatar];
            }
            return (avatar ?? [])[0];
        } else if ((user as Contact)?.i_contact != null) {
            return 'assets/images/dashboard/avatar.png';
        } else {
            const chatUser = user as ChatUser;
            if (chatUser?.agent != null) {
                const agentAvatar = (chatUser?.agent?.scanner?.avatar ?? [])[0];
                if (agentAvatar != null) {
                    return agentAvatar;
                } else {
                    return 'assets/images/dashboard/agent.png';
                }
            } else if (chatUser?.contact != null) {
                return 'assets/images/dashboard/avatar.png';
            } else if (chatUser?.lead != null) {
                return 'assets/images/dashboard/lead.svg';
            }
            // return 'assets/images/dashboard/unknown.svg';
            return null;
        }
    }

    getDisplayName(user: ChatUser) {
        return getDisplayName(user);
    }

    userAvatarClicked(user: ChatUser, $event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        console.log(user);
        if (user.agent?._id != null) {
            const url = this.router.createUrlTree(['/agent', user.agent?._id]);
            window.open(url.toString(), '_blank');
        } else if (user.contact?.i_contact != null) {
            const url = this.router.createUrlTree(['/contact', user.contact?.i_contact]);
            window.open(url.toString(), '_blank');
        } else if (user.lead?.i_crm_lead != null) {
            const url = this.router.createUrlTree(['/address', user.lead?.address_key]);
            window.open(url.toString(), '_blank');
        }
    }

    getDisplayDate(str: string) {
        return getDisplayDate(str);
    }

    setupPrimaryPhoneAndEmail(agent: EsAgent | Contact) {
        if (agent?.primary_phone != null) {
            const primary = agent.phones.find(ph => ph.value === agent.primary_phone);
            if (primary != null) {
                primary.is_primary = true;
            }
        }
        if (agent?.primary_email != null) {
            const primary = agent.emails.find(em => em.value === agent.primary_email);
            if (primary != null) {
                primary.is_primary = true;
            }
        }
    }

}
