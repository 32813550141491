import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SweetAlertResult } from 'sweetalert2';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TagService } from '@app/shared/services/tag.service';
import { Tag, TagModule } from '@app/models/tag.model';
import { RequestAgent } from '@app/models/request-agent.model';

@Component({
    selector: 'app-tag-edit',
    templateUrl: './tag-edit.component.html',
    styleUrls: ['./tag-edit.component.scss']
})

export class TagEditComponent implements OnInit {
    @ViewChild('editTag') editTagDialog: SwalComponent;
    
    public oldSelectedTags = [];
    
    public selectedTags = [];

    public tags: Tag[] = [];
    
    public selectedUserId: any;

    public tagModule: TagModule;

    public isShown = false;

    constructor(
        private router: Router,
        private toaster: ToastrService,
        private tagService: TagService,
    ) { }

    ngOnInit() {
        // this.getTags();
    }

    public getTags(selectedTags: Tag[]) {
        this.tagService.getTagsByModule(this.tagModule, 'all').subscribe(res => {
            this.tags = res;

            if (selectedTags) {
                this.selectedTags = selectedTags ? selectedTags.map(tag => tag.id) : []; 
            }
        });
    }

    show(selectedUserId: any, selectedTags: Tag[], tagModule: TagModule): Promise<SweetAlertResult<any>> {
        this.tagModule = tagModule;
        
        this.getTags(selectedTags);

        this.isShown = true;
        
        this.selectedUserId = selectedUserId;

        this.oldSelectedTags = selectedTags;

        // this.editTagDialog.title = tags ? 'Edit Tags' : 'Add Tags';
        
        return this.editTagDialog.fire();
    }

    cancel() {
        this.editTagDialog.dismiss({ isConfirmed: false } as SweetAlertResult);

        this.isShown = false;
    }

    submit() {
        let _this = this;
        
        let deleteTags = [];

        if (this.oldSelectedTags) {
            this.oldSelectedTags.forEach(function(v, i) {
                if (!_this.selectedTags.includes(v.id)) {
                    deleteTags.push({
                        id: v.id,
                        entity_id: _this.selectedUserId
                    })
                }
            });
        }
        
        if (deleteTags) {
            this.tagService.removeAssignTags({ tags: deleteTags }).subscribe(res => {
                // this.toaster.success('Tag deleted successfully.');
            });
        }

        if (this.selectedTags) {
            let sTags = [];

            this.selectedTags.forEach(function(v, i) {
                if (!_this.oldSelectedTags.map(t => t.id).includes(v)) {
                    sTags.push({
                        id: v,
                        entity_id: _this.selectedUserId
                    });
                }
            });

            this.tagService.assignTags({ tags: sTags }).subscribe(res => {
                this.toaster.success('Tag assigned successfully.');

                let selTags = this.tags.filter(tag => this.selectedTags.includes(tag.id));

                this.editTagDialog.dismiss({ isConfirmed: true, value: selTags } as SweetAlertResult);

                this.isShown = false;
            });
        }
    }
}
