<div class="card user-profile" [ngClass]="{ 'condensed': isCondensed }">
    <div class="card-header" *ngIf="!isCondensed">
        <h5>Contact Information
            <div class="pull-right">
                <app-toggle [state]="agent.is_email_enabled" (toggleClick)="emailEnabledChanged($event)"></app-toggle>
            </div>
            <div class="pull-right automation-label m-l-15">Email subscription</div>
            <div class="pull-right">
                <app-toggle [state]="agent.is_automation" (toggleClick)="automationChanged($event)"></app-toggle>
            </div>
            <div class="pull-right automation-label">Automation</div>
        </h5>
    </div>
    <div class="card-body">
        <div class="row p-b-30">
            <div [ngClass]="{ 'col-md-6': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="new-users">
                    <div class="media">
                        <div class="hovercard position-relative">
                            <div class="contact-profile">
                                <img class="rounded-circle img-100" alt="" [src]="utilService.getAvatar(agent) || 'assets/images/dashboard/agent.png'">
                                <div class="icon-wrapper">
                                    <i class="icofont icofont-pencil-alt-5">
                                        <input class="upload" type="file" (change)="fileChangeEvent($event)"/>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <!-- <app-avatar [src]="utilService.getAvatar(agent)" [size]="90" [name]="agent.agent_name || agent.list_agent_full_name"></app-avatar> -->
                        <div class="media-body m-l-10 m-t-15">
                            <h6 class="mb-0 f-w-700 d-flex flex-wrap align-items-center">
                                <span class="mt-0 mr-2">{{ agent.agent_name || agent.list_agent_full_name }}</span>
                                <span class="mt-0 tags-container">
                                    <i class="fa fa-tags tags-revert m-r-3"></i>
                                    <span *ngFor="let tag of agent?.tags | slice:0:2" class="badge badge-{{ tag.color }} mt-0">
                                        {{ tag.name | titlecase }}
                                    </span>
                                    <span class="mt-0 ml-1 more-link" (click)="editAgentTag()">
                                        {{ agent?.tags && agent?.tags.length > 2 ? agent?.tags.length - 2 + ' more' : 'Add Tags' }}
                                    </span>
                                </span>
                            </h6>
                            <p>
                                {{ agent.scanner?.brokerage }}<br>
                                <a [href]="websiteUrl" target="_blank">Website</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <h5>Contact Information<br>
                    <button *ngIf="!isEdit" class="btn btn-primary btn-xs" type="button" (click)="startEdit('profile')">Edit</button>
                    <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button" (click)="save('profile')">Save</button>
                    <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button" (click)="cancelEdit('profile')">Cancel</button>
                </h5>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>Preferred Name</h6>
                    <div *ngIf="!isEdit">
                        {{ agent.preferred_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div class="col-xs-5">
                            <input class="form-control" type="text" [(ngModel)]="agent.preferred_name" ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>First Name</h6>
                    <div *ngIf="!isEdit">
                        {{ agent.first_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div class="col-xs-5">
                            <input class="form-control" type="text" [(ngModel)]="agent.first_name" ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>Last Name</h6>
                    <div *ngIf="!isEdit">
                        {{ agent.last_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div class="col-xs-5">
                            <input class="form-control" type="text" [(ngModel)]="agent.last_name" ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Address
                        <button *ngIf="isEdit" class="btn btn-primary btn-xs" type="button" (click)="addPhone()">Add</button>
                    </h6>
                    {{ agent.scanner.address }}<br>
                    {{ agent.scanner.city }}, {{ agent.scanner.state }} {{ agent.scanner.addr_zip }}
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Phone Numbers
                        <button *ngIf="isEdit" class="btn btn-primary btn-xs" type="button" (click)="addPhone()">Add</button>
                    </h6>
                    <div *ngIf="!isEdit">
                        <div *ngFor="let phone of phones" class="m-b-5">
                            <a *ngIf="config?.sms_voice_provider === 'Justcall'" class="d-md-inline d-none"
                               [href]="'justcall://' + phone.display | safeurl"><img src="assets/images/contact.png"
                                                                                     alt="" width="20"></a>
                            <app-twilio-caller *ngIf="config?.sms_voice_provider === 'Twilio'"
                                               [from]="this.config.twilio_agents_number"
                                               [to]="phone.value"></app-twilio-caller>
                            {{ phone.display | phone }} [{{ phone.type }}]
                            <span *ngIf="phone.value === primaryPhone">[primary]</span>
                        </div>
                    </div>
                    <ng-container *ngIf="isEdit">
                        <ng-container *ngFor="let phone of agent.phones; index as i">
                            <div class="row m-l-0 m-b-5">
                                <div class="col-sm-1">
                                    <div class="form-group">
                                        <div class="checkbox m-l-10">
                                            <input [(ngModel)]="phone.is_primary"
                                                [ngModelOptions]="{standalone: true}"
                                                type="checkbox"
                                                [id]="'isPreferredPhone'+i"
                                                (ngModelChange)="onPrimaryPhoneChange(phone)" />
                                            <label title="Primary" [for]="'isPreferredPhone'+i"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <input title="Phone" class="form-control edit-input" type="text" [(ngModel)]="phone.value" ngModelOptions="{ standalone: true }">
                                        </div>
                                        <div class="col-sm-12">
                                            <input title="Type" class="form-control edit-input" type="text" [(ngModel)]="phone.type" ngModelOptions="{ standalone: true }">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5 m-t-10" type="button" (click)="deletePhone(phone)">Delete</button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Email Addresses
                        <button *ngIf="isEdit" class="btn btn-primary btn-xs" type="button" (click)="addEmail()">Add</button>
                    </h6>
                    <div *ngIf="!isEdit">
                        <div *ngFor="let email of emails" class="m-b-5">
                            {{ email.value }} <span *ngIf="email.type">[{{ email.type }}]</span>
                            <span *ngIf="email.value === primaryEmail">[primary]</span>
                        </div>
                    </div>
                    <ng-container *ngIf="isEdit">
                        <ng-container *ngFor="let email of agent.emails; index as i">
                            <div class="row m-l-0 m-b-5">
                                <div class="col-sm-1">
                                    <div class="form-group">
                                        <div class="checkbox m-l-10">
                                            <input [(ngModel)]="email.is_primary"
                                                [ngModelOptions]="{standalone: true}"
                                                type="checkbox"
                                                [id]="'isPreferredEmail'+i"
                                                (ngModelChange)="onPrimaryEmailChange(email)" />
                                            <label title="Primary" [for]="'isPreferredEmail'+i"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <input class="form-control edit-input" type="text" [(ngModel)]="email.value" ngModelOptions="{ standalone: true }">
                                        </div>
                                        <div class="col-sm-12">
                                            <input class="form-control edit-input" type="text" [(ngModel)]="email.type" ngModelOptions="{ standalone: true }">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5 m-t-10" type="button" (click)="deleteEmail(email)">Delete</button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- <div class="row" *ngIf="agent?.agentStoryData">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Claim Status</h6>
                    <div>{{ agent.agentStoryData?.claim_status ? 'Claimed' : 'Unclaimed' }}</div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Profile Phone Number</h6>
                    <div *ngIf="!isEdit">
                        <a class="d-md-inline d-none" [href]="'justcall://' + agent.agentStoryData?.profile_phone | safeurl"><img src="assets/images/contact.png" alt="" width="20"></a>
                        {{ agent.agentStoryData?.profile_phone }}
                    </div>
                    <div *ngIf="isEdit">
                        <div class="col-xs-5">
                            <input class="form-control" type="text" [(ngModel)]="agent.profilePhone.value" ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Profile Status</h6>
                    <div>
                        {{ agent.agentStoryData?.profile_status ? 'Active' : 'Deactivated' }}
                        <button *ngIf="agent.agentStoryData?.profile_status" class="btn btn-primary btn-xs m-l-5" type="button" (click)="deactivate()">Deactivate</button>
                        <button *ngIf="!agent.agentStoryData?.profile_status" class="btn btn-primary btn-xs m-l-5" type="button" (click)="activate()">Activate</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="card user-profile" [ngClass]="{ 'condensed': isCondensed }">
    <div class="card-header" *ngIf="!isCondensed">
        <h5>Online Profile
            <button *ngIf="!isOnlineProfileEdit" class="btn btn-primary btn-xs" type="button" (click)="startEdit('online_profile')">Edit</button>
            <button *ngIf="isOnlineProfileEdit" class="btn btn-primary btn-xs m-l-5" type="button" (click)="save('online_profile')">Save</button>
            <button *ngIf="isOnlineProfileEdit" class="btn btn-primary btn-xs m-l-5" type="button" (click)="cancelEdit('online_profile')">Cancel</button>
        </h5>
    </div>
    <div class="card-body">
        <div class="row" *ngIf="agent?.agentStoryData">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Claim Status</h6>
                    <div>{{ agent.agentStoryData?.claim_status ? 'Claimed' : 'Unclaimed' }}</div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Profile Phone Number</h6>
                    <div *ngIf="!isOnlineProfileEdit">
                        <a *ngIf="config?.sms_voice_provider === 'Justcall'" class="d-md-inline d-none"
                           [href]="'justcall://' + agent.agentStoryData?.profile_phone | safeurl"><img
                                src="assets/images/contact.png" alt="" width="20"></a>
                        <app-twilio-caller *ngIf="config?.sms_voice_provider === 'Twilio'"
                                           [from]="this.config.twilio_agents_number"
                                           [to]="agent.agentStoryData?.profile_phone"></app-twilio-caller>
                        {{ agent.agentStoryData?.profile_phone }}
                    </div>
                    <div *ngIf="isOnlineProfileEdit">
                        <div class="col-xs-5">
                            <input class="form-control" type="text" [(ngModel)]="agent.profilePhone.value" ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Profile Status</h6>
                    <div>
                        {{ agent.agentStoryData?.profile_status ? 'Active' : 'Deactivated' }}
                        <button *ngIf="agent.agentStoryData?.profile_status" class="btn btn-primary btn-xs m-l-5" type="button" (click)="deactivate()">Deactivate</button>
                        <button *ngIf="!agent.agentStoryData?.profile_status" class="btn btn-primary btn-xs m-l-5" type="button" (click)="activate()">Activate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-tag-edit #tagEdit></app-tag-edit>