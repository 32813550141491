import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unicode2html'
})
export class Unicode2HtmlPipe implements PipeTransform {
    transform(value: any) {
        if (value == null) {
            return value;
        }

        return (value as string).replace(/\\u[\dA-F]{4}/gi, (match) => {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        })
            .replace(/\\n/g, '<br>')
            .replace(/\\\\'/g, `'`)
            .replace(/\\'/g, `'`);
    }
}