import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { Contact } from '@app/models/contact.model';
import { ContactStatus } from '@app/models/contact-status.model';
import { Timeline } from '@app/models/timeline.model';
import { ChatUser } from '@app/models/chat.model';
import { CandidateSelection } from '@app/models/agent-selection.model';
import { LineType } from '@app/lib/ranking/common';
import * as moment from 'moment';
import { DateFormat, TimeFormat } from '@app/shared/helpers/util';
import { SavedFilter } from '@app/models/saved-filter.model';

@Injectable({ providedIn: 'root' })
export class ContactService {
    private contactChangedSource = new Subject<number>();

    constructor(private http: HttpClient) {
    }

    public get contactChanged() {
        return this.contactChangedSource.asObservable();
    }

    public triggerContactChanged(contactId: number) {
        this.contactChangedSource.next(contactId);
    }

    list(page: number, count: number, searchText: string, sort: SortModel, filter: SavedFilter) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<ListResponse<Contact[]> | ApiResponse>(
            `${environment.apiUrl}/contact?i_saved_filter=${filter?.i_saved_filter ?? ''}&page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}`)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<Contact[]>;
            }), catchError(handleApiError));
    }

    update(contact: Contact) {
        return this.http.put<DataApiResponse<Contact>>(
            `${environment.apiUrl}/contact/${contact.i_contact}?tz_offset=${new Date().getTimezoneOffset()}`, contact)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    setAutomation(contact: Contact, isAutomated: boolean) {
        return this.http.put<DataApiResponse<Contact>>(
            `${environment.apiUrl}/contact/${contact.i_contact}`, { is_automation: isAutomated ? 1 : 0 })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    setEmailEnabled(contact: Contact, isEmailEnabled: boolean) {
        return this.http.put<DataApiResponse<Contact>>(
            `${environment.apiUrl}/contact/${contact.i_contact}`, { is_email_enabled: isEmailEnabled ? 1 : 0 })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    get(contactId: number, candidateSelectionId?: number) {
        return this.http.get<DataApiResponse<Contact>>(
            `${environment.apiUrl}/contact/${contactId}?tz_offset=${new Date().getTimezoneOffset()}&i_candidate_selection=${candidateSelectionId}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(contactId: number) {
        return this.http.delete(
            `${environment.apiUrl}/contact/${contactId}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(contact: Contact) {
        return this.http.post<DataApiResponse<Contact>>(
            `${environment.apiUrl}/contact?tz_offset=${new Date().getTimezoneOffset()}`, contact)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }

    search(searchText: string, addressOnly = false) {
        return this.http.get<DataApiResponse<Contact[]>>(`${environment.apiUrl}/search/contact?address_only=${addressOnly ? 1 : 0}&search_text=${searchText}`)
            .pipe(map(data => data.data));
    }

    unlinkAddress(contactId: number, addressIds: string[]) {
        return this.http.post<ApiResponse>(
            `${environment.apiUrl}/contact/${contactId}/unlink`, { addressIds })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    linkAddress(contactId: number, addressKey: string, propertyType: string, propertyPrice: number, lineType: LineType = 'region') {
        return this.http.post<DataApiResponse<CandidateSelection>>(
            `${environment.apiUrl}/contact/${contactId}/link`, {
                addressKey, propertyType, propertyPrice, lineType,
            })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }

    setStatus(contact: Contact, status: ContactStatus) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/contact/${contact.i_contact}/status`, { i_contact_status: status.i_contact_status })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    getTimeline(contact: Contact, currentChat?: ChatUser) {
        let agent = currentChat?.agent;
        if (agent != null) {
            agent = { ...agent, local: [], records: [], cluster: []};
        }
        return this.http.post<DataApiResponse<Timeline[]>>(
            `${environment.apiUrl}/timeline`, { currentChat: currentChat ? { ...currentChat, agent } : undefined, contact })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    res.data.forEach(row => {
                        if (row.message != null) {
                            const dt = moment.utc(row.message.datetime).local();
                            row.message.time_create = dt.format(TimeFormat);
                            row.message.date_create = dt.format(DateFormat);
                        }
                        else if (row.email != null) {
                            const dt = moment.utc(row.email.date_create).local();
                            row.email.timecreate = dt.format(TimeFormat);
                            row.email.datecreate = dt.format(DateFormat);
                        }
                    });

                    return res.data;
                }),
                catchError(handleApiError));
    }

    setPrimaryPhone(contact: Contact, phoneNumber: string) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/contact/${contact.i_contact}/primary-phone`, { primary_phone: phoneNumber })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    setPrimaryEmail(contact: Contact, email: string) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/contact/${contact.i_contact}/primary-email`, { primary_email: email })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    setPrimaryPhoneForced(contact: Contact) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/contact/${contact.i_contact}/primary-phone-forced`, { })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    setPrimaryEmailForced(contact: Contact) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/contact/${contact.i_contact}/primary-email-forced`, { })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }
}
