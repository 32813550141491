import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '@app/models/address.model';
import * as moment from 'moment';

const Today = 'Today';
const Tomorrow = 'Tomorrow';

@Pipe({ name: 'usDate' })
export class UsDatePipe implements PipeTransform {
    transform(value: string): string {
        if (value == null) {
            return undefined;
        }
        if (value === 'now') {
            return Today;
        }
        const date = moment.utc(value).local();
        const today = moment();
        const tomorrow = moment().add(1, 'day');
        return date.isSame(today, 'day')
            ? `${Today} ${date.format('h:mm A')}`
            : date.isSame(tomorrow, 'day')
                ? `${Tomorrow} ${date.format('h:mm A')}`
                : date.format('MM/DD/YY h:mm A');
    }
}
