import {
    AfterContentChecked,
    Component,
    ContentChildren, EventEmitter,
    Input,
    OnInit, Output,
    QueryList,
} from '@angular/core';
import { FormulaItemComponent } from '@app/shared/components/formula-item/formula-item.component';

export type FormulaOperation = 'AND' | 'OR';

@Component({
    selector: 'app-formula-editor',
    templateUrl: './formula-editor.component.html',
    styleUrls: ['./formula-editor.component.scss']
})
export class FormulaEditorComponent implements OnInit, AfterContentChecked {
    @ContentChildren(FormulaItemComponent) formulaItems: QueryList<FormulaItemComponent>;
    @Input() formula = '';
    @Output() formulaChanged: EventEmitter<string> = new EventEmitter<string>();

    initialFormulaItems: Record<number, { level?: number, operation?: FormulaOperation }>;

    ngOnInit(): void {
    }

    ngAfterContentChecked(): void {
        let last: FormulaItemComponent;
        if (this.initialFormulaItems == null) {
            this.initialFormulaItems = {};
            const formulaTokens = this.formula.split(/\s+/);
            let level = 0;
            let lastOperand: number = null;
            let lastOperation: FormulaOperation = null;
            let lastLevel: number = null;
            formulaTokens.forEach((token) => {
                if (token === '(') {
                    level++;
                } else if (token === ')') {
                    if (this.initialFormulaItems[lastOperand] == null) {
                        this.initialFormulaItems[lastOperand] = {};
                    }
                    if (this.initialFormulaItems[lastOperand].level == null) {
                        this.initialFormulaItems[lastOperand].level = level;
                    }
                    level--;
                } else if (/\d/.test(token)) {
                    lastOperand = Number(token);
                } else {
                    lastOperation = token as FormulaOperation;
                    lastLevel = level;
                    if (this.initialFormulaItems[lastOperand] == null) {
                        this.initialFormulaItems[lastOperand] = {};
                    }
                    if (this.initialFormulaItems[lastOperand].level == null) {
                        this.initialFormulaItems[lastOperand].level = level;
                    }
                    if (this.initialFormulaItems[lastOperand].operation == null) {
                        this.initialFormulaItems[lastOperand].operation = lastOperation;
                    }
                }
            });
            if (lastOperand != null) {
                this.initialFormulaItems[lastOperand] = { level: lastLevel, operation: lastOperation };
            }
            // console.log(this.formula);
            // console.log(this.initialFormulaItems);
        }

        this.formulaItems.filter(fi => fi.isActive).forEach((item, index) => {
            item.index = index + 1;
            item.isLast = false;
            if (item.level == null) {
                item.level = this.initialFormulaItems[index + 1]?.level ?? 0;
            }
            if (item.operation == null) {
                item.operation = this.initialFormulaItems[index + 1]?.operation ?? 'AND';
            }
            last = item;
        });

        if (last != null) {
            last.isLast = true;
        }
    }

    getFormula() {
        let lastLevel = 0;
        const ret = this.formulaItems.filter(fi => fi.isActive).map((fi, index, arr) => {
            let openBrackets = '';
            let closeBrackets = '';
            if (fi.level > lastLevel) {
                openBrackets = Array(fi.level - lastLevel).fill('( ').join('');
            }

            if (arr[index + 1]?.level < fi.level) {
                closeBrackets = Array(fi.level - arr[index + 1]?.level).fill(' )').join('');
            }

            if (fi.isLast) {
                closeBrackets = Array(fi.level).fill(' )').join('');
            }
            lastLevel = fi.level;

            return fi.isLast ? `${openBrackets} ${fi.index} ${closeBrackets}` : `${openBrackets} ${fi.index} ${closeBrackets} ${fi.operation}`;
        }).join(' ');
        this.formulaChanged.emit(ret);
        return ret;
    }
}
