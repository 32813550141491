import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgentSearchResult, AgentService } from '@app/shared/services/agent.service';

@Component({
    selector: 'app-search-agent',
    templateUrl: './search-agent.component.html',
    styleUrls: ['./search-agent.component.scss']
})
export class SearchAgentComponent implements OnInit, OnChanges{
    @ViewChild('searchContactText') searchText: ElementRef<HTMLInputElement>;
    @Input() default: string;
    @Input() clearOnSelect = false;
    @Input() noEmptyMlsId = false;
    @Output() agentSelected = new EventEmitter<AgentSearchResult>();

    autocompleteInput: string;
    defaultPlaceholder = 'Search agent';
    placeholder = this.defaultPlaceholder;

    constructor(
        private agentService: AgentService,
        private toaster: ToastrService,
    ) {
    }

    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term =>
                forkJoin([
                    this.agentService.search(term, undefined, this.noEmptyMlsId),
                ]).pipe(map(([r1]) => [...r1])))
        )
    };

    onTypeaheadSelected(event: NgbTypeaheadSelectItemEvent) {
        this.autocompleteInput = '';
        this.placeholder = this.clearOnSelect ? this.defaultPlaceholder : `${(event.item as AgentSearchResult).name}`;
        this.searchText.nativeElement.blur();
        this.invokeEvent(event.item as AgentSearchResult);
    }

    formatAgent = (agent: AgentSearchResult) => agent.name;

    nullFormatter = (agent: AgentSearchResult) => '';

    invokeEvent(agent: AgentSearchResult) {
        this.agentSelected.emit(agent);
    }

    formatTypeahead(result: AgentSearchResult): string {
        return result.name;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.default) {
            if (this.default != null) {
                this.placeholder = this.default;
            }
        }
    }

    ngOnInit(): void {
        if (this.default != null) {
            this.placeholder = this.default;
        }
    }
}
