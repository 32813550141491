<div class="card" [ngClass]="{ 'condensed': isCondensed, 'timeline': !isCondensed }">
    <div class="card-header">
        <div class="row">
            <div class="col-sm-6" *ngIf="!isCondensed">
                <h5>Activity</h5>
            </div>
            <div class="text-right" [ngClass]="{ 'col-sm-6': !isCondensed, 'col-sm-12': isCondensed }">
                <a *ngFor="let pill of pills" #link
                   href="javascript:void(0)"
                   class="badge badge-pill"
                   (click)="pillClicked(pill, link)"
                   [ngClass]="[pill.class, pill.selected ? 'focus' : '']"
                   [title]="pill.title">
                    <app-feather-icons [icon]="pill.icon"></app-feather-icons>
                </a>
            </div>
        </div>
    </div>
    <div class="card-body activity-scroll custom-scrollbar" #scrollingBlock>
        <virtual-scroller #scroll
                          [enableUnequalChildrenSizes]="true"
                          [items]="rowsScrollWindow"
                          [parentScroll]="scrollingBlock"
                          (vsEnd)="loadEvents($event)">
        <div class="activity" *ngFor="let row of scroll.viewPortItems; index as i">
            <div class="media" *ngIf="row.message">
                <div class="gradient-round m-r-15 gradient-line-1">
                    <app-feather-icons [icon]="'message-circle'"></app-feather-icons>
                </div>
                <div class="media-body">
                    <h6>{{ isIncoming(row.message.direction) ? 'Incoming' : 'Outgoing' }} Message <span
                            class="pull-right f-14">{{ row.date | timeSince}}</span></h6>
                    <div>
                        <div class="about">
                            <div class="status">
                                <i class="fa fa-share font-success" *ngIf="row.message.direction === Outgoing"></i> <i
                                    class="fa fa-reply font-danger"
                                    *ngIf="row.message.direction === Incoming"></i>  
                                {{getDisplayDate(row.message.date_create)}} {{row.message.time_create}}
                            </div>
                            <app-chat-message [message]="row.message"></app-chat-message>
                        </div>
                    </div>
                </div>
            </div>
            <div class="media pointer" *ngIf="row.email" [routerLink]="[getEmailLink()]" [queryParams]="{email: row.email.contact_email}" [fragment]="getFragment(row.email.i_gmail_message)">
                <div class="gradient-round m-r-15 gradient-line-1">
                    <app-feather-icons [icon]="'mail'"></app-feather-icons>
                </div>
                <div class="media-body">
                    <h6>{{ isIncoming(row.email.direction) ? 'Incoming' : 'Outgoing' }} Email <span
                            class="pull-right f-14">{{ row.date | timeSince}}</span></h6>
                    <div>
                        <div class="about">
                            <div class="status">
                                <i class="fa fa-share font-success" *ngIf="row.email.direction === Outgoing"></i> <i
                                    class="fa fa-reply font-danger"
                                    *ngIf="row.email.direction === Incoming"></i>  
                                {{getDisplayDate(row.email.datecreate)}} {{row.email.timecreate}}
                            </div>
                            Subject: <strong>{{ row.email.subject }}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="media" *ngIf="row.call">
                <div class="gradient-round m-r-15 gradient-line-1">
                    <app-feather-icons [icon]="'phone-call'"></app-feather-icons>
                </div>
                <div class="media-body">
                    <h6>{{ transformSubject(row.call.subject) }} <span
                            class="pull-right f-14">{{ row.date | timeSince }}</span></h6>
                    <div>
                        <img class="rounded-circle user-image" [src]="getAvatar(row.call)" alt="">
                        <div class="about">
                            <div class="name">{{ transformSubject(row.call.contact_name) }}
                            </div>
                            <div class="status">
                                {{ transformPhone(row.call.contact_number) }}
                                <a title="Call recording" [href]="transformUrl(row.call.recordingmp3)"
                                   *ngIf="row.call.recording_url" target="_blank">
                                    <i class="fa fa-play font-success"></i>
                                </a>
                            </div>
                            <div class="status">
                                <i class="fa fa-share font-success" *ngIf="row.call.direction === Outgoing"></i> <i
                                    class="fa fa-reply font-danger"
                                    *ngIf="row.call.direction === Incoming"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="media" *ngIf="row.candidate_selection?.picked_agents">
                <div class="gradient-round m-r-15 gradient-line-1">
                    <app-feather-icons [icon]="'users'"></app-feather-icons>
                </div>
                <div class="media-body">
                    <h6>{{ row.requestTitle }} <span class="pull-right f-14">{{ row.date | timeSince }}</span></h6>
                    <div>
                        <div class="about">
                            <div>
                                {{ row.candidate_selection.address | address }}
                            </div>
                            <div>
                                {{ row.candidate_selection.property_type }} {{ row.candidate_selection.price | currency:'USD':'symbol':'1.0-0' }}
                            </div>
                            <div *ngIf="row.candidate_selection.inquiry_data?.beds || row.candidate_selection.inquiry_data?.baths">
                                <span class="mr-2" *ngIf="row.candidate_selection.inquiry_data?.beds">Bad: <strong>{{ row.candidate_selection.inquiry_data?.beds }}</strong></span>
                                <span *ngIf="row.candidate_selection.inquiry_data?.baths">Bath: <strong>{{ row.candidate_selection.inquiry_data?.baths }}</strong></span>
                            </div>
                            <div *ngIf="row.candidate_selection.inquiry_data?.interested_in_listing != null">
                                Interested in listing?: <strong>{{ row.candidate_selection.inquiry_data?.interested_in_listing }}</strong>
                            </div>
                            <div *ngIf="row.candidate_selection.inquiry_data?.hired_realtor != null">
                                Working with a realtor?: <strong>{{ row.candidate_selection.inquiry_data?.hired_realtor ? 'Yes' : 'No' }}</strong>
                            </div>
                            <div *ngIf="row.candidate_selection.name_match">
                                Owner Match: <strong>{{ row.candidate_selection.name_match | rrpercent }}</strong>
                            </div>
                            <div *ngIf="row.candidate_selection.picked_agents.joined_score.length">
                                Agent count: <strong>{{ row.candidate_selection.picked_agents.joined_score.length }}</strong>
                            </div>
                            <div *ngIf="row.candidate_selection.price_range">
                                Property value: <strong>{{ row.candidate_selection.price_range }}</strong>
                            </div>
                            <div *ngIf="row.candidate_selection.inquiry_data?.condition">
                                Condition: <strong>{{ row.candidate_selection.inquiry_data?.condition }}</strong>
                            </div>
                            <div *ngIf="row.candidate_selection.flow_type">
                                Source: <strong>{{ row.candidate_selection.flow_type }}</strong>
                            </div>
                            <div *ngIf="row.candidate_selection.analytics?.page">
                                Page: <strong>{{ row.candidate_selection.analytics?.page }}</strong>
                            </div>
                            <div *ngFor="let tag of row.tags">
                                <span class="badge badge-{{ tag.color }} d-block mb-1 w-min-content">{{ tag.name | titlecase }}</span>
                            </div>
                            <div class="customers">
                                <ul>
                                    <li *ngFor="let agent of row.candidate_selection?.picked_agents?.joined_score"
                                        class="d-inline-block">
                                    <app-avatar [src]="utilService.getAvatar(agent)"
                                                [riseOnHover]="true"
                                                (avatarClicked)="onAgentClicked(agent)"
                                                [name]="agent.agent_name"
                                                [tooltip]="createTooltip(agent)"></app-avatar>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="media" *ngIf="row.candidate_selection && row.candidate_selection.is_conversion">
                <div class="gradient-round m-r-15 gradient-line-1">
                    <app-feather-icons [icon]="'users'"></app-feather-icons>
                </div>
                <div class="media-body m-b-50">
                    <h6>Lead Converted to Contact <span
                            class="pull-right f-14">{{ row.date | timeSince }}</span></h6>
                </div>
            </div>

            <div class="media" *ngIf="row.record">
                <div class="gradient-round m-r-15 gradient-line-1">
                    <app-feather-icons [icon]="'dollar-sign'"></app-feather-icons>
                </div>
                <div class="media-body">
                    <h6>{{ row.recordTitle }} <span
                            class="pull-right f-14">{{ row.date | timeSince }}</span></h6>
                    <div>
                        <div class="about">
                            <div>
                                {{ row.record.street }} {{ row.record.city }}
                                , {{ row.record.zip_code }} {{ row.record.state }}
                            </div>
                            <div>
                                <strong>{{ row.statusTitle }}</strong> {{ row.record.status }}
                                <strong>{{ row.priceTitle }}</strong> {{ row.record.list_price | currency:'USD':'symbol':'1.0-0' }}
                            </div>
                            <app-timeline-agent *ngIf="row.record.agent_id"
                                                [agent]="row.record.list_agent"
                                                [fallbackAgentName]="row.record.list_agent_full_name"
                                                [fallbackOfficeName]="row.record.list_office_name"
                                                [fallbackAgentId]="row.record.agent_id"
                                                type="List Agent"></app-timeline-agent>
                            <app-timeline-agent *ngIf="row.record.colist_agent_mlsid && row.record.colist_agent"
                                                [agent]="row.record.colist_agent"
                                                [fallbackAgentName]="row.record.list_agent_2"
                                                [fallbackOfficeName]="row.record.colist_office_name"
                                                [fallbackAgentId]="row.record.colist_agent_mlsid"
                                                type="CoList Agent"></app-timeline-agent>
                            <app-timeline-agent *ngIf="row.record.selling_agent_id"
                                                [agent]="row.record.selling_agent"
                                                [fallbackAgentName]="row.record.selling_agent_full_name"
                                                [fallbackOfficeName]="row.record.selling_office_name"
                                                [fallbackAgentId]="row.record.selling_agent_id"
                                                type="Selling Agent"></app-timeline-agent>
                        </div>
                    </div>
                </div>
            </div>
            <div class="media" *ngIf="row.change">
                <div class="gradient-round m-r-15 gradient-line-1">
                    <app-feather-icons [icon]="'dollar-sign'"></app-feather-icons>
                </div>
                <div class="media-body">
                    <h6>{{ getChangeTitle(row.change) }}<span
                            class="pull-right f-14">{{ row.date | timeSince }}</span></h6>
                    <div>
                        <div class="about">
                            <div>
                                {{ row.change.street_address }}, {{ row.change.city }}, {{ row.change.state }}, {{ row.change.zip_code }}
                            </div>
                            <div *ngFor="let change of row.change.changes">
                                <strong>{{change.change_item | titlecase }} <span *ngIf="!row.change.isNew">changed </span></strong>
                                <span *ngIf="change.change_item === 'price' && change.change_from">
                                    <strong *ngIf="!row.change.isNew">From</strong> {{ change.change_from | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                                <span *ngIf="change.change_item === 'price' && change.change_to">
                                    <strong *ngIf="!row.change.isNew">To </strong> {{ change.change_to | currency:'USD':'symbol':'1.0-0' }}
                                </span>
                                <span *ngIf="change.change_item !== 'price' && change.change_from">
                                    <strong *ngIf="!row.change.isNew">From</strong> {{ change.change_from }}
                                </span>
                                <span *ngIf="change.change_item !== 'price' && change.change_to">
                                    <strong *ngIf="!row.change.isNew">To</strong> {{ change.change_to }}
                                </span>
                            </div>
                            <app-timeline-agent *ngIf="row.change.listing_agent_global_mlsid"
                                                [agent]="row.change.listing_agent"
                                                [fallbackAgentName]="row.change.listing_agent_full_name"
                                                [fallbackOfficeName]="row.change.listing_agent_office_name"
                                                [fallbackAgentId]="row.change.listing_agent_global_mlsid"
                                                type="List Agent"></app-timeline-agent>
                            <app-timeline-agent *ngIf="row.change.colist_agent_full_name && row.change.colist_agent"
                                                [agent]="row.change.colist_agent"
                                                [fallbackAgentName]="row.change.colist_agent_full_name"
                                                [fallbackOfficeName]="row.change.colist_agent_office_name"
                                                [fallbackAgentId]="row.change.colist_agent_id"
                                                type="CoList Agent"></app-timeline-agent>
                            <app-timeline-agent *ngIf="row.change.buyer_agent_global_mlsid"
                                                [agent]="row.change.buyer_agent"
                                                [fallbackAgentName]="row.change.buyer_agent_full_name"
                                                [fallbackOfficeName]="row.change.buyer_agent_office_name"
                                                [fallbackAgentId]="row.change.buyer_agent_global_mlsid"
                                                type="Selling Agent"></app-timeline-agent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </virtual-scroller>
    </div>
</div>
