import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { AddressSearchResult, AddressService } from '@app/shared/services/address.service';
import { AgentSearchResult, AgentService } from '@app/shared/services/agent.service';
import { Address } from '@app/models/address.model';
import { formatMoney } from '@app/shared/helpers/util';

export interface GoogleAddressSearchResult {
    place: google.maps.places.PlaceResult;
    address: AddressSearchResult;
    unit: string;
    price: number;
}

@Component({
    selector: 'app-search-address',
    templateUrl: './search-address.component.html',
    styleUrls: ['./search-address.component.scss']
})
export class SearchAddressComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('searchText') searchText: any;
    @Input() initialAddress: Address;
    @Input() placeType: string;
    @Input() default: string;
    @Output() addressSelected = new EventEmitter<GoogleAddressSearchResult>();

    autocompleteInput: string;
    placeholder = 'Enter address';

    private autocompleteService: google.maps.places.AutocompleteService;
    private placesService: google.maps.places.PlacesService;

    constructor(
        private mapsApiLoader: MapsAPILoader,
        private addressService: AddressService,
    ) {
    }

    ngOnInit() {
        if (this.initialAddress) {
            this.placeholder = `${this.initialAddress.street} ${this.initialAddress.city} ${this.initialAddress.state} ${this.initialAddress.zip_code}`.trim();
            if (this.initialAddress.price != null) {
                this.placeholder = `${this.placeholder.trim()} (${formatMoney(this.initialAddress.price)})`.trim();
            }
        } else if (this.default != null) {
            this.placeholder = this.default;
        }
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        this.mapsApiLoader.load().then(() => {
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
        });
    }

    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term =>
                forkJoin([
                    this.addressService.search(term),
                    this.getAutocompletePredictions(term),
                ]).pipe(map(([r1, r2]) => [...r2, ...r1])))
        )
    };

    onTypeaheadSelected(event: NgbTypeaheadSelectItemEvent) {
        if (this.isAddressSearchResult(event.item)) {
            const price = event.item.price;
            this.autocompleteInput = '';
            this.searchText.nativeElement.blur();
            this.invokeEvent(null, null, price, event.item as AddressSearchResult);
        } else {
            const placeObj = event.item as google.maps.places.AutocompletePrediction;
            const unit = event.item.unit;
            const price = event.item.price;
            if (placeObj != null) {
                this.getPlaceDetails(event.item.place_id).then(place => {
                    this.invokeEvent(place, unit, price, event.item as AddressSearchResult);
                });
            }
        }
    }

    private getAutocompletePredictions(query: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                if (query === '') {
                    resolve([]);
                } else {
                    const req: any = {
                        input: query,
                        componentRestrictions: { country: 'US' }
                    };
                    if (this.placeType != null) {
                        req.types = [this.placeType];
                    }
                    this.autocompleteService.getPlacePredictions(req
                        , (predictions, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            resolve(predictions);
                        } else {
                            resolve([]);
                        }
                    });
                }
            } catch (e) {
                resolve([]);
            }
        });
    }

    formatPlace = (place) => place.description;

    private getPlaceDetails(placeId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.placesService.getDetails({ placeId },
                (placeResult, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        resolve(placeResult);
                    } else {
                        reject(status);
                    }
                });
        });
    }

    invokeEvent(place: google.maps.places.PlaceResult, unit: string, price: number, address: AddressSearchResult) {
        this.placeholder = place?.formatted_address || address?.formatted_address;
        this.autocompleteInput = '';
        this.searchText.nativeElement.blur();
        this.addressSelected.emit({ place, unit, price, address });
    }


    typeaheadInputClick(event: MouseEvent, el: HTMLInputElement) {
        el.focus();
        event.stopPropagation();
    }

    isAddressSearchResult = (object: AgentSearchResult | AddressSearchResult | google.maps.places.AutocompletePrediction)
        : object is AddressSearchResult => {
        return 'address_key_normalized' in object || 'address_key' in object;
    }

    isGoogleResult = (object: AgentSearchResult | AddressSearchResult | google.maps.places.AutocompletePrediction) =>
        !this.isAddressSearchResult(object);

    formatTypeahead(result: AgentSearchResult | google.maps.places.AutocompletePrediction): string {
        if (this.isAddressSearchResult(result)) {
            const address = result as AddressSearchResult;
            return address.formatted_address;
        }

        const place = result as google.maps.places.AutocompletePrediction;
        if (place != null) {
            return place.description;
        }
        return '';
    }

}
