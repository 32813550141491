import { Component, Input } from '@angular/core';

export interface ChatImage {
    filename: string;
    mimetype: string;
    url: string;
}

@Component({
    selector: 'app-chat-image',
    templateUrl: './chat-image.component.html',
    styleUrls: ['./chat-image.component.scss']
})
export class ChatImageComponent {
    @Input() image: ChatImage ;
}
