import {
    Component,
    ViewChild,
} from '@angular/core';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertResult } from 'sweetalert2';
import { Contact } from '@app/models/contact.model';
import { Lead } from '@app/models/lead.model';
import { ToastrService } from 'ngx-toastr';
import { LeadService } from '@app/shared/services/lead.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lead-convert',
    templateUrl: './lead-convert.component.html',
})
export class LeadConvertComponent {
    @ViewChild('convertDialog') leadConvertDialog: SwalComponent;

    lead: Lead;
    isShown = false;
    isProcessing = false;

    newContact: Contact;

    constructor(
        private toaster: ToastrService,
        private leadService: LeadService,
        private router: Router,
    ) {
    }

    public show(lead: Lead): Promise<SweetAlertResult<Lead>> {
        this.isShown = true;
        this.isProcessing = false;
        this.lead = lead;
        this.newContact = { ...this.lead };
        this.leadConvertDialog.title = `Convert ${this.newContact.first_name} ${this.newContact.last_name} to Contact`;
        return this.leadConvertDialog.fire();
    }

    submit() {
        this.leadConvertDialog.dismiss({ isConfirmed: true, value: this.lead } as SweetAlertResult);
        this.isShown = false;
    }

    onConvertSubmit() {
        this.newContact.phones = this.newContact.phones.filter(ph => ph.isSelected).map(ph => ({
            value: ph.value,
            type: ph.type,
            display: ph.display,
        }));
        this.lead.phones.forEach(ph => ph.isSelected = false);
        this.isProcessing = true;
        this.leadService.convert(this.lead, this.newContact)
            .subscribe(
                ({contact, request}) => {
                    this.toaster.success('The lead has been converted');
                    this.lead.i_contact = contact.i_contact;
                    const url = this.router.createUrlTree(['/contact', contact.i_contact]);
                    window.open(url.toString(), '_blank');
                    this.isProcessing = false;
                    this.leadConvertDialog.dismiss({ isConfirmed: true, value: this.lead } as SweetAlertResult);
                },
                err => {
                    this.isProcessing = false;
                    this.toaster.error(err);
                });
    }

    cancelConvert() {
        this.leadConvertDialog.dismiss({ isConfirmed: false } as SweetAlertResult);
        this.isShown = false;
    }

    canConvert() {
        return this.newContact.first_name.trim() !== '' &&
            this.newContact.last_name.trim() !== '' &&
            this.newContact.phones.some(ph => ph.isSelected);
    }

    canConvertLead() {
        return this.lead?.i_contact == null;
    }
}
