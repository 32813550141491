import { Component, NgZone, OnInit } from '@angular/core';
import { ChatService } from '@app/shared/services/chat.service';
import { ChatUser, Direction } from '@app/models/chat.model';
import { UtilService } from '@app/shared/services/util.service';
import { stripPhone } from '@app/shared/helpers/util';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NavService } from '@app/shared/services/nav.service';


@Component({
    selector: 'app-right-sidebar-messages',
    templateUrl: './right-sidebar.component.html',
    styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarMessagesComponent implements OnInit {
    users: ChatUser[] = [];
    searchUsers: ChatUser[] = [];
    notFound = false;
    searchText: string;

    Incoming = Direction.Incoming;
    Outgoing = Direction.Outgoing;

    constructor(
        private chatService: ChatService,
        public utilService: UtilService,
        private router: Router,
        private ngZone: NgZone,
        public toaster: ToastrService,
        public navService: NavService,
    ) {
        this.chatService.getUnreadMessages().subscribe(users => {
            this.searchUsers = users;
            this.users = users;
        });
    }

    searchTerm(term: any) {
        if (!term) {
            this.searchUsers = this.users;
            return;
        }
        term = term.toLowerCase();
        const phoneTerm = stripPhone(term);

        this.searchUsers = this.users.filter(user =>
            user.contact_name?.toLocaleLowerCase().includes(term) ||
            (`${user.contact?.first_name ?? ''} ${user.contact?.last_name ?? ''}`).trim().toLocaleLowerCase().includes(term) ||
            (user.agent?.agent_name ?? '').toLocaleLowerCase().includes(term) ||
            (phoneTerm.length > 0 &&
                (
                    (user.contact_number ?? '').includes(phoneTerm) ||
                    (user.contact?.phones ?? []).some(ph => ph.value.toLocaleLowerCase().includes(phoneTerm)) ||
                    (user.agent?.phones ?? []).some(ph => ph.value.toLocaleLowerCase().includes(phoneTerm))
                )
            )
        );
        this.checkSearchResultEmpty();
    }

    checkSearchResultEmpty() {
        this.notFound = !this.searchUsers.length;
    }

    ngOnInit() {
        this.chatService.getNewMessage().subscribe(message => {
            ( new Audio('../assets/sounds/new-message.mp3') ).play();
            this.toaster.info(message.latest_message_content, this.utilService.getDisplayName(message), {
                tapToDismiss: false,
                closeButton: true,
                timeOut: 30 * 1000,
            }).onTap.subscribe(() => this.openUserChat(message));
        });
    }

    openUserChat(user: ChatUser) {
        this.chatService.selectUser(user);
        this.users.splice(this.users.indexOf(user), 1);
        this.chatService.setUnreadMessages(this.users);
        const isContactPage = user.contact != null;
        if (isContactPage) {
            if (!this.router.url.startsWith(`/contact/${user.contact.i_contact}/chat`)) {
                this.ngZone.run(() => {
                    this.router.navigate(['/contact', user.contact.i_contact, 'chat']);
                });
            }
        } else {
            if (!this.router.url.startsWith(`/chat`)) {
                this.ngZone.run(() => {
                    this.router.navigate(['/chat']);
                });
            }
        }
    }

    closeSidebar() {
        this.navService.closeRightSidebar();
    }

    markAllRead() {
        this.chatService.markAllRead().subscribe(() => {
            this.chatService.setUnreadMessages([]);
        });
    }
}
