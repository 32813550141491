import { LatLngLiteral } from '@agm/core';
import { StatTableLine } from '../../models/agent-stat.model';
import * as moment from 'moment';

export interface FormOption {
    name: string;
    type?: 'text' | 'number' | 'boolean';
    optional?: boolean;
}

export function currencyFormatter(val: { value: number | string}) {
    if (val.value == null) {
        return NA;
    }
    if (val.value === NA) {
        return val.value;
    }
    return `\$${formatNumber(val.value)}`;
}

export function integerFormatter(val) {
    if (val.value == null) {
        return NA;
    }
    if (val.value === NA) {
        return val.value;
    }
    return `${formatNumber(val.value)}`;
}

export function formatNumber(num) {
    return Math.floor(num)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function percentFormatter(val) {
    if (val.value == null) {
        return NA;
    }
    if (val.value === NA) {
        return val.value;
    }
    return `${Number(val.value).toFixed(2)}%`;
}

export function melissaDateFormatter(val) {
    if (val.value == null) {
        return NA;
    }
    if (val.value === NA) {
        return val.value;
    }
    const vv = val.value;
    const y = vv.substring(0, 4);
    const m = vv.substring(4, 6);
    const d = vv.substring(6);
    return `${m}/${d}/${y}`;
}

export function naFormatter(val) {
    if (val.value == null) {
        return NA;
    }
    return val.value;
}

export function dropPercentFormatter(val) {
    if (val.value == null) {
        return NA;
    }
    if (val.value === NA) {
        return val.value;
    }
    return `${val.value.toFixed(0)}%`;
}

export function transformPolygon(value: string): LatLngLiteral[] {
    if (value == null) {
        return [];
    }
    const str = value.replace('POLYGON((', '').replace('))', '');
    return str.split(',').map(pair => transformPoint(pair));
}

export function transformLinestring(value: string): LatLngLiteral[] {
    if (value == null) {
        return [];
    }
    const str = value.replace('LINESTRING(', '').replace(')', '');
    return str.split(',').map(pair => transformPoint(pair));
}

export function transformPoint(value: string): LatLngLiteral {
    if (value == null) {
        return null;
    }
    const str = value.replace('POINT(', '').replace(')', '');
    const [lng, lat] = str.trim().split(' ');
    return {
        lat: Number(lat),
        lng: Number(lng),
    };
}

export function haversineDistance(p1: LatLngLiteral, p2: LatLngLiteral) {
    const R = 3958.8; // Radius of the Earth in miles
    const rlat1 = p1.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = p2.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (p2.lng - p1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) +
        Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
}

export function rayCrossesSegment(pt: LatLngLiteral, a: LatLngLiteral, b: LatLngLiteral): boolean {
    const px = pt.lng;
    let py = pt.lat;
    let ax = a.lng;
    let ay = a.lat;
    let bx = b.lng;
    let by = b.lat;

    if (ay > by) {
        ax = b.lng;
        ay = b.lat;
        bx = a.lng;
        by = a.lat;
    }

    if (py === ay || py === by) { py += 0.00000001; }
    if ((py > by || py < ay) || (px > Math.max(ax, bx))) { return false; }
    if (px < Math.min(ax, bx)) { return true; }

    const red = (ax !== bx) ? ((by - ay) / (bx - ax)) : Infinity;
    const blue = (ax !== px) ? ((py - ay) / (px - ax)) : Infinity;

    return (blue >= red);
}

export function pathContains(point: LatLngLiteral, path: LatLngLiteral[]): boolean {
    let crossings = 0;

    // for each edge
    for (let i = 0; i < path.length; i++) {
        const a = path[i];
        let j = i + 1;
        if (j >= path.length) {
            j = 0;
        }
        const b = path[j];
        if (rayCrossesSegment(point, a, b)) {
            crossings++;
        }
    }

    // odd number of crossings?
    return (crossings % 2 === 1);
}

export const NA = 'n/a';

export function transformPercents(row) {
    [
        'close_rate',
        'percent_rank',
        'weighted_score',
        'score_a',
        'score_b',
        'score_c',
        'score_d',
        'score_e',
    ].map(col => {
        if (row != null) {
            if (row[col] != null && row[col] !== NA) {
                row[col] = Number(row[col]) * 100;
            }
            if (row.local != null) {
                if (row.local[col] != null && row.local[col] !== NA) {
                    row.local[col] = Number(row.local[col]) * 100;
                }
            }
            if (row.buyerLocal != null) {
                if (row.buyerLocal[col] != null && row.buyerLocal[col] !== NA) {
                    row.buyerLocal[col] = Number(row.buyerLocal[col]) * 100;
                }
            }
        }
    });
    [
        'price_drop',
    ].map(col => {
        if (row != null) {
            if (row[col] != null && row[col] !== NA) {
                row[col] = (100 - Number(row[col]));
            }
            if (row.local != null) {
                if (row.local[col] != null && row.local[col] !== NA) {
                    row.local[col] = (100 - Number(row.local[col]));
                }
            }
            if (row.buyerLocal != null) {
                if (row.buyerLocal[col] != null && row.buyerLocal[col] !== NA) {
                    row.buyerLocal[col] = (100 - Number(row.buyerLocal[col]));
                }
            }
        }
    });
}
export function transformPercentsBuyer(row) {
    [
        'close_rate',
        'percent_rank',
        'weighted_score',
        'score_a',
        'score_b',
        'score_c',
        'score_d',
        'score_e',
    ].map(col => {
        if (row != null) {
            if (row[col] != null && row[col] !== NA) {
                row[col] = Number(row[col]) * 100;
            }
            if (row.local != null) {
                if (row.local[col] != null && row.local[col] !== NA) {
                    row.local[col] = Number(row.local[col]) * 100;
                }
            }
            if (row.buyerLocal != null) {
                if (row.buyerLocal[col] != null && row.buyerLocal[col] !== NA) {
                    row.buyerLocal[col] = Number(row.buyerLocal[col]) * 100;
                }
            }
        }
    });
    [
        'price_drop',
        'buyer_price_drop',
    ].map(col => {
        if (row != null) {
            if (row[col] != null && row[col] !== NA) {
                row[col] = (100 - Number(row[col]));
            }
            if (row.local != null) {
                if (row.local[col] != null && row.local[col] !== NA) {
                    row.local[col] = (100 - Number(row.local[col]));
                }
            }
            if (row.buyerLocal != null) {
                if (row.buyerLocal[col] != null && row.buyerLocal[col] !== NA) {
                    row.buyerLocal[col] = (100 - Number(row.buyerLocal[col]));
                }
            }
        }
    });
}

export function transformCompetitorPercents(row) {
    [
        'overlap_div_agent_area',
        'overlap_div_co_agent_area',
    ].map(col => {
        if (row[col] != null && row[col] !== NA) {
            row[col] = (Number(row[col]) * 100);
        }
    });
}

export function calcDistance(newRow, center: LatLngLiteral, isBuyer = false) {
    const local = !isBuyer ? newRow.local : newRow.buyerLocal;
    const centroid = local != null ?
        (!isBuyer ? (local.act_geo_centroid || local.geo_centroid || local.centroid) : local.actbuy_geo_centroid) : null;
    if (centroid != null && center != null) {
        const p2 = transformPoint(centroid);
        newRow.listing_dist_from_polygon_center = haversineDistance(
            { lat: center.lat, lng: center.lng },
            p2,
        ).toFixed(2);
    } else {
        newRow.listing_dist_from_polygon_center = NA;
    }
}

export function calcMinDistance(newRow: StatTableLine, others: StatTableLine[]) {
    const local = newRow.local || newRow.buyerLocal;
    const centroid = local != null ? local.act_geo_centroid || local.perf_geo_centroid : null;
    if (centroid != null && others.length > 0) {
        const p2 = newRow.actPoint || newRow.point;
        let minDistance = null;
        let addressWithinPolygon = null;

        others.forEach((other: StatTableLine) => {
            if (other.local.perf_geo_centroid != null || other.local.act_geo_centroid != null) {
                const p1 = other.actPoint || other.point;
                const path = other.actPath || other.path;
                const dist = haversineDistance(p1, p2);
                if (minDistance == null || minDistance > dist) {
                    minDistance = dist;
                    addressWithinPolygon = pathContains(p2, path) ? 'True' : 'False';
                }
            }
        });
        newRow.listing_dist_from_polygon_center = minDistance != null ? minDistance.toFixed(2) : NA;
        newRow.address_within_polygon = addressWithinPolygon || NA;
    } else {
        newRow.listing_dist_from_polygon_center = NA;
        newRow.address_within_polygon = NA;
    }
}

export function calcIsWithin(newRow, center: LatLngLiteral, isBuyer = false) {
    const local = !isBuyer ? newRow.local : newRow.buyerLocal;
    if (local != null) {
        if (local.area === 'Location') {
            const point = !isBuyer ? (newRow.actPoint || newRow.point) : newRow.actBuyPoint;
            if (point != null) {
                if (point.lat === center?.lat && point.lng === center?.lng) {
                    newRow.address_within_polygon = 'True';
                } else {
                    newRow.address_within_polygon = NA;
                }
            } else {
                newRow.address_within_polygon = NA;
            }
        } else {
            const path = !isBuyer ? (newRow.actPath || newRow.path || newRow.actBuyPath) : newRow.actBuyPath;
            if (path != null && center != null) {
                const p1 = { lat: center.lat, lng: center.lng };
                newRow.address_within_polygon = pathContains(p1, path) ? 'True' : 'False';
            } else {
                newRow.address_within_polygon = NA;
            }
        }
    } else {
        newRow.address_within_polygon = NA;
    }
}

export function log(str: string) {
    console.log(`[${new Date().toISOString()}]: ${str}`);
}

export function timespan(t1: Date, t2: Date): number {
    const diff = t1.getTime() - t2.getTime();

    return Math.abs(diff / 1000);
}

const suffixes = ['k', 'M', 'B', 'T', 'P', 'E'];

export function formatMoney(input: number): string {
    if (input < 1000) {
        return `\$${input}`;
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000));
    let decimals;
    if (exp === 1) {
        decimals = 0;
    } else {
        decimals = 1;
    }

    let v = (input / Math.pow(1000, exp)).toFixed(decimals).toString();
    if (v.endsWith('.0')) {
        v = v.replace(/\.0$/, '');
    }

    return `\$${v}${suffixes[exp - 1]}`;
}

export const DateFormat = 'MM-DD-Y';
export const TimeFormat = 'h:mm A';
export const DateTimeFormat = 'MM-DD-Y h:mm A';

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    return keys.reduce((o, k) => (o[k] = obj[k], o), {} as Pick<T, K>);
}

export function copyObjectValues<T>(source: T, dest: T): void {
    Object.keys(dest).forEach(key => dest[key] = source[key]);
}

export function removeSpaces(str: string): string {
    return str.replace(/\s{2,}/g, ' ');
}

export function stripPhone(str: string): string {
    if (str == null) {
        return str;
    }
    return str.replace(/[^0-9]+/g, '');
}

export function isPhone(str: string): boolean {
    if (str == null) {
        return false;
    }
    if (typeof str !== 'string') {
        return false;
    }
    const stripped = stripPhone(str);
    return stripped.length === 9 || stripped.length === 10 || stripped.length === 11 || stripped.length === 12;
}


export function formatPhoneNumber(phoneNumber) {
    const x = phoneNumber && phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d*)/);
    return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
}

export function formatPhoneNumberExt(phoneNumber: string, showPlus = true) {
    if (phoneNumber == null) {
        return undefined;
    }
    if (phoneNumber.length === 10) {
        return formatPhoneNumber(phoneNumber);
    }
    if (showPlus) {
        if (phoneNumber.charAt(0) === '1') {
            return `+1 ${formatPhoneNumber(phoneNumber.substring(1))}`;
        }
        if (phoneNumber.startsWith('+1')) {
            return `+1 ${formatPhoneNumber(phoneNumber.substring(2))}`;
        }
    } else {
        if (phoneNumber.charAt(0) === '1') {
            return formatPhoneNumber(phoneNumber.substring(1));
        }
        if (phoneNumber.startsWith('+1')) {
            return formatPhoneNumber(phoneNumber.substring(2));
        }
    }
    return phoneNumber;
}

export function getAreaCodeFromPhone(phone: string): string {
    let stripped = stripPhone(phone);
    if (stripped.startsWith('1')) {
        stripped = stripped.substring(1);
    }
    return stripped.substring(0, 3);
}

export function formatDate(dateStr: string): string {
    return moment.utc(dateStr).local().format(DateFormat);
}

export function formatDateTime(dateStr: string): string {
    return moment.utc(dateStr).local().format(DateTimeFormat);
}

export function parseDateTime(val: string): number[] {
    const [h, m] = val.split(':').map(Number);
    let minutesOffset = h * 60 + m + new Date().getTimezoneOffset();
    if (minutesOffset < 0) {
        minutesOffset = 24 * 60 + minutesOffset;
    }
    if (minutesOffset > 24 * 60) {
        minutesOffset = minutesOffset % (24 * 60);
    }
    return [(minutesOffset - minutesOffset % 60) / 60, minutesOffset % 60];
}

const pad = (s: string) => s.length === 1 ? `0${s}` : s;

export function setDateTime(hour: number, minute: number, defaultDelta = 0): string[] {
    const timezoneOffset = new Date().getTimezoneOffset();
    let minutesOffset = (hour ?? 0) * 60 + (minute ?? (timezoneOffset + defaultDelta)) - timezoneOffset;
    if (minutesOffset < 0) {
        minutesOffset = 24 * 60 + minutesOffset;
    }
    if (minutesOffset > 24 * 60) {
        minutesOffset = minutesOffset % (24 * 60);
    }
    return [(minutesOffset - minutesOffset % 60) / 60, minutesOffset % 60].map(String).map(pad);
}

export const WebListPageSize = 50;

export const isEmpty = (v) => typeof v === 'string' ? v.trim() === '' : v == null;
