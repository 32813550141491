<div class="row">
    <div class="col-sm-2 m-t-10 text-center" [ngStyle]="{ 'padding-left': (level * 10) + 'px' }" *ngIf="isActive && level != null">
        <div class="row">
            <div class="col-sm-2 no-padding m-t-5 m-r-5">
                <i class="fa fa-arrow-left pointer" (click)="level = level - 1" *ngIf="level > 0"></i>
            </div>
            <div class="no-padding">
                <div class="rounded">
                    {{ index }}
                </div>
            </div>
            <div class="col-sm-2 no-padding m-t-5 m-l-5">
                <i class="fa fa-arrow-right pointer" (click)="level = level + 1" *ngIf="level < 5"></i>
            </div>
        </div>
        <div class="row" style="height: calc(100% - 20px)">
            <div class="col-sm-2 no-padding m-t-5 m-r-5">
            </div>
            <div class="no-padding" style="display: flex; align-items: center">
                <div class="formula pointer" (click)="toggleOperation()" *ngIf="!isLast">
                    {{ operation }}
                </div>
            </div>
            <div class="col-sm-2 no-padding m-t-5 m-l-5">
            </div>
        </div>
    </div>
    <div class="p-t-5" [ngClass]="{'col-sm-10': isActive, 'col-sm-12': !isActive}">
        <ng-content></ng-content>
    </div>
</div>
