import { Component, Input } from '@angular/core';
import { ChatMessage } from '@app/models/chat.model';

@Component({
    selector: 'app-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent {
    @Input() message: ChatMessage;
    @Input() showEllipsis = true;
}
