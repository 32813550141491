import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, SortModel } from '@app/shared/helpers/api';
import { Config } from '@app/models/config.model';
import { PhoneNumber } from '@app/models/just-call/number.model';
import { Call, Chat, ChatMessage, ChatUser } from '@app/models/chat.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { DateFormat, TimeFormat } from '@app/shared/helpers/util';
import { JustCallUser } from '@app/models/just-call/just-call-user.model';

@Injectable({ providedIn: 'root' })
export class JustCallService {
    constructor(private http: HttpClient) {
    }

    test(config: Config) {
        return this.http.put<DataApiResponse<Config>>(`${environment.apiUrl}/just-call/test`, config)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }

    getNumbers() {
        return this.http.get<DataApiResponse<PhoneNumber[]>>(`${environment.apiUrl}/just-call/numbers`)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }

    getUsers() {
        return this.http.get<DataApiResponse<JustCallUser[]>>(`${environment.apiUrl}/just-call/users`)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }

    sendMessage(message: ChatMessage) {
        return this.http.post<ApiResponse>(`${environment.apiUrl}/just-call/message`, message)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data;
            }), catchError(handleApiError));
    }

    getChatHistory(user: ChatUser) {
        return this.http.post<DataApiResponse<Chat>>(`${environment.apiUrl}/just-call/chat-history`,
            {
                tz_offset: new Date().getTimezoneOffset(),
                ...user
            })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                this.convertMessagesDates(data.data.messages);
                return data.data;
            }), catchError(handleApiError));
    }

    convertMessagesDates(messages: ChatMessage[]) {
        messages.forEach(call => {
            const dt = moment.utc(call.datetime).local();
            call.time_create = dt.format(TimeFormat);
            call.date_create = dt.format(DateFormat);
        });
    }

    getCallHistory(user: ChatUser) {
        return this.http.post<DataApiResponse<Call[]>>(`${environment.apiUrl}/just-call/call-history`,
            {
                tz_offset: new Date().getTimezoneOffset(),
                ...user
            })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                this.convertCallsDates(data.data);
                return data.data;
            }), catchError(handleApiError));
    }

    getLatestChats(contactId: number, agentId: string, searchText?: string): Observable<ChatUser[]> {
        return this.http.post<DataApiResponse<ChatUser[]>>(
            `${environment.apiUrl}/just-call/latest-chats?tz_offset=${new Date().getTimezoneOffset()}`,
            { searchText, i_contact: contactId, agent_id: agentId })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }

    getUnreadChats(): Observable<ChatUser[]> {
        return this.http.post<DataApiResponse<ChatUser[]>>(
            `${environment.apiUrl}/just-call/unread-chats?tz_offset=${new Date().getTimezoneOffset()}`,
            { })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }

    markAllRead(): Observable<void> {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/just-call/mark-all-read`,
            { })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
            }), catchError(handleApiError));
    }

    getLatestCalls(offset?: number, limit?: number): Observable<Call[]> {
        const params: { [name: string]: string } = { tz_offset: `${new Date().getTimezoneOffset()}` };
        if (offset != null) {
            params.offset = `${offset}`;
        }
        if (limit != null) {
            params.limit = `${limit}`;
        }
        const paramsStr = Object.keys(params).map(p => `${p}=${params[p]}`).join('&');
        return this.http.get<DataApiResponse<Call[]>>(
            `${environment.apiUrl}/just-call/latest-calls?${paramsStr}`)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                this.convertCallsDates(data.data);
                return data.data;
            }), catchError(handleApiError));
    }

    convertCallsDates(data: Call[]) {
        data.forEach(call => {
            const dt = moment.utc(call.datetime).local();
            call.call_time = dt.format(TimeFormat);
            call.call_date = dt.format(DateFormat);
        });
    }
}
