import { CKEditor5 } from '@ckeditor/ckeditor5-angular';

export const CKEditorConfig: CKEditor5.Config = {
    autoGrow: { minHeight: '250' },
    toolbar: {
        items: [
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'highlight',
            '|',
            'alignment',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            'link',
            'blockQuote',
            'insertTable',
            '|',
            'undo',
            'redo'
        ]
    },
    language: 'en',
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:full',
            'imageStyle:side'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
        ]
    },
};

export function remapCkEditorKeys(editor: CKEditor5.Editor) {
    editor.editing.view.document.on('enter', (evt, data) => {
        if (data.isSoft) {
            editor.execute('enter');
        } else {
            editor.execute('shiftEnter');
        }
        data.preventDefault();
        evt.stop();
        editor.editing.view.scrollToTheSelection();
    }, { priority: 'high' });
}