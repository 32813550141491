import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventManager } from '@angular/platform-browser';
import { SavedFilterService } from '@app/shared/services/saved-filter.service';
import { SavedFilter } from '@app/models/saved-filter.model';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    icon?: string;
    icoIcon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    separated?: boolean;
    children?: Menu[];
    params?: Record<string, string | number>;
}

@Injectable({
    providedIn: 'root'
})

export class NavService {

    private currentBreadcrumbSubject = new BehaviorSubject<string>(null);
    private rightSidebarClose = new Subject<boolean>();
    private tasksFilters: SavedFilter[] = [];
    private contactsFilters: SavedFilter[] = [];
    private agentsFilters: SavedFilter[] = [];

    constructor(
        private eventManager: EventManager,
    ) {
        this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
        this.onResize();
    }

    public screenWidth: any;
    public collapseSidebar = false;

    items = new BehaviorSubject<Menu[]>(this.createMenu());

    AdminMenuItems: Menu[] = [
        {
            title: 'General Settings', path: '/admin/general-settings',
        },
        {
            title: 'Agent Request Statuses', path: '/admin/agent-request-statuses',
        },
        {
            title: 'Agent Statuses', path: '/admin/agent-statuses',
        },
        {
            title: 'Contact Statuses', path: '/admin/contact-statuses',
        },
        {
            title: 'Task Types', path: '/admin/task-types',
        },
        {
            title: 'Tags', path: '/admin/tags',
        },
        {
            title: 'Workflows', path: '/admin/workflows', separated: true,
        },
        {
            title: 'Logs', path: '/admin/workflow-logs',
        },
        {
            title: 'Tracked Numbers', path: '/admin/tracked-numbers', separated: true,
        },
        {
            title: 'Assignments', path: '/admin/tracked-numbers-assignments',
        },
        {
            title: 'Gmail', path: '/admin/gmail', separated: true,
        },
        {
            title: 'HelloSign', path: '/admin/settings/hello-sign',
        },
        {
            title: 'Intercom', path: '/admin/settings/intercom',
        },
        {
            title: 'JustCall', icon: 'phone-call', path: '/admin/settings/just-call',
        },
        {
            title: 'Twilio', path: '/admin/settings/twilio', separated: true,
        },
        {
            title: 'Twilio Numbers', path: '/admin/settings/twilio-numbers',
        },
        {
            title: 'Assignments', path: '/admin/twilio-numbers-assignments',
        },
        {
            title: 'Email Templates', path: '/admin/email-templates', separated: true, icon: 'mail'
        },
        {
            title: 'SMS Templates', path: '/admin/sms-templates', icon: 'message-square',
        },
        {
            title: 'Task Templates', path: '/admin/task-templates', icon: 'edit'
        },
        {
            title: 'User Management', icon: 'users', path: '/admin/users', separated: true,
        },
        {
            title: 'BQ Sync', icon: 'refresh-cw', path: '/admin/sync', separated: true,
        },
        {
            title: 'Google Sheet Report', icon: 'list', path: '/admin/google-sheet-report', separated: true,
        },
        {
            title: 'Website', path: '/admin/website', separated: true,
        },
    ];

    adminMenuItems = new BehaviorSubject<Menu[]>(this.AdminMenuItems);

    onResize() {
        this.screenWidth = window.innerWidth;
        this.collapseSidebar = this.screenWidth < 991;
    }

    public set currentPageBreadcrumb(breadcrumb: string) {
        this.currentBreadcrumbSubject.next(breadcrumb);
    }

    public get currentBreadcrumb() {
        return this.currentBreadcrumbSubject.asObservable();
    }

    setFilters(tasksFilters: SavedFilter[], contactsFilters: SavedFilter[], agentFilters: SavedFilter[]) {
        this.tasksFilters = tasksFilters;
        this.contactsFilters = contactsFilters;
        this.agentsFilters = agentFilters;
        this.items.next(this.createMenu());
    }

    private createMenu() {
        return [
            {
                title: 'Addresses', icoIcon: 'social-google-map', type: 'link', path: '/addresses',
            },
            {
                title: 'Agents', icoIcon: 'businessman', type: 'link', path: '/agents',
                children:
                    this.agentsFilters.map(filter => (
                        {
                            title: `${filter.name} (${filter.count})`,
                            type: 'link',
                            path: `/agents`,
                            params: { i_saved_filter: filter.i_saved_filter },
                        }
                    ))
            },
            {
                title: 'Chat', icon: 'message-square', type: 'link', path: '/chat',
            },
            {
                title: 'Contacts', icon: 'user', type: 'link', path: '/contacts',
                children:
                    this.contactsFilters.map(filter => (
                        {
                            title: `${filter.name} (${filter.count})`,
                            type: 'link',
                            path: `/contacts`,
                            params: { i_saved_filter: filter.i_saved_filter },
                        }
                    ))
            },
            {
                title: 'Leads', icon: 'target', type: 'link', path: '/leads',
            },
            {
                title: 'Listings', icon: 'map-pin', type: 'link', path: '/listings',
            },
            {
                title: 'Requests', icoIcon: 'architecture-alt', type: 'link', path: '/requests',
            },
            {
                title: 'Tasks', icoIcon: 'tasks', type: 'link', path: '/tasks',
                children:
                    this.tasksFilters.map(filter => (
                        {
                            title: `${filter.name} (${filter.count})`,
                            type: 'link',
                            path: `/tasks`,
                            params: { i_saved_filter: filter.i_saved_filter },
                        }
                    ))
            },
        ];
    }

    get rightSidebarClosed$() {
        return this.rightSidebarClose.asObservable();
    }

    closeRightSidebar() {
        this.rightSidebarClose.next(true);
    }
}
