import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'callback',
    pure: false
})
export class CallbackPipe implements PipeTransform {
    transform<T>(
        items: T[],
        callback: (item: T, aux1?: any, aux2?: any, aux3?: any, aux4?: any, aux5?: any, aux6?: any) => boolean,
        aux1?: any, aux2?: any, aux3?: any, aux4?: any, aux5?: any, aux6?: any): T[] {
        if (!items || !callback) {
            return items;
        }
        return items.filter(item => callback(item, aux1, aux2, aux3, aux4, aux5, aux6));
    }
}
