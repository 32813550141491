<div class="card user-profile" [ngClass]="{ 'condensed': isCondensed }">
    <div class="card-header">
        <h5>Lead Information<br>
            <button *ngIf="lead?.i_contact == null" class="btn btn-primary btn-xs m-r-5" type="button"
                    (click)="convert()">Convert
            </button>
            <button *ngIf="!isEdit" class="btn btn-primary btn-xs" type="button"
                    (click)="startEdit()">Edit
            </button>
            <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button"
                    (click)="save()">Save
            </button>
            <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5" type="button"
                    (click)="cancelEdit()">Cancel
            </button>
        </h5>
    </div>
    <div class="card-body">
        <div class="row">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>First Name
                    </h6>
                    <div *ngIf="!isEdit">
                        {{ lead.first_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div>
                            <input class="form-control" type="text" [(ngModel)]="lead.first_name"
                                   ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>Last Name
                    </h6>
                    <div *ngIf="!isEdit">
                        {{ lead.last_name | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div>
                            <input class="form-control" type="text" [(ngModel)]="lead.last_name"
                                   ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info text-left">
                    <h6>Email
                    </h6>
                    <div *ngIf="!isEdit">
                        {{ lead.email | na}}
                    </div>
                    <div *ngIf="isEdit">
                        <div>
                            <input class="form-control" type="text" [(ngModel)]="lead.email"
                                   ngModelOptions="{ standalone: true }">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div [ngClass]="{ 'col-md-4': !isCondensed, 'col-sm-12': isCondensed }">
                <div class="ttl-info ttl-info-inner text-left">
                    <h6>Phone Numbers
                        <button *ngIf="isEdit" class="btn btn-primary btn-xs" type="button"
                                (click)="addPhone()">Add
                        </button>
                    </h6>
                    <div *ngIf="!isEdit">
                        <div *ngFor="let phone of phones" class="m-b-5">
                            {{ phone.display | phone }} [{{ phone.type }}]
                        </div>
                    </div>
                    <ng-container *ngIf="isEdit">
                        <ng-container *ngFor="let phone of lead.phones; index as i">
                            <div class="row m-l-0 m-b-5">
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <input title="Phone" class="form-control edit-input" type="text" [(ngModel)]="phone.value"
                                                   ngModelOptions="{ standalone: true }">
                                        </div>
                                        <div class="col-sm-12">
                                            <input title="Type" class="form-control edit-input" type="text" [(ngModel)]="phone.type"
                                                   ngModelOptions="{ standalone: true }">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <button *ngIf="isEdit" class="btn btn-primary btn-xs m-l-5 m-t-10" type="button"
                                            (click)="deletePhone(phone)">Delete
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<app-lead-convert #convertDialog></app-lead-convert>