import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
    transform(value: string, maxLength: number): string {
        if (value == null) {
            return value;
        }
        if (value.length <= maxLength) {
            return value;
        }

        const words = value.split(' ');
        let ret = '';
        for (const word of words) {
            if (ret.length > 0) {
                const newRet = `${ret} ${word}`;
                if (newRet.length >= maxLength) {
                    return `${newRet.substr(0, maxLength)}...`;
                } else {
                    ret = newRet;
                }
            } else {
                ret = word;
            }
        }

        return ret;
    }
}
