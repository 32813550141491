import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { Email, EsAgent} from '@app/models/es-agent.model';
import {
    copyObjectValues,
    formatPhoneNumber,
    stripPhone
} from '@app/shared/helpers/util';
import { AgentService } from '@app/shared/services/agent.service';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/shared/services/util.service';
import * as clone from 'clone';
import { getConsolidatedEmailInfo, getConsolidatedPhoneInfo } from '@app/shared/helpers/ui';
import { Phone } from '@app/models/phone.model';
import { SettingsService } from '@app/shared/services/settings.service';
import { Config } from '@app/models/config.model';
import { TagEditComponent } from '../tag-edit/tag-edit.component';

@Component({
    selector: 'app-agent-contact-details',
    templateUrl: './agent-contact-details.component.html',
    styleUrls: ['./agent-contact-details.component.scss']
})
export class AgentContactDetailsComponent implements OnInit, OnChanges  {
    @ViewChild('tagEdit') tagEditDialog: TagEditComponent;

    @Input() agent: EsAgent;
    @Input() isCondensed = false;

    agentBackup: EsAgent;
    phones: Phone[];
    emails: Email[];
    isEdit = false;
    isOnlineProfileEdit = false;
    primaryEmail: string;
    primaryPhone: string;
    config: Config;

    constructor(
        private agentService: AgentService,
        private toaster: ToastrService,
        public utilService: UtilService,
        public settingsService: SettingsService,
    ) {
    }

    ngOnInit(): void {
        this.setup();
        this.settingsService.get().subscribe(config => this.config = config, err => this.toaster.error(err));
    }

    get websiteUrl(): string {
        return `${this.config?.website_url}/agents/${this.agent?.scanner?.url_pointer?.id}/${this.agent?.scanner?.url_pointer?.name?.replace(/ /g, '-')}`;
    }

    private setup() {
        this.phones = getConsolidatedPhoneInfo(this.agent.phones);
        this.emails = getConsolidatedEmailInfo(this.agent.emails);
        this.primaryEmail = this.agent.primary_email;
        this.primaryPhone = this.agent.primary_phone;
    }

    startEdit(type: string) {
        if (type == 'profile') {
            this.isEdit = true;
        } else {
            this.isOnlineProfileEdit = true;
        }

        this.agent.profilePhone = {
            value: this.agent.agentStoryData?.profile_phone ?? '',
            display: this.agent.agentStoryData?.profile_phone ?? '',
            type: ''
        };

        this.agentBackup = clone(this.agent);
    }

    cancelEdit(type: string) {
        if (type == 'profile') {
            this.isEdit = false;
        } else {
            this.isOnlineProfileEdit = false;
        }
        
        copyObjectValues(this.agentBackup, this.agent);

        this.setup();
    }

    save(type: string) {
        if (type == 'profile') {
            this.isEdit = false;
        } else {
            this.isOnlineProfileEdit = false;
        }

        this.agent.phones.forEach(ph => {
            ph.value = stripPhone(ph.value);
            ph.display = formatPhoneNumber(ph.value);
        });

        const profilePhone = stripPhone(this.agent.profilePhone.value);

        this.agent.profilePhone = {
            value: profilePhone,
            display: formatPhoneNumber(profilePhone),
            type: 'Profile'
        };
        
        this.agent.agentStoryData.profile_phone = profilePhone;

        this.agentService.updateAgent(
            this.agent,
            {
                phones: this.agent.phones,
                emails: this.agent.emails,
                props: {
                    preferred_name: this.agent.preferred_name,
                    first_name: this.agent.first_name,
                    last_name: this.agent.last_name,
                },
                agentStory: {
                    profile_phone: this.agent.profilePhone,
                }
            }
        ).subscribe(
            () => {
                this.phones = getConsolidatedPhoneInfo(this.agent.phones);
                this.emails = getConsolidatedEmailInfo(this.agent.emails);
            },
            err => this.toaster.error(err)
        );
    }

    addPhone() {
        this.agent.phones.push({ display: '', type: '', value: '' });
    }

    addEmail() {
        this.agent.emails.push({ type: '', value: '' });
    }

    deletePhone(phone: Phone) {
        if (confirm(`Are you sure you want to delete the phone number ${phone.display} from the agent?`)) {
            this.agent.phones.splice(this.agent.phones.indexOf(phone), 1);
        }
    }

    deleteEmail(email: Email) {
        if (confirm(`Are you sure you want to delete the email address ${email.value} from the agent?`)) {
            this.agent.emails.splice(this.agent.emails.indexOf(email), 1);
        }
    }

    onPrimaryEmailChange(email: Email) {
        if (email.is_primary) {
            this.agent.emails.filter(em => em !== email).forEach(em => (em.is_primary = false));
            this.primaryEmail = email.value;
        } else {
            this.primaryEmail = null;
        }
    }

    onPrimaryPhoneChange(phone: Phone) {
        if (phone.is_primary) {
            this.agent.phones.filter(ph => ph !== phone).forEach(ph => (ph.is_primary = false));
            this.primaryPhone = phone.value;
        } else {
            this.primaryPhone = null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.agent != null) {
            this.utilService.setupPrimaryPhoneAndEmail(this.agent);
            this.setup();
        }
    }

    fileChangeEvent(event: any): void {
        if (event.target.files.length === 0) {
            return;
        }

        if (!/image\/(png|jpg|jpeg|bmp|gif|tiff)/.test(event.target.files[0].type)) {
            this.toaster.error('Please use an image file format');

            return;
        }

        const fileReader = new FileReader();

        fileReader.onload = (event: any) => {
            this.agent?.scanner?.avatar.push(event.target.result);
        };

        fileReader.readAsDataURL(event.target.files[0]);
    }

    activate() {
        this.agentService.activateAgentProfile(this.agent).subscribe(() => {
            this.agent.agentStoryData.profile_status = true;
            this.toaster.info('Profile activated');
        }, err => this.toaster.error(err));
    }

    deactivate() {
        this.agentService.deactivateAgentProfile(this.agent).subscribe(() => {
            this.agent.agentStoryData.profile_status = false;
            this.toaster.info('Profile deactivated');
        }, err => this.toaster.error(err));
    }

    automationChanged(value: boolean) {
        this.agentService.setAutomation(this.agent, value).subscribe(
            () => {
                this.agent.is_automation = value;
                this.agentService.triggerAgentChanged(this.agent._id);
            },
            err => this.toaster.error(err));
    }

    emailEnabledChanged(value: boolean) {
        this.agentService.setEmailEnabled(this.agent, value).subscribe(
            () => {
                this.agent.is_email_enabled = value;
                this.agentService.triggerAgentChanged(this.agent._id);
            },
            err => this.toaster.error(err));
    }

    async editAgentTag() {
        const res = await this.tagEditDialog.show(this.agent.agent_id, this.agent?.tags, 'agent');
        if (res.isConfirmed) {
            this.agent.tags = res.value;
        }
    }
}
