import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, SortModel } from '@app/shared/helpers/api';
import { Config } from '@app/models/config.model';

@Injectable({ providedIn: 'root' })
export class TwilioService {
    constructor(private http: HttpClient) {
    }
    test(config: Config) {
        return this.http.put<DataApiResponse<Config>>(`${environment.apiUrl}/twilio/test`, config)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }
    getToken() {
        return this.http.get<DataApiResponse<{ token: string }>>(`${environment.apiUrl}/twilio/token`)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }
}
