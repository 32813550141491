import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { TaskTemplate } from '@app/models/task-template.model';
import { Observable, ReplaySubject } from 'rxjs';

const ApiPath = '/task_template';

@Injectable({ providedIn: 'root' })
export class TaskTemplateService {
    constructor(private http: HttpClient) {
    }

    private listSubject = new ReplaySubject<TaskTemplate[]>(1);

    public get taskTemplates$(): Observable<TaskTemplate[]> {
        return this.listSubject.asObservable();
    }

    public fetchTaskTemplates() {
        this.list(0, 1000, '', { colId: 'name', sort: 'asc' })
            .subscribe(data => this.listSubject.next(data.data));
    }

    get(id: number) {
        return this.http.get<DataApiResponse<TaskTemplate>>(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    list(page: number, count: number, searchText: string, sort?: SortModel) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<ListResponse<TaskTemplate[]> | ApiResponse>(
            `${environment.apiUrl}${ApiPath}?page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}`,)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<TaskTemplate[]>;
            }), catchError(handleApiError));
    }

    update(taskTemplate: TaskTemplate) {
        return this.http.put<DataApiResponse<TaskTemplate>>(
            `${environment.apiUrl}${ApiPath}/${taskTemplate.i_task_template}`, taskTemplate)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(id: number) {
        return this.http.delete(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(taskTemplate: TaskTemplate) {
        return this.http.post<DataApiResponse<TaskTemplate>>(
            `${environment.apiUrl}${ApiPath}`, taskTemplate)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }
}
