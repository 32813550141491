import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

// Directives
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@app/shared/interceptors/jwt.interceptor';
import { ErrorInterceptor } from '@app/shared/interceptors/errors.interceptor';
import { ImageCropperModule } from '@app/shared/components/image-cropper/image-cropper.module';
import { SafeTextPipe } from '@app/shared/pipes/safe-text.pipe';
import { Unicode2HtmlPipe } from '@app/shared/pipes/unicode2html.pipe';
import { AvatarComponent } from '@app/shared/components/avatar/avatar.component';
import { ChatMessageComponent } from '@app/shared/components/chat-message/chat-message.component';
import { SafeUrlPipe } from '@app/shared/pipes/safe-url.pipe';
import { SafeImagePipe } from '@app/shared/pipes/safe-image.pipe';
import { NestedEllipsisModule } from 'ngx-nested-ellipsis';
import { SearchContactComponent } from '@app/shared/components/search-contact/search-contact.component';
import { SearchAddressComponent } from '@app/shared/components/search-address/search-address.component';
import { NormalizeFloatPipe } from '@app/shared/pipes/normalize-float.pipe';
import { ConvertNumberPipe } from '@app/shared/pipes/convert-number.pipe';
import { AddressPipe } from '@app/shared/pipes/address.pipe';
import { CallbackPipe } from '@app/shared/pipes/callback.pipe';
import { BigNumberPipe } from '@app/shared/pipes/bigNumber.pipe';
import { UsDatePipe } from '@app/shared/pipes/usDate.pipe';
import { RRPercentPipe } from '@app/shared/pipes/percent.pipe';
import { DropPercentPipe } from '@app/shared/pipes/drop-percent.pipe';
import { ThousandSuffixPipe } from '@app/shared/pipes/thousand-suffix.pipe';
import { ConvertNumberRandomPipe } from '@app/shared/pipes/convert-number-random.pipe';
import { NaPipe } from '@app/shared/pipes/na.pipe';
import { SafeurlPipe } from '@app/shared/pipes/safeurl.pipe';
import { TimeSincePipe } from '@app/shared/pipes/timeSince.pipe';
import { EllipsisPipe } from '@app/shared/pipes/ellipsis.pipe';
import { ChatImageComponent } from '@app/shared/components/chat-image/chat-image.component';
import { PhonePipe } from '@app/shared/pipes/phone.pipe';
import { TaskPriorityPipe } from '@app/shared/pipes/task-priority.pipe';
import { SearchAgentComponent } from '@app/shared/components/search-agent/search-agent.component';
import { ContactPipe } from '@app/shared/pipes/contact.pipe';
import { AgentPipe } from '@app/shared/pipes/agent.pipe';
import { CandidateSelectionPipe } from '@app/shared/pipes/candidate-selection.pipe';
import { ToggleComponent } from '@app/shared/components/toggle/toggle.component';
import { RightSidebarMessagesComponent } from '@app/shared/components/right-sidebar-messages/right-sidebar.component';
import { FormulaEditorComponent } from '@app/shared/components/formula-editor/formula-editor.component';
import { FormulaItemComponent } from '@app/shared/components/formula-item/formula-item.component';
import { NotificationsComponent } from '@app/shared/components/notifications/notifications.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { TaskEditComponent } from '@app/shared/components/task-edit/task-edit.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TemplateSelectorComponent } from '@app/shared/components/template-selector/template-selector.component';
import { ContactTimelineComponent } from '@app/shared/components/contact-timeline/contact-timeline.component';
import { TimelineAgentComponent } from '@app/shared/components/contact-timeline/timeline-agent/timeline-agent.component';
import { ContactDetailsComponent } from '@app/shared/components/contact-details/contact-details.component';
import { AgentContactDetailsComponent } from '@app/shared/components/agent-contact-details/agent-contact-details.component';
import { LeadDetailsComponent } from '@app/shared/components/lead-details/lead-details.component';
import { LeadConvertComponent } from '@app/shared/components/lead-convert/lead-convert.component';
import { MmsComponent } from '@app/shared/components/mms/mms.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TagEditComponent } from './components/tag-edit/tag-edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TwilioCallerComponent } from '@app/shared/components/twilio-caller/twilio-caller.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        ContentLayoutComponent,
        FeatherIconsComponent,
        BreadcrumbComponent,
        ToggleFullscreenDirective,
        SafeTextPipe,
        Unicode2HtmlPipe,
        AvatarComponent,
        ChatMessageComponent,
        SafeImagePipe,
        SafeUrlPipe,
        SearchContactComponent,
        SearchAddressComponent,
        SearchAgentComponent,
        AddressPipe,
        NormalizeFloatPipe,
        ConvertNumberPipe,
        ConvertNumberRandomPipe,
        CallbackPipe,
        BigNumberPipe,
        UsDatePipe,
        TimeSincePipe,
        SafeurlPipe,
        RRPercentPipe,
        DropPercentPipe,
        NaPipe,
        ThousandSuffixPipe,
        EllipsisPipe,
        ChatImageComponent,
        PhonePipe,
        TaskPriorityPipe,
        ContactPipe,
        AgentPipe,
        CandidateSelectionPipe,
        ToggleComponent,
        RightSidebarMessagesComponent,
        FormulaEditorComponent,
        FormulaItemComponent,
        NotificationsComponent,
        TaskEditComponent,
        TagEditComponent,
        TemplateSelectorComponent,
        ContactTimelineComponent,
        TimelineAgentComponent,
        ContactDetailsComponent,
        AgentContactDetailsComponent,
        LeadDetailsComponent,
        LeadConvertComponent,
        MmsComponent,
        TwilioCallerComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbTypeaheadModule,
        LoadingBarHttpClientModule,
        ImageCropperModule,
        NgbTooltipModule,
        NestedEllipsisModule,
        VirtualScrollerModule,
        SweetAlert2Module,
        CKEditorModule,
        NgxDropzoneModule,
        NgSelectModule,
    ],
    exports: [
        FeatherIconsComponent,
        Unicode2HtmlPipe,
        SafeTextPipe,
        AvatarComponent,
        ChatMessageComponent,
        SearchAddressComponent,
        SearchContactComponent,
        AddressPipe,
        NormalizeFloatPipe,
        ConvertNumberPipe,
        ConvertNumberRandomPipe,
        CallbackPipe,
        BigNumberPipe,
        UsDatePipe,
        TimeSincePipe,
        SafeurlPipe,
        RRPercentPipe,
        DropPercentPipe,
        NaPipe,
        ThousandSuffixPipe,
        EllipsisPipe,
        ChatImageComponent,
        PhonePipe,
        TaskPriorityPipe,
        SearchAgentComponent,
        ContactPipe,
        AgentPipe,
        CandidateSelectionPipe,
        ToggleComponent,
        FormulaEditorComponent,
        FormulaItemComponent,
        TaskEditComponent,
        TagEditComponent,
        TemplateSelectorComponent,
        ContactTimelineComponent,
        TimelineAgentComponent,
        ContactDetailsComponent,
        AgentContactDetailsComponent,
        LeadDetailsComponent,
        LeadConvertComponent,
        MmsComponent,
        TwilioCallerComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
    ]
})
export class SharedModule {
}

