import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { Notification } from '@app/models/notification.model';
import { IPageInfo } from 'ngx-virtual-scroller';
import { TaskEditComponent } from '@app/shared/components/task-edit/task-edit.component';
import { TaskService } from '@app/shared/services/task.service';
import { Task } from '@app/models/task.model';
import { Subject, Subscription, timer } from 'rxjs';
import { SelectionService } from '@app/shared/services/selection.service';
import { ChatService } from '@app/shared/services/chat.service';
import {formatCTAAction, joinAddressComponents} from '@app/shared/helpers/ui';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('taskEditN') taskEditDialog: TaskEditComponent;
    private readonly Limit = 10;
    private readonly ReloadPeriod = 1000 * 10; // seconds

    offset = 0;
    limit = this.Limit;
    total = 0;

    notifications: Notification[] = [];
    private destroyed$: Subject<boolean> = new Subject();
    private newRequestSubscription: Subscription;
    private websiteCtaEvent: Subscription;

    constructor(
        private notificationService: NotificationService,
        private taskService: TaskService,
        private selectionService: SelectionService,
        private toaster: ToastrService,
        private chatService: ChatService,
    ) {
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
        this.newRequestSubscription?.unsubscribe();
        this.websiteCtaEvent?.unsubscribe();
    }

    ngOnInit(): void {
        this.loadData();
        timer(this.ReloadPeriod, this.ReloadPeriod)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.loadData(true));

        this.newRequestSubscription = this.chatService.getNewRequest()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((selection) => {
                this.toaster.success(joinAddressComponents(selection.address), `New request`, {
                    tapToDismiss: false,
                    closeButton: true,
                    timeOut: 30 * 1000,
                    enableHtml: true,
                }).onTap.subscribe(() => window.location.href = selection.request_url);
            });

        this.websiteCtaEvent = this.chatService.getWebsiteCtaEvent()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((event) => {
                this.toaster.success(formatCTAAction(event), `New CTA Event`, {
                    tapToDismiss: false,
                    closeButton: true,
                    timeOut: 30 * 1000,
                    enableHtml: true,
                }).onTap.subscribe(() => window.location.href = event.contact_url);
            });
    }

    private loadData(reload = false) {
        if (reload) {
            this.limit = this.offset + this.limit;
            this.offset = 0;
        }
        this.notificationService.list(this.offset, this.limit).subscribe(
            res => {
                if (!reload) {
                    this.notifications.push(...res.data);
                } else {
                    this.notifications = res.data;
                }
                this.total = res.total;
                this.notifications.filter(n => {
                    if (n.is_today && this.getShownNotifications()[n.i_notification] == null && n.task != null) {
/*
                        this.toaster.info(n.body, `Task: ${n.subject}`, {
                            tapToDismiss: false,
                            closeButton: true,
                            timeOut: 30 * 1000,
                            enableHtml: true,
                        }).onTap.subscribe(() => this.viewNotification(n, null));
*/
                        this.setShownNotifications(n.i_notification);
                    }
                });
            },
            err => this.toaster.error(err),
        );
    }

    markAllRead() {
        this.notificationService.markAllRead().subscribe(
            () => this.loadData(true),
            err => this.toaster.error(err));
    }

    loadEvents($event: IPageInfo) {
        if ($event.startIndex > this.offset) {
            this.offset += this.Limit;
            this.loadData();
        }
    }

    async viewNotification(notification: Notification, $event: MouseEvent) {
        if (notification.task != null) {
            $event?.preventDefault();
            this.taskService.markRead(notification.i_task)
                .subscribe(
                    () => this.loadData(true),
                    err => this.toaster.error(err));

            const res = await this.taskEditDialog.show(notification.task);
            if (res.isConfirmed) {
                this.onTaskSubmitted(res.value);
            }
        } else if (notification.i_candidate_selection != null) {
            this.selectionService.markRead(notification.i_candidate_selection)
                .subscribe(
                    () => this.loadData(true),
                    err => this.toaster.error(err));
        }
    }

    onTaskSubmitted(task: Task) {
        this.taskService.update(task).subscribe(
            (res) => {
                this.toaster.success('The task has been updated');
                this.loadData(true);
            },
            err => this.toaster.error(err)
        );
    }

    markRead($event: MouseEvent, notification: Notification) {
        $event.stopPropagation();
        $event.preventDefault();
        if (notification.i_task != null) {
            this.taskService.markRead(notification.i_task)
                .subscribe(
                    () => this.loadData(true),
                    err => this.toaster.error(err));
        } else if (notification.i_candidate_selection != null) {
            this.selectionService.markRead(notification.i_candidate_selection)
                .subscribe(
                    () => this.loadData(true),
                    err => this.toaster.error(err));
        }
    }

    private getShownNotifications(): Record<string, boolean> {
        return JSON.parse(sessionStorage.getItem('shownNotifications') ?? '{}');
    }

    private setShownNotifications(id: string): void {
        const arr = this.getShownNotifications();
        arr[id] = true;
        sessionStorage.setItem('shownNotifications', JSON.stringify(arr));
    }
}
