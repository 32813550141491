import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { Task } from '@app/models/task.model';
import { SavedFilter } from '@app/models/saved-filter.model';

const ApiPath = '/task';

@Injectable({ providedIn: 'root' })
export class TaskService {
    constructor(private http: HttpClient) {
    }

    get(id: number) {
        return this.http.get<DataApiResponse<Task>>(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    markRead(id: number) {
        return this.http.put<DataApiResponse<Task>>(
            `${environment.apiUrl}${ApiPath}/${id}/read`, {})
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    list(page: number, count: number, searchText: string, sort: SortModel, filter: SavedFilter, contactId: number,
         agentId: string, selectedStatus: string) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<ListResponse<Task[]> | ApiResponse>(
            `${environment.apiUrl}${ApiPath}?i_saved_filter=${filter?.i_saved_filter ?? ''}&page=${page}&selectedStatus=${selectedStatus}&i_contact=${contactId ?? ''}&agent_id=${agentId ?? ''}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}`)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<Task[]>;
            }), catchError(handleApiError));
    }

    update(task: Task) {
        return this.http.put<DataApiResponse<Task>>(
            `${environment.apiUrl}${ApiPath}/${task.i_task}`, task)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(id: number) {
        return this.http.delete(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(task: Task) {
        return this.http.post<DataApiResponse<Task>>(
            `${environment.apiUrl}${ApiPath}`, task)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }

    bulkMarkComplete(ids: number[]) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}${ApiPath}/bulk/complete`, { ids })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    bulkDelete(ids: number[]) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}${ApiPath}/bulk/delete`, { ids })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }
}
