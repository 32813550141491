import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/shared/services/util.service';
import {
    copyObjectValues,
    formatPhoneNumber,
    stripPhone
} from '@app/shared/helpers/util';
import * as clone from 'clone';
import { Lead } from '@app/models/lead.model';
import { LeadService } from '@app/shared/services/lead.service';
import { Contact } from '@app/models/contact.model';
import { Router } from '@angular/router';
import { LeadConvertComponent } from '@app/shared/components/lead-convert/lead-convert.component';
import { getConsolidatedPhoneInfo } from '@app/shared/helpers/ui';
import { Phone } from '@app/models/phone.model';

@Component({
    selector: 'app-lead-details',
    templateUrl: './lead-details.component.html',
    styleUrls: ['./lead-details.component.scss']
})
export class LeadDetailsComponent implements OnInit {
    @ViewChild('convertDialog') convertDialog: LeadConvertComponent;
    @Input() lead: Lead;
    leadBackup: Lead;
    @Input() isCondensed = false;
    phones: Phone[];
    isEdit = false;

    newContact: Contact;

    constructor(
        private leadService: LeadService,
        private toaster: ToastrService,
        public utilService: UtilService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.setup();
    }

    private setup() {
        this.phones = getConsolidatedPhoneInfo(this.lead.phones);
    }

    startEdit() {
        this.isEdit = true;
        this.leadBackup = clone(this.lead);
    }

    cancelEdit() {
        this.isEdit = false;
        copyObjectValues(this.leadBackup, this.lead);
        this.setup();
    }

    save() {
        this.isEdit = false;
        this.lead.phones.forEach(ph => {
            ph.value = stripPhone(ph.value);
            ph.display = formatPhoneNumber(ph.value);
        });
        this.leadService.update(
            this.lead
        ).subscribe(
            data => {
                this.phones = getConsolidatedPhoneInfo(this.lead.phones);
            },
            err => this.toaster.error(err)
        );
    }

    addPhone() {
        this.lead.phones.push({ display: '', type: '', value: '' });
    }

    deletePhone(phone: Phone) {
        if (confirm(`Are you sure you want to delete the phone number ${phone.display} from the lead?`)) {
            this.lead.phones.splice(this.lead.phones.indexOf(phone), 1);
        }
    }

    async convert() {
        const res = await this.convertDialog.show(this.lead);
        if (res.isConfirmed && res.value != null) {
            this.lead.i_contact = res.value.i_contact;
        }
    }
}
