<div>
    <div *ngIf="showEllipsis; else noEllipsis" class="message-wrapper">
        <div class="short-message">
            <span *nestedEllipsis="true; indicator moreAnchor"
                  [innerHTML]="message?.content | unicode2html | safetext"></span>
            <ng-template #moreAnchor>
                ...<a class="p-l-5" href="javascript:void(0)" (click)="showEllipsis = false">see more</a>
            </ng-template>
        </div>
    </div>

    <ng-template #noEllipsis>
        <span [innerHTML]="message?.content | unicode2html | safetext"></span>
    </ng-template>

    <span *ngIf="message?.mms">
        <span *ngFor="let mms of message?.mms">
          <a [href]="mms.media_url | safeurl" target="_blank">
              <img alt="mms" [src]="mms.media_url | safeimage" class="mms-image">
          </a>
        </span>
    </span>
</div>