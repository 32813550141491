import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { content } from './shared/routes/content-routes';
import { LoginComponent } from '@app/auth/login/login.component';
import { UserGuard } from '@app/shared/guards/user-guard.service';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '',
        redirectTo: '/requests',
        pathMatch: 'full'
    },
    {
        path: '',
        component: ContentLayoutComponent,
        children: content,
        canActivate: [UserGuard]
    },
    {
        path: '**',
        redirectTo: '/requests'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
