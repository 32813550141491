import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { MlsRecord } from '@app/models/record.model';
import { Observable, Subject } from 'rxjs';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError } from '@app/shared/helpers/api';
import { User } from '@app/models/user.model';

@Injectable({ providedIn: 'root' })
export class ProfileService {

    transactionsLoadedSubject: Subject<boolean> = new Subject<boolean>();

    get transactionsLoaded$() {
        return this.transactionsLoadedSubject.asObservable();
    }

    triggerTransactionsLoaded() {
        this.transactionsLoadedSubject.next(true);
    }

    constructor(private http: HttpClient) {
    }

    getTransactions(agentId: string): Observable<MlsRecord[]> {
        return this.http.get<MlsRecord[]>(
            `${environment.apiUrl}/agent_profile/transactions?agentId=${agentId}`,)
            .pipe(map(data => {
                return data;
            }));
    }

    getProfile() {
        return this.http.get<DataApiResponse<User>>(
            `${environment.apiUrl}/user/profile`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }


    updateProfile(user: Partial<User>) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/user/profile`, user)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

}
