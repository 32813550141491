import { Email} from '@app/models/es-agent.model';
import { Address } from '@app/models/address.model';
import { formatMoney } from '@app/shared/helpers/util';
import { Phone } from '@app/models/phone.model';
import {WebsiteCtaEvent} from '@app/models/website-cta-event.model';

export function getConsolidatedPhoneInfo(phones: Phone[]): Phone[] {
    const consolidated: Record<string, { types: string[] }> = {};
    phones.forEach(ph => {
        consolidated[ph.value] = {
            types: [ph.type, ...(consolidated[ph.value] || { types: [], related: [] }).types],
        };
    });

    return Object.keys(consolidated).map(value => ({
        value,
        display: value,
        type: consolidated[value].types.join('/'),
    }));
}

export function getConsolidatedEmailInfo(emails: Email[]): Email[] {
    const consolidated: Record<string, { types: string[] }> = {};
    emails.forEach(ph => {
        consolidated[ph.value] = {
            types: [ph.type, ...(consolidated[ph.value] || { types: [], related: [] }).types],
        };
    });

    return Object.keys(consolidated).map(value => ({
        value,
        type: consolidated[value].types.filter(s => s?.trim() !== '').join('/'),
    }));
}

export function formatAddress(address: Address): string {
    let ret = `${address.street} ${address.city} ${address.state} ${address.zip_code}`;

    if (address.unit != null) {
        ret = `${ret.trim()} Unit #${address.unit}`;
    }

    if (address.price != null) {
        ret = `${ret.trim()} (${formatMoney(address.price)})`;
    }

    return ret;
}

export function joinAddressComponents(address: Address): string {
    const values = [
        address.street,
        `${address.city ?? ''} ${address.state ?? ''}`,
        address.zip_code,
    ].map(s => s != null ? s.trim() : '').filter(Boolean);
    return values.join(', ');
}

export function formatCTAAction(event: WebsiteCtaEvent): string {
    const values = [
        event.action,
        `${event.contact.first_name ?? ''} ${event.contact.last_name ?? ''}`,
        event.city,
        event.ip,
    ].map(s => s != null ? s.trim() : '').filter(Boolean);

    return values.join(' ');
}
