import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { Notification } from '@app/models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService {

    constructor(private http: HttpClient) {
    }

    markAllRead() {
        return this.http.put<ListResponse<Notification[]>>(
            `${environment.apiUrl}/notifications/markAllRead`, {})
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data;
            }), catchError(handleApiError));
    }

    list(offset: number, limit: number) {
        return this.http.get<ListResponse<Notification[]>>(
            `${environment.apiUrl}/notifications?offset=${offset}&limit=${limit}&tz_offset=${new Date().getTimezoneOffset()}`,
            {
                headers: { ignoreLoadingBar: '' },
            })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data;
            }), catchError(handleApiError));
    }
}
