import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from '@app/models/contact.model';
import { ContactService } from '@app/shared/services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { LeadService } from '@app/shared/services/lead.service';
import { Lead } from '@app/models/lead.model';

@Component({
    selector: 'app-search-contact',
    templateUrl: './search-contact.component.html',
    styleUrls: ['./search-contact.component.scss']
})
export class SearchContactComponent implements OnInit, OnChanges{
    @ViewChild('searchContactText') searchText: any;
    @Input() default: Contact | Lead;
    @Output() contactSelected = new EventEmitter<Contact | Lead>();
    @Input() searchLeads = true;
    @Input() searchContacts = true;
    @Input() defaultText: string;

    autocompleteInput: string;
    placeholder = 'Search contact';

    constructor(
        private contactService: ContactService,
        private leadService: LeadService,
        private toaster: ToastrService,
    ) {
    }

    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term =>
                forkJoin([
                    this.searchContacts ? this.contactService.search(term) : of([]),
                    this.searchLeads ? this.leadService.search(term) : of([]),
                ]).pipe(map(([r1, r2]) => [...r2, ...r1])))
        )
    };

    onTypeaheadSelected(event: NgbTypeaheadSelectItemEvent) {
        this.autocompleteInput = '';
        this.placeholder = `${(event.item as Contact | Lead).first_name} ${(event.item as Contact).last_name}`;
        this.searchText.nativeElement.blur();
        this.invokeEvent(event.item as Contact | Lead);
    }

    formatContact = (contact: Contact | Lead) => `${contact.first_name} ${contact.last_name}`.trim();

    nullFormatter = (contact: Contact | Lead) => '';

    invokeEvent(contact: Contact | Lead) {
        this.contactSelected.emit(contact);
    }

    createContact() {
        this.contactService.insert({ first_name: this.autocompleteInput }).subscribe(contact => {
            this.contactSelected.emit(contact);
        }, error => this.toaster.error(error));
    }

    formatTypeahead(result: Contact | Lead): string {
        return `${result.first_name} ${result.last_name}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.default) {
            if (this.default != null) {
                this.placeholder = this.formatContact(this.default);
            }
        }
    }

    ngOnInit(): void {
        if (this.default != null) {
            this.placeholder = this.formatContact(this.default);
        } else if (this.defaultText != null) {
            this.placeholder = this.defaultText;
        }
    }
}
