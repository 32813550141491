import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';

import { ChatUser, Chat, ChatMessage, Call } from '@app/models/chat.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { JustCallService } from '@app/shared/services/just-call.service';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/shared/services/auth.service';
import { map } from 'rxjs/internal/operators';
import { IntercomService } from '@app/shared/services/intercom.service';
import { CandidateSelection } from '@app/models/agent-selection.model';
import { WebsiteCtaEvent } from '@app/models/website-cta-event.model';

interface WsMessage {
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    private readonly echo: Echo;
    private messagesSubject: Subject<ChatUser[]> = new Subject<ChatUser[]>();
    private newMessageSubject: Subject<ChatUser> = new Subject<ChatUser>();
    private unreadMessagesSubject: Subject<ChatUser[]> = new Subject<ChatUser[]>();
    private selectedChatSubject: BehaviorSubject<ChatUser> = new BehaviorSubject<ChatUser>(null);
    private callsSubject: Subject<Call[]> = new Subject<Call[]>();
    private newRequestSubject: Subject<CandidateSelection> = new Subject<CandidateSelection>();
    private websiteCTAEvent: Subject<WebsiteCtaEvent> = new Subject<WebsiteCtaEvent>();

    constructor(
        private justCallService: JustCallService,
        private intercomService: IntercomService,
        private authService: AuthenticationService,
    ) {
        this.echo = new Echo(
            {
                broadcaster: 'pusher',
                key: environment.pusherKey,
                wsHost: environment.wsHost,
                wsPort: environment.wsPort,
                wssPort: environment.wsPort,
                forceTLS: environment.wsForceTLS,
                authEndpoint: environment.wsAuthUrl,
                cluster: 'mt1',
                disableStats: true,
                auth: { headers: { Authorization: `Bearer ${authService.currentUserValue.token}` } },
            });

        this.echo.private('ah')
            .listen('ChatMessagesUpdated', (message: WsMessage) => {
                const chatUsers: ChatUser[] = JSON.parse(message.message);
                this.messagesSubject.next(chatUsers);
            })
            .listen('UnreadChatMessagesUpdated', (message: WsMessage) => {
                const chatUsers: ChatUser[] = JSON.parse(message.message);
                this.setUnreadMessages(chatUsers);
            })
            .listen('CallsUpdated', (message: WsMessage) => {
                const calls: Call[] = JSON.parse(message.message);
                this.justCallService.convertCallsDates(calls);
                this.callsSubject.next(calls);
            })
            .listen('NewMessageArrived', (message: WsMessage) => {
                const newMessage: ChatUser = JSON.parse(message.message);
                this.newMessageSubject.next(newMessage);
            })
            .listen('NewRequestArrived', (message: WsMessage) => {
                const newRequest: CandidateSelection = JSON.parse(message.message);
                this.newRequestSubject.next(newRequest);
            })
            .listen('WebsiteCtaEvent', (message: WsMessage) => {
                const websiteCtaEvent: WebsiteCtaEvent = JSON.parse(message.message);
                this.websiteCTAEvent.next(websiteCtaEvent);
            });
    }

    public setUnreadMessages(chatUsers: ChatUser[]) {
        this.unreadMessagesSubject.next(chatUsers);
    }

    getNewRequest(): Observable<CandidateSelection> {
        return this.newRequestSubject.asObservable();
    }

    getWebsiteCtaEvent(): Observable<WebsiteCtaEvent> {
        return this.websiteCTAEvent.asObservable();
    }

    getMessage(): Observable<ChatUser[]> {
        return this.messagesSubject.asObservable();
    }

    getUnreadMessages(): Observable<ChatUser[]> {
        return this.unreadMessagesSubject.asObservable();
    }

    getNewMessage(): Observable<ChatUser> {
        return this.newMessageSubject.asObservable();
    }

    getSelectedChat(): Observable<ChatUser> {
        return this.selectedChatSubject.asObservable();
    }

    getSelectedChatUser(): ChatUser {
        return this.selectedChatSubject.value;
    }

    public selectUser(user: ChatUser) {
        this.selectedChatSubject.next(user);
    }

    getCalls(): Observable<Call[]> {
        return this.callsSubject.asObservable();
    }

    public getLatestChats(contactId: number, agentId: string, searchText?: string): Observable<ChatUser[]> {
        return this.justCallService.getLatestChats(contactId, agentId, searchText);
    }

    public getUnreadChats(): Observable<ChatUser[]> {
        return this.justCallService.getUnreadChats().pipe(
            map(chats => {
                this.unreadMessagesSubject.next(chats);
                return chats;
            })
        );
    }

    public getLatestCalls(offset: number, limit: number): Observable<Call[]> {
        return this.justCallService.getLatestCalls(offset, limit);
    }

    public getChatHistory(user: ChatUser): Observable<Chat> {
        return this.justCallService.getChatHistory(user);
    }

    public getCallHistory(user: ChatUser): Observable<Call[]> {
        return this.justCallService.getCallHistory(user);
    }

    public sendMessage(chat: ChatMessage) {
        return this.justCallService.sendMessage(chat);
    }

    public sendIntercomMessage(chat: ChatMessage) {
        return this.intercomService.sendMessage(chat);
    }

    public markAllRead() {
        return this.justCallService.markAllRead();
    }
}
