import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Contact } from '@app/models/contact.model';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from '@app/shared/services/contact.service';
import { PillType, Timeline } from '@app/models/timeline.model';
import { Call, ChatUser, Direction } from '@app/models/chat.model';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EsAgent } from '@app/models/es-agent.model';
import { getDisplayDate, transformPhone, transformSubject, transformUrl } from '@app/shared/helpers/chat';
import { PickedAgentInfo } from '@app/lib/ranking/common';
import { UtilService } from '@app/shared/services/util.service';
import { IPageInfo } from 'ngx-virtual-scroller';
import { RecordChange } from '@app/models/record-change.model';

interface Pill {
    icon: string;
    title: string;
    class: string;
    type: PillType;
    selected: boolean;
}

@Component({
    selector: 'app-contact-timeline',
    templateUrl: './contact-timeline.component.html',
    styleUrls: ['./contact-timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactTimelineComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public currentChat: ChatUser;
    @Input() public agent: EsAgent;
    @Input() isCondensed = false;

    Incoming = Direction.Incoming;
    Outgoing = Direction.Outgoing;
    transformSubject = transformSubject;
    transformPhone = transformPhone;
    transformUrl = transformUrl;
    getDisplayDate = getDisplayDate;
    readonly scrollLimit = 50;
    readonly scrollDelta = 1;
    private scrollWindowSize = this.scrollLimit;

    constructor(
        private contactService: ContactService,
        private toaster: ToastrService,
        private router: Router,
        private ngZone: NgZone,
        public utilService: UtilService,
    ) {
    }

    pills: Pill[] = [
        {
            icon: 'dollar-sign',
            title: 'Listings',
            class: 'badge-primary',
            type: PillType.Listing,
            selected: false,
        },
        {
            icon: 'phone-call',
            title: 'Calls',
            class: 'badge-secondary',
            type: PillType.Call,
            selected: false,
        },
        {
            icon: 'users',
            title: 'Candidates',
            class: 'badge-success',
            type: PillType.Candidate,
            selected: false,
        },
        {
            icon: 'message-circle',
            title: 'Messages',
            class: 'badge-info',
            type: PillType.Message,
            selected: false,
        },
        {
            icon: 'mail',
            title: 'Emails',
            class: 'badge-info',
            type: PillType.Email,
            selected: false,
        },
    ];

    get defaultAvatar(): string {
        return 'assets/images/dashboard/avatar.png';
    }

    @Input() contact: Contact;
    rows: Timeline[] = [];
    rowsScrollWindow: Timeline[] = [];

    private contactChangedSubscription: Subscription = null;
    private destroyed$: Subject<boolean> = new Subject();

    private static getTimelineDate(timeline: Timeline) {
        return timeline.message?.datetime
            ?? timeline.call?.datetime
            ?? timeline.candidate_selection?.date_create
            ?? timeline.record?.effective_date
            ?? timeline.record?.close_date
            ?? timeline.record?.pending_date
            ?? timeline.record?.contract_date
            ?? timeline.change?.change_effective_date
            ?? timeline.email?.date_create;
    }

    ngOnInit(): void {
        this.loadTimeline(this.contact);
        this.contactChangedSubscription = this.contactService.contactChanged
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe((contactId) => {
                if (this.contact.i_contact === contactId) {
                    this.loadTimeline(this.contact);
                }
            });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
        this.contactChangedSubscription.unsubscribe();
    }

    timelineSorter(a: Timeline, b: Timeline): number {
        const d1 = ContactTimelineComponent.getTimelineDate(a);
        const d2 = ContactTimelineComponent.getTimelineDate(b);

        if (!d1 || !d2) {
            return -1;
        }

        const [day1] = d1.split(' ');
        const [day2] = d2.split(' ');
        if (day1 === day2 && (a.change != null && b.change != null)) {
            if (a.change.change_item === 'status') {
                return 1;
            }
            if (b.change.change_item === 'status') {
                return -1;
            }
            return d2.localeCompare(d1);
        } else {
            return d2.localeCompare(d1);
        }
    }

    private loadTimeline(contact: Contact, currentChat?: ChatUser) {
        this.pills.forEach(pill => pill.selected = false);
        this.contactService.getTimeline(contact, currentChat ?? (this.agent ? { agent: this.agent } : null)).subscribe(
            res => {
                this.rows = this.combineRows(res);
                this.rows.sort(this.timelineSorter);
                this.rows.forEach((tl, index) => this.preprocess(tl, index));
                this.rowsScrollWindow = this.getScrollWindow(this.rows);
            },
            err => this.toaster.error(err)
        );
    }

    private preprocess(tl: Timeline, index: number) {
        if (tl.record != null) {
            tl.type = PillType.Listing;
        } else if (tl.call != null) {
            tl.type = PillType.Call;
        } else if (tl.candidate_selection != null) {
            tl.type = PillType.Candidate;
        } else if (tl.message != null) {
            tl.type = PillType.Message;
        } else if (tl.change != null) {
            tl.type = PillType.Listing;
        } else if (tl.email != null) {
            tl.type = PillType.Email;
        }

        tl.date = ContactTimelineComponent.getTimelineDate(tl);
        if (tl.candidate_selection != null) {
            tl.requestTitle = this.getRequestTitle(tl, index);
        } else if (tl.record != null) {
            tl.recordTitle = this.getRecordTitle(tl, index);
            tl.statusTitle = this.getStatusTitle(tl, index);
            tl.priceTitle = this.getPriceTitle(tl, index);
            if (tl.record.agent_id != null) {
                tl.record.agent_id = tl.record.agent_id.replace(':0', ':');
            }
            if (tl.record.selling_agent_id != null) {
                tl.record.selling_agent_id = tl.record.selling_agent_id.replace(':0', ':');
            }
            if (tl.record.colist_agent_mlsid != null) {
                tl.record.colist_agent_mlsid = tl.record.colist_agent_mlsid.replace(':0', ':');
            }
        } else if (tl.change != null) {
            if (tl.change.listing_agent_global_mlsid != null) {
                tl.change.listing_agent_global_mlsid = tl.change.listing_agent_global_mlsid.replace(':0', ':');
            }
            if (tl.change.colist_agent_id != null) {
                tl.change.colist_agent_id = tl.change.colist_agent_id.replace(':0', ':');
            }
            if (tl.change.buyer_agent_global_mlsid != null) {
                tl.change.buyer_agent_global_mlsid = tl.change.buyer_agent_global_mlsid.replace(':0', ':');
            }
            tl.change.isNew = tl.change.changes.some(ch => ch.change_item === 'status' && ch.change_from == null);
        }
    }

    isIncoming(direction: Direction) {
        return direction === Direction.Incoming;
    }

    public getAvatar(call: Call) {
        return this.utilService.getAvatar(call) ?? this.defaultAvatar;
    }

    onAgentClicked(agent: PickedAgentInfo | EsAgent) {
        this.ngZone.run(() => {
            let url;
            const id = (agent as PickedAgentInfo).id ?? (agent as EsAgent)._id;
            if (id !== null) {
                url = this.router.createUrlTree(['/agent', id]);
            } else {
                url = this.router.createUrlTree(['/agent', agent.agent_mlsid, agent.sheet]);
            }
            window.open(url.toString(), '_blank');
        });
    }

    private getRequestTitle(row: Timeline, index: number): string {
        const requests = this.rows.filter(r => r.candidate_selection != null);
        for (let i = requests.length - 1; i > index; i--) {
            if (requests[i].candidate_selection.address_key === row.candidate_selection.address_key) {
                return 'Request Update';
            }
        }

        return 'New Request';
    }

    private getRecordTitle(row: Timeline, index: number) {
        return 'Listing';
    }

    private getPriceTitle(row: Timeline, index: number) {
        return 'Price';
    }

    private getStatusTitle(row: Timeline, index: number) {
        return 'Status';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentChat?.firstChange === false) {
            this.loadTimeline(this.contact, this.currentChat);
        }
    }

    createTooltip(agent: PickedAgentInfo) {
        return `${agent.agent_name} #${agent.rank}`;
    }

    pillClicked(pill: Pill, link: HTMLAnchorElement) {
        pill.selected = !pill.selected;
        link.blur();
        this.scrollWindowSize = this.scrollLimit;
        this.rowsScrollWindow = this.getScrollWindow(this.rows.filter(tl => this.filterByPills(tl, this.pills)));
    }

    filterByPills(tl: Timeline, pills: Pill[]): boolean {
        const selectedPills = pills.filter(pill => pill.selected);
        if (selectedPills.length === 0) {
            return true;
        }
        return selectedPills.some(pill => pill.type === tl.type);
    }

    loadEvents($event: IPageInfo) {
        if ($event.startIndex > this.scrollWindowSize - 30) {
            this.scrollWindowSize += this.scrollDelta;
            this.rowsScrollWindow = this.getScrollWindow(this.rows);
        }
    }

    getScrollWindow(rows: Timeline[]) {
        return rows.slice(0, this.scrollWindowSize);
    }

    private combineRows(res: Timeline[]): Timeline[] {
        const groups: Record<string, RecordChange[]> = {};
        res.forEach(tl => {
            if (tl.change != null) {
                const key = `${tl.change.address_key_normalized}_${tl.change.change_effective_date}`;
                if (groups[key] == null) {
                    groups[key] = [tl.change];
                } else {
                    groups[key].push(tl.change);
                }
            }
        });
        return [...res.filter(tl => tl.change == null), ...Object.values(groups).map(gr => {
            return {
                change: {
                    ...gr[0],
                    changes: gr.map(ch => ({ change_item: ch.change_item, change_from: ch.change_from, change_to: ch.change_to }))
                }
            };
        })];
    }

    getChangeTitle(change: RecordChange): string {
        if (change.isNew) {
            return 'New listing';
        }
        return `Listing ${change.changes.map(ch => ch.change_item).join(', ')} change`;
    }

    getTimelineArray(items: any[]): Timeline[] {
        return items;
    }

    getFragment(id: number): string {
        return `email-${id}`;
    }

    getEmailLink() {
        return this.contact != null ? `/contact/${this.contact.i_contact}/chat` : '/chat';
    }
}
