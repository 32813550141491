import { Pipe, PipeTransform } from '@angular/core';
import { NA } from '@app/shared/helpers/util';
import { TaskPriorities } from '@app/models/task.model';

@Pipe({ name: 'taskPriority' })
export class TaskPriorityPipe implements PipeTransform {
    transform(value: number): string {
        if (value == null) {
            return NA;
        }
        return TaskPriorities.find(tp => tp.value === value)?.name ?? NA;
    }
}
