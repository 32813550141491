import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@app/models/address.model';
import { joinAddressComponents } from '@app/shared/helpers/ui';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
    transform(value: Address): string {
        if (value == null) {
            return undefined;
        }
        return joinAddressComponents(value);
    }
}
