import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { AuthenticationService } from '@app/shared/services/auth.service';
import { SavedFilterService } from '@app/shared/services/saved-filter.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit{
    public menuItems: Menu[];

    constructor(
        private router: Router,
        public navServices: NavService,
        public authService: AuthenticationService,
        private savedFilterService: SavedFilterService,
    ) {
    }

    ngOnInit(): void {
        this.loadSavedFilters();

        this.savedFilterService.savedFilterChanged.subscribe(res => {
            if (res) {
                this.loadSavedFilters();
            }
        });

        this.navServices.items.subscribe(menuItems => {
            this.menuItems = menuItems;
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    menuItems.forEach(items => {
                        if (items.path === event.url) {
                            this.setNavActive(items);
                        }
                        if (!items.children) {
                            return false;
                        }
                        items.children.forEach(subItems => {
                            if (subItems.path === event.url) {
                                this.setNavActive(subItems);
                            }
                            if (!subItems.children) {
                                return false;
                            }
                            subItems.children.forEach(subSubItems => {
                                if (subSubItems.path === event.url) {
                                    this.setNavActive(subSubItems);
                                }
                            });
                        });
                    });
                    this.navServices.onResize();
                }
            });
        });
    }

    private loadSavedFilters() {
        forkJoin([
            this.savedFilterService.list(0, 1000, null, 'tasks'),
            this.savedFilterService.list(0, 1000, null, 'contacts'),
            this.savedFilterService.list(0, 1000, null, 'agents'),
        ]).subscribe(([taskFilters, contactsFilters, agentsFilters]) => {
            this.navServices.setFilters(taskFilters.data, contactsFilters.data, agentsFilters.data);
        });
    }

// Active Nave state
    setNavActive(item) {
        this.menuItems.forEach(menuItem => {
            if (menuItem !== item) {
                menuItem.active = false;
            }
            if (menuItem.children && menuItem.children.includes(item)) {
                menuItem.active = true;
            }
            if (menuItem.children) {
                menuItem.children.forEach(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true;
                        submenuItems.active = true;
                    }
                });
            }
        });
    }

    toggleNavActive(item) {
        if (!item.active) {
            this.menuItems.forEach(a => {
                if (this.menuItems.includes(item)) {
                    a.active = false;
                }
                if (!a.children) {
                    return false;
                }
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false;
                    }
                });
            });
        }
        item.active = !item.active;
    }

    get currentUser() {
        return this.authService.currentUserValue;
    }
}
