import { Pipe, PipeTransform } from '@angular/core';
import { NA } from '@app/shared/helpers/util';

@Pipe({ name: 'na' })
export class NaPipe implements PipeTransform {
    transform(value: string | number): string {
        if (value == null || value === '') {
            return NA;
        }
        return `${value}`;
    }
}
