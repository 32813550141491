import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, SortModel } from '@app/shared/helpers/api';
import { Config } from '@app/models/config.model';
import { ChatMessage } from '@app/models/chat.model';
import { IntercomAdmin } from '@app/models/intercom/admin.model';

@Injectable({ providedIn: 'root' })
export class IntercomService {
    constructor(private http: HttpClient) {
    }

    test(config: Config) {
        return this.http.put<DataApiResponse<Config>>(`${environment.apiUrl}/intercom/test`, config)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }

    sendMessage(message: ChatMessage) {
        return this.http.post<ApiResponse>(`${environment.apiUrl}/intercom/message`, message)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data;
            }), catchError(handleApiError));
    }

    getAdmins() {
        return this.http.get<DataApiResponse<IntercomAdmin[]>>(`${environment.apiUrl}/intercom/admins`)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }

}
