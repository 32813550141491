<swal #taskEdit
      [title]="''"
      [showCancelButton]="false"
      [showConfirmButton]="false"
      [showCloseButton]="false"
      [width]="900"
      [heightAuto]="true"
      [focusCancel]="false"
      [focusConfirm]="false"
>
    <div *swalPortal>
        <form class="theme-form" *ngIf="isShown">
            <div class="form-group row text-left" *ngIf="currentTemplate">
                <label class="col-sm-4 col-form-label">Template</label>
                <div class="col-sm-8">
                    <app-template-selector [type]="'task'"
                                           [initialTemplate]="currentTemplate"
                                           [initialRelatedAgent]="currentRelatedAgent"
                                           [initialRelatedContact]="currentRelatedContact"
                                           [initialRelatedAddress]="currentRelatedAddress"
                                           [entity]="entity"
                                           [relatedAgents]="currentChat?.related_agents"
                                           [relatedContacts]="currentChat?.related_contacts"
                                           (templateChanged)="onTemplateComponentSelected($event)"></app-template-selector>
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Subject</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="task.title"
                           [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Related To</label>
                <div class="col-sm-8">
                    <select class="form-control"
                            [(ngModel)]="task.related_to"
                            [ngModelOptions]="{standalone: true}">
                        <option [value]="undefined">Please select</option>
                        <option *ngFor="let relatedTo of relatedToOptions"
                                [value]="relatedTo">{{ relatedTo }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row text-left" *ngIf="task.related_to === 'Contact' || task.related_to === 'Request'">
                <label class="col-sm-4 col-form-label">Contact</label>
                <div class="col-sm-8">
                    <app-search-contact [defaultText]="task.contact" [searchLeads]="false"
                                        (contactSelected)="setContact($event)"></app-search-contact>
                </div>
            </div>
            <div class="form-group row text-left" *ngIf="requests">
                <label class="col-sm-4 col-form-label">Request</label>
                <div class="col-sm-8">
                    <select class="form-control"
                            [(ngModel)]="task.i_candidate_selection"
                            [ngModelOptions]="{standalone: true}">
                        <option [ngValue]="undefined">Please select</option>
                        <option *ngFor="let request of requests"
                                [ngValue]="request.i_candidate_selection">
                            #{{ request.i_candidate_selection }} {{ request.address | address }} {{ request.price | currency }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row text-left" *ngIf="task.related_to === 'Address'">
                <label class="col-sm-4 col-form-label">Address</label>
                <div class="col-sm-8">
                    <app-search-address [default]="task.address"
                                        (addressSelected)="setAddress($event)"></app-search-address>
                </div>
            </div>
            <div class="form-group row text-left" *ngIf="task.related_to === 'Agent'">
                <label class="col-sm-4 col-form-label">Agent</label>
                <div class="col-sm-8">
                    <app-search-agent [default]="task.agent" (agentSelected)="setAgent($event)"></app-search-agent>
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Status</label>
                <div class="col-sm-8">
                    <select class="form-control"
                            [(ngModel)]="task.status"
                            [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let status of statuses"
                                [value]="status">{{ status }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Type</label>
                <div class="col-sm-8">
                    <select class="form-control"
                            [(ngModel)]="task.i_task_type"
                            [ngModelOptions]="{standalone: true}">
                        <option [value]="undefined">Please select</option>
                        <option *ngFor="let taskType of taskTypes"
                                [value]="taskType.i_task_type">{{ taskType.name }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Priority</label>
                <div class="col-sm-8">
                    <select class="form-control"
                            [(ngModel)]="task.priority"
                            [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let priority of priorities"
                                [value]="priority.value">{{ priority.name }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Due Date</label>
                <div class="col-sm-8">
                    <input class="form-control digits" type="datetime-local" [(ngModel)]="task.due_date"
                           [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Assign to</label>
                <div class="col-sm-8">
                    <select class="form-control"
                            [(ngModel)]="task.i_user"
                            [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let user of users"
                                [value]="user.i_user">{{ user.first_name }} {{ user.last_name }} ({{ user.email }})
                        </option>
                    </select>
                    <span class="checkbox m-l-5">
                        <input [(ngModel)]="task.notify_user"
                               [ngModelOptions]="{standalone: true}"
                               type="checkbox"
                               id="notify_user"
                        />
                        <label for="notify_user" class="f-14">Notify user</label>
                    </span>
                </div>
            </div>
            <div class="form-group row text-left">
                <label class="col-sm-4 col-form-label">Description</label>
                <div class="col-sm-8">
                    <ckeditor [editor]="Editor"
                              [config]="config"
                              [(ngModel)]="task.description"
                              [ngModelOptions]="{standalone: true}"
                    ></ckeditor>
                </div>
            </div>
            <div>
                <button class="btn btn-primary m-b-0 m-r-10" [disabled]="!canSubmit()"
                        (click)="submit()">{{ task?.i_task ? 'Save' : 'Add' }}
                </button>
                <button class="btn btn-secondary m-b-0" (click)="cancel()">Cancel</button>
            </div>
        </form>
    </div>
</swal>
