<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-header-left">
          <!-- <h3>Agenthawk</h3> -->
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="''">
              <app-feather-icons [icon]="'home'"></app-feather-icons>
            </a></li>
            <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
            <li class="breadcrumb-item active" [ngClass]="{ active: !pageBreadcrumb }" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
            </li>
            <li class="breadcrumb-item active" *ngIf="pageBreadcrumb" [innerHTML]="pageBreadcrumb | safetext"></li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
