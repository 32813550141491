import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Contact } from '@app/models/contact.model';
import { Email } from '@app/models/es-agent.model';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/shared/services/util.service';
import { copyObjectValues, formatPhoneNumber, stripPhone } from '@app/shared/helpers/util';
import { ContactService } from '@app/shared/services/contact.service';
import * as clone from 'clone';
import { getConsolidatedEmailInfo, getConsolidatedPhoneInfo } from '@app/shared/helpers/ui';
import { Phone } from '@app/models/phone.model';
import { SettingsService } from '@app/shared/services/settings.service';
import { Config } from '@app/models/config.model';

@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit, OnChanges {
    @Input() contact: Contact;
    contactBackup: Contact;
    @Input() isCondensed = false;
    @Output() addressClick: EventEmitter<void> = new EventEmitter<void>();
    phones: Phone[];
    emails: Email[];
    isEdit = false;
    primaryEmail: string;
    primaryPhone: string;
    config: Config;

    constructor(
        private contactService: ContactService,
        private toaster: ToastrService,
        public utilService: UtilService,
        private settingsService: SettingsService,
    ) {
    }

    ngOnInit(): void {
        this.settingsService.get().subscribe(config => this.config = config);
        this.setup();
    }

    private setup() {
        this.phones = getConsolidatedPhoneInfo(this.contact.phones);
        this.emails = getConsolidatedEmailInfo(this.contact.emails);
        this.primaryEmail = this.contact.primary_email;
        this.primaryPhone = this.contact.primary_phone;
    }

    startEdit() {
        this.isEdit = true;
        this.contactBackup = clone(this.contact);
    }

    cancelEdit() {
        this.isEdit = false;
        copyObjectValues(this.contactBackup, this.contact);
        this.setup();
    }

    save() {
        this.isEdit = false;
        this.contact.phones.forEach(ph => {
            ph.value = stripPhone(ph.value);
            ph.display = formatPhoneNumber(ph.value);
        });
        this.contactService.update(
            this.contact
        ).subscribe(
            data => {
                this.phones = getConsolidatedPhoneInfo(this.contact.phones);
                this.emails = getConsolidatedEmailInfo(this.contact.emails);
            },
            err => this.toaster.error(err)
        );
    }

    addPhone() {
        this.contact.phones.push({ display: '', type: '', value: '' });
    }

    addEmail() {
        this.contact.emails.push({ type: '', value: '' });
    }

    deletePhone(phone: Phone) {
        if (confirm(`Are you sure you want to delete the phone number ${phone.display} from the contact?`)) {
            this.contact.phones.splice(this.contact.phones.indexOf(phone), 1);
        }
    }

    deleteEmail(email: Email) {
        if (confirm(`Are you sure you want to delete the email address ${email.value} from the contact?`)) {
            this.contact.emails.splice(this.contact.emails.indexOf(email), 1);
        }
    }

    onPrimaryEmailChange(email: Email) {
        if (email.is_primary) {
            this.contact.emails.filter(em => em !== email).forEach(em => (em.is_primary = false));
            this.primaryEmail = email.value;
        } else {
            this.primaryEmail = null;
        }
    }

    onPrimaryPhoneChange(phone: Phone) {
        if (phone.is_primary) {
            this.contact.phones.filter(ph => ph !== phone).forEach(ph => (ph.is_primary = false));
            this.primaryPhone = phone.value;
        } else {
            this.primaryPhone = null;
        }
    }

    automationChanged(value: boolean) {
        this.contactService.setAutomation(this.contact, value).subscribe(
            () => {
                this.contact.is_automation = value;
                this.contactService.triggerContactChanged(this.contact.i_contact);
            },
            err => this.toaster.error(err));
    }

    emailEnabledChanged(value: boolean) {
        this.contactService.setEmailEnabled(this.contact, value).subscribe(
            () => {
                this.contact.is_email_enabled = value;
                this.contactService.triggerContactChanged(this.contact.i_contact);
            },
            err => this.toaster.error(err));
    }

    addressClicked() {
        this.addressClick.emit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.agent != null) {
            this.utilService.setupPrimaryPhoneAndEmail(this.contact);
        }
    }
}
