import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@app/models/contact.model';

@Pipe({ name: 'contact' })
export class ContactPipe implements PipeTransform {
    transform(value: Contact): string {
        if (value == null) {
            return undefined;
        }
        return `${value.first_name} ${value.last_name}`;
    }
}
