<app-feather-icons [icon]="'bell'"></app-feather-icons>
<div class="unread-count" *ngIf="total > 0">{{ total }}</div>

<ul class="notification-dropdown onhover-show-div custom-scrollbar" #scrollingBlock>
    <li>
        You have <span class="badge badge-pill badge-primary">{{ total }}</span> unread notifications
        <a (click)="markAllRead()" class="pull-right text-primary">Mark all as Read</a>
    </li>
    <virtual-scroller #scroll [items]="notifications" [parentScroll]="scrollingBlock" (vsEnd)="loadEvents($event)">
        <li *ngFor="let notification of scroll.viewPortItems">
            <div class="media">
                <div class="media-body">
                    <a (click)="viewNotification(notification, $event)" [href]="notification.link" [ngClass]="{ 'selection': notification.selection != null, 'task': notification.task != null }">
                        <h6 class="mt-0">
                    <span>
                        <i class="icofont icofont-tasks" *ngIf="notification.i_task"></i>
                        <i class="icofont icofont-architecture-alt" *ngIf="notification.i_candidate_selection"></i>
                    </span>{{ notification.subject }}
                            <small class="pull-right" *ngIf="!notification.hover"
                                   (mouseenter)="notification.hover = true">
                                {{ notification.due_date || notification.date_create | timeSince:false: true }}
                            </small>
                            <small class="pull-right" *ngIf="notification.hover"
                                   (mouseleave)="notification.hover = false" (mouseout)="notification.hover = false">
                                <a (click)="markRead($event, notification)" class="text-primary">Mark as Read</a>
                            </small>
                        </h6>
                    </a>
                    <p class="mb-0 notification-body" [innerHTML]="notification.body" *ngIf="notification.body">
                    </p>
                    <p class="mb-0 notification-body" *ngIf="notification.address">
                        {{ notification.address | address }}
                    </p>
                </div>
            </div>
        </li>
    </virtual-scroller>
</ul>
<app-task-edit #taskEditN></app-task-edit>