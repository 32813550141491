// Chat users
import { Contact } from '@app/models/contact.model';
import { EsAgent } from '@app/models/es-agent.model';
import { ChatImage } from '@app/shared/components/chat-image/chat-image.component';
import { Address } from '@app/models/address.model';
import { EmailMessage, GmailEmail } from '@app/models/gmail/email.model';
import { RequestAgent } from '@app/models/request-agent.model';
import { Lead } from '@app/models/lead.model';

export interface ChatUser {
    id?: number;
    from_number?: string;
    contact_number?: string;
    contact_email?: string;
    contact_name?: string;
    contact_id?: string;
    conversation_id?: string;
    status?: string;
    seen?: string;
    online?: boolean;
    contact?: Contact;
    agent?: EsAgent;
    lead?: Lead;
    latest_message_datetime?: string;
    latest_sms_datetime?: string;
    latest_email_datetime?: string;
    latest_intercom_datetime?: string;
    latest_message_content?: string;
    latest_direction?: Direction;
    sms_unread_count?: number;
    email_unread_count?: number;
    intercom_unread_count?: number;
    is_latest_message?: boolean;
    is_latest_email?: boolean;
    is_latest_intercom?: boolean;
    request_agent?: RequestAgent;
}

export interface Call {
    contact?: Contact;
    agent?: EsAgent;
    status?: string;
    datetime?: string;
    call_date?: string;
    call_time?: string;
    subject?: string;
    description?: string;
    direction?: Direction,
    called_via?: string;
    contact_name?: string;
    contact_number?: string;
    contact_email?: string;
    recording_url?: string;
    call_status?: string;
    call_duration?: string;
    signature?: string;
    notes?: string;
    disposition_code?: string;
    agent_name?: string;
    requestid?: string;
    recordingmp3?: string;
    callinfo?: string;
    call_sid?: string;
    missed_call_type?: string;
    lead?: Lead;
}

export enum Direction {
    Outgoing,
    Incoming,
}

export interface MMS {
    media_url: string;
    content_type: string;
}

// Messages
export interface ChatMessage {
    sender?: number;
    receiver?: ChatUser;
    date_create?: string;
    time_create?: string;
    datetime?: string;
    content?: string;
    direction: Direction;
    mms?: MMS[];
    images?: string;
    i_user?: number;
    agent_id?: number;
    is_bot?: boolean;
}

// Chate
export interface Chat {
    id?: number;
    messages?: ChatMessage[];
    intercomMessages?: ChatMessage[];
    emails?: EmailMessage[];
    related_agents?: EsAgent[];
    related_contacts?: Contact[];
    related_addresses?: Address[];
    contact?: Contact;
    agent?: EsAgent;
}
