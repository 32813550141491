import { Routes } from '@angular/router';
import { AdminGuard } from '@app/shared/guards/admin-guard.service';

export const content: Routes = [
  {
    path: '',
    loadChildren: () => import('../../components/crm/crm.module').then(m => m.CrmModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../components/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'current',
    loadChildren: () => import('../../components/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('../../components/chat/chat.module').then(m => m.ChatModule),
    data: {
      breadcrumb: 'Chat'
    }
  },
/*
  {
    path: 'email',
    loadChildren: () => import('../../components/email/email.module').then(m => m.EmailModule),
    data: {
      breadcrumb: 'Email'
    }
  },
*/
];
