import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Address } from '@app/models/address.model';
import { Observable, throwError } from 'rxjs';
import { AreaStatsRow, MlsRecord } from '@app/models/record.model';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, SortModel } from '@app/shared/helpers/api';
import { Contact } from '@app/models/contact.model';

export interface AddressSearchResult {
    address_key: string;
    formatted_address: string;
    street: string;
    converted_address: string;
    city: string;
    state: string;
    zip_code: string;
    lat: number;
    lng: number;
    price: number;
    contact?: Contact;
}

interface SearchResult {
    data: AddressSearchResult[];
}

@Injectable({ providedIn: 'root' })
export class AddressService {

    constructor(private http: HttpClient) {
    }

    list(page: number, count: number, searchText: string, sort?: SortModel) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<any>(
            `${environment.apiUrl}/map_address?page=${page}&count=${count}&search_text=${searchText}${sortStr}`,)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data;
            }), catchError(handleApiError));
    }

    get(addressId: string, isForce = false) {
        return this.http.get<Address>(`${environment.apiUrl}/map_address/${addressId}?is_force=${isForce ? 1 : 0}&tz_offset=${new Date().getTimezoneOffset()}`);
    }

    ensurePlace(address: google.maps.places.PlaceResult, unit?: string, price?: number) {
        return this.http.post<any>(`${environment.apiUrl}/map_address/ensure/place`, { address, unit, price })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.address_key;
            }), catchError(handleApiError));
    }

    ensureAddress(address: AddressSearchResult, price?: number) {
        return this.http.post<any>(`${environment.apiUrl}/map_address/ensure/address`, { address, price })
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.address_key_normalized;
            }), catchError(handleApiError));
    }

    ensureRecord(record: Partial<MlsRecord>): Observable<string> {
        return this.http.post<any>(`${environment.apiUrl}/map_address/ensure/record`, record)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.address_key;
            }), catchError(handleApiError));
    }

    candidates(zipCode: string, noCache: boolean = false) {
        return this.http.get<any>(`${environment.apiUrl}/ml/candidates?zip_code=${zipCode}&no_cache=${noCache ? 1 : 0}`).pipe(
            catchError(handleApiError)
        );
    }

    search(searchText: string) {
        return this.http.get<SearchResult>(`${environment.apiUrl}/search/map_address?search_text=${searchText}`)
            .pipe(map(data => data.data));
    }

    getAreaStats(address: Address, noCache: boolean = false) {
        return this.http.get<DataApiResponse<AreaStatsRow[]>>(`${environment.apiUrl}/area_stats?lat=${address.lat}&lng=${address.lng}&no_cache=${noCache ? 1 : 0}`)
            .pipe(map(data => data.data));
    }

    getByContact(contactId: number) {
        return this.http.get<DataApiResponse<Address[]>>(`${environment.apiUrl}/map_address/contact/${contactId}`)
            .pipe(map(data => {
                handleApplicationError(data as ApiResponse);
                return data.data;
            }), catchError(handleApiError));
    }
}
