import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { User } from '@app/models/user.model';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    private allUsersSubject = new ReplaySubject<User[]>(1);

    public get allUsers$(): Observable<User[]> {
        return this.allUsersSubject.asObservable();
    }

    public fetchUsers() {
        this.listAll()
            .subscribe(data => this.allUsersSubject.next(data));
    }

    constructor(private http: HttpClient) {
    }

    list(page: number, count: number, searchText: string, sort?: SortModel) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<ListResponse<User[]> | ApiResponse>(
            `${environment.apiUrl}/admin/user?page=${page}&count=${count}&search_text=${searchText}&tz_offset=${new Date().getTimezoneOffset()}${sortStr}`,)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<User[]>;
            }), catchError(handleApiError));
    }

    update(user: User) {
        return this.http.put<DataApiResponse<User>>(
            `${environment.apiUrl}/admin/user/${user.i_user}`, user)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    get(userId: number) {
        return this.http.get<DataApiResponse<User>>(
            `${environment.apiUrl}/admin/user/${userId}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    getToken(userId: number) {
        return this.http.get<DataApiResponse<string>>(
            `${environment.apiUrl}/admin/user/${userId}/token`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(userId: number) {
        return this.http.delete(
            `${environment.apiUrl}/admin/user/${userId}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(user: User) {
        return this.http.post<DataApiResponse<User>>(
            `${environment.apiUrl}/admin/user`, user)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }

    search(searchText: string) {
        return this.http.get<DataApiResponse<User[]>>(`${environment.apiUrl}/admin/search/user?search_text=${searchText}`)
            .pipe(map(data => data.data));
    }

    listAll() {
        return this.http.get<DataApiResponse<User[]>>(`${environment.apiUrl}/user/all`)
            .pipe(map(data => {
                this.allUsersSubject.next(data.data);
                return data.data;
            }));
    }
}
