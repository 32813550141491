<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none">
            <div class="logo-wrapper"><a href="javascript::void(0)"><img src="assets/images/agenthawk/logo-light.png"
                                                                         alt=""></a></div>
        </div>
        <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
                <label class="switch">
                    <a href="javascript:void(0)" (click)="collapseSidebar()">
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li>
                    <form class="form-inline search-form search-form-header">
                        <div class="form-group">
                            <div class="input-group pill-input-group flex-nowrap">
                                <div class="input-group-prepend" [class.open]="isOpenMobile">
                                            <span class="input-group-text p-l-20"><i
                                                    class="icofont icofont-search"></i></span>
                                </div>
                                <input class="form-control-plaintext"
                                       type="text"
                                       autocomplete="off"
                                       [placeholder]="searchPlaceholder"
                                       #searchText
                                       [(ngModel)]="autocompleteInput"
                                       [ngModelOptions]="{standalone: true}"
                                       [class.open]="isOpenMobile"
                                       [ngbTypeahead]="search"
                                       [inputFormatter]="formatPlace"
                                       [resultTemplate]="rt"
                                       (selectItem)="onTypeaheadSelected($event)"
                                >
                                <div class="input-group-append">
                                            <span class="input-group-text"><i
                                                    *ngIf="hasAddress"
                                                    class="icofont icofont-ui-pointer clickable m-r-10"
                                                    (click)="moveToAddress()" title="Go to address"></i><i
                                                    *ngIf="hasAddress"
                                                    class="icofont icofont-ui-close clickable"
                                                    (click)="removeAddress()"
                                                    title="Clear address"></i></span>
                                </div>
                            </div>
                            <span class="d-sm-none mobile-search">
                                <app-feather-icons [icon]="'search'"
                                                   (click)="isOpenMobile=!isOpenMobile"></app-feather-icons>
                                <app-feather-icons [icon]="'crosshair'"
                                                   *ngIf="hasAddress"
                                                   class="clickable m-r-10 m-l-10"
                                                   title="Go to address"
                                                   (click)="moveToAddress()"></app-feather-icons>
                                <app-feather-icons [icon]="'x'"
                                                   *ngIf="hasAddress"
                                                   title="Clear address"
                                                   class="clickable"
                                                   (click)="removeAddress()"></app-feather-icons>
                            </span>
                        </div>
                    </form>
                </li>
                <li>
                    <a href="javascript:void(0)" class="text-dark" appToggleFullscreen title="Fullscreen mode">
                        <app-feather-icons [icon]="'maximize'"></app-feather-icons>
                    </a>
                </li>
                <!--
                        <li class="onhover-dropdown">
                          <a class="txt-dark" href="javascript:void(0)">
                            <h6>EN</h6>
                          </a>
                          <ul class="language-dropdown onhover-show-div p-20">
                            <li><a href="javascript:void(0)" data-lng="en"><i
                                  class="flag-icon flag-icon-is"></i> English</a></li>
                            <li><a href="javascript:void(0)" data-lng="es"><i
                                  class="flag-icon flag-icon-um"></i> Spanish</a></li>
                            <li><a href="javascript:void(0)" data-lng="pt"><i
                                  class="flag-icon flag-icon-uy"></i> Portuguese</a></li>
                            <li><a href="javascript:void(0)" data-lng="fr"><i
                                  class="flag-icon flag-icon-nz"></i> French</a></li>
                          </ul>
                        </li>
                        -->
                        <li class="onhover-dropdown">
                            <app-notifications></app-notifications>
                        </li>
                        <li>
                            <a>
                                <app-feather-icons class="right_side_toggle" [icon]="'message-circle'" (click)="rightSideBarClick()" (mouseenter)="openRightSideBar()">
                                </app-feather-icons>
                                <span class="unread-count" *ngIf="unreadLength > 0">
                                    {{ unreadLength }}
                                </span>
                            </a>
                        </li>

                <li class="onhover-dropdown">
                    <div class="media align-items-center">
                        <app-avatar class="align-self-center pull-right "
                                    [size]="50"
                                    [src]="currentUser.avatar"
                                    [tooltip]="null"
                                    [name]="currentUser.first_name + ' ' + currentUser.last_name"></app-avatar>
                        <!--            <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>-->
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20">
                                    <li><a [routerLink]="'/current/profile'">
                                        <app-feather-icons [icon]="'user'"></app-feather-icons>Edit Profile
                                      </a></li>
                        <!--
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'mail'"></app-feather-icons>Inbox
              </a></li>
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'lock'"></app-feather-icons>Lock Screen
              </a></li>-->
                        <li class="onhover-dropdown-l2" *ngIf="currentUser?.is_admin">
                            <a>
                            <app-feather-icons [icon]="'settings'"></app-feather-icons>Admin
                            </a>
                            <ul class="profile-dropdown onhover-show-div-l2 p-20">
                                <li *ngFor="let item of adminMenuItems" [ngClass]="{'menu-separator': item.separated}">
                                    <a [routerLink]="item.path">
                                        <app-feather-icons [icon]="item.icon" *ngIf="item.icon"></app-feather-icons>
                                        {{ item.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li><a href="javascript:void(0)" (click)="logout()">
                            <app-feather-icons [icon]="'log-out'"></app-feather-icons>
                            Logout
                        </a></li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>
<!-- Page Header Ends -->

<ng-template #rt let-r="result" let-t="term">
    <i class="icofont icofont-businessman m-r-5" *ngIf="isAgentSearchResult(r)"></i>
    <i class="icofont icofont-social-google-map m-r-5" *ngIf="isGoogleResult(r)"></i>
    <app-feather-icons class="m-r-5" [icon]="'user'" *ngIf="isAddressSearchResult(r)"></app-feather-icons>
    <app-feather-icons class="m-r-5" [icon]="'user'" *ngIf="isContactSearchResult(r)"></app-feather-icons>
    <app-feather-icons class="m-r-5" [icon]="'target'" *ngIf="isLeadSearchResult(r)"></app-feather-icons>
    <ngb-highlight [result]="formatTypeahead(r)" [term]="t"></ngb-highlight>
    <input type="number" *ngIf="isGoogleResult(r)|| isAddressSearchResult(r)" placeholder="$" class="form-control-sm price-input m-l-10" #price
           (click)="typeaheadInputClick($event, price)" [(ngModel)]="r.price">
    <input type="text" *ngIf="isGoogleResult(r)" placeholder="Unit#" class="form-control-sm unit-input m-l-10" #unit
           (click)="typeaheadInputClick($event, unit)" [(ngModel)]="r.unit">
</ng-template>
