import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AddressSearchResult, AddressService } from '@app/shared/services/address.service';
import { Router } from '@angular/router';
import { LatLngLiteral } from '@agm/core';
import { Address } from '@app/models/address.model';

export interface PlaceInfo {
    place?: google.maps.places.PlaceResult;
    address?: AddressSearchResult;
    unit?: string;
    price?: number;
}

@Injectable({ providedIn: 'root' })
export class SearchService {

    private searchSource = new Subject<PlaceInfo>();
    private currentPlaceSubject = new BehaviorSubject<PlaceInfo>(null);

    constructor(
        private addressService: AddressService,
        public ngZone: NgZone,
        private router: Router,
    ) {
    }

    public get search() {
        return this.searchSource.asObservable();
    }

    public get currentPlace() {
        return this.currentPlaceSubject.getValue();
    }

    public get currentLocation(): LatLngLiteral {
        const current = this.currentPlace;
        if (current != null) {
            if (current.place != null) {
                return {
                    lat: current.place.geometry.location.lat(),
                    lng: current.place.geometry.location.lng()
                };
            } else {
                return {
                    lat: Number(current.address.lat),
                    lng: Number(current.address.lng),
                };
            }
        }
        return null;
    }

    public get currentLocationAddress(): Address {
        const current = this.currentPlace;
        if (current != null) {
            if (current.address != null) {
                return {
                    ...current.address,
                    unit: '',
                    property: null,
                    records: [],
                    contacts: [],
                    melissa_property_info: null,
                    melissa_deeds_info: null,
                } as Address;
            } else {
                return {
                    street: current.place?.formatted_address,
                    city: '',
                    state: '',
                    zip_code: '',
                    address_key: null,
                    unit: current.unit,
                    property: null,
                    records: [],
                    contacts: [],
                    price: null,
                    lat: null,
                    lng: null,
                    melissa_property_info: null,
                    melissa_deeds_info: null
                };
            }
        }
        return null;
    }

    public get currentLocationName(): string {
        const current = this.currentPlace;
        if (current != null) {
            return current.address?.formatted_address ||
                `${current.place?.formatted_address} ${current.unit != null ? ` Unit ${current.unit}` : ''}`;
        }
        return null;
    }

    public searchComplete(term: PlaceInfo) {
        this.searchSource.next(term);
        this.currentPlaceSubject.next(term);
    }

    public ensureAddress(placeResult: PlaceInfo) {
        if (placeResult.place != null) {
            this.addressService.ensurePlace(placeResult.place, placeResult.unit, placeResult.price)
                .pipe(first())
                .subscribe(
                    addressId => {
                        this.ngZone.run(() => {
                            this.router.navigate(['/address', addressId]);
                        })
                    });
        } else if (placeResult.address != null) {
            this.addressService.ensureAddress(placeResult.address, placeResult.price)
                .pipe(first())
                .subscribe(
                    addressId => {
                        this.ngZone.run(() => {
                            this.router.navigate(['/address', placeResult.address.address_key]);
                        })
                    });

        }
    }

    clearCurrentPlace() {
        this.searchComplete(null);
    }

    gotoCurrentPlace() {
        this.ensureAddress(this.currentPlace);
    }
}
