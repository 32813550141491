/// <reference types="googlemaps" />

import { Component, OnInit, Output, EventEmitter, NgZone, ViewChild, AfterViewInit, ViewEncapsulation, HostListener } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { AuthenticationService } from '@app/shared/services/auth.service';
import { Router } from '@angular/router';
import { SearchService } from '@app/shared/services/search.service';
import { MapsAPILoader } from '@agm/core';
import { forkJoin, Observable, of, pipe } from 'rxjs';
import { debounceTime, distinctUntilChanged, flatMap, map, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AgentSearchResult, AgentService } from '@app/shared/services/agent.service';
import { AddressSearchResult, AddressService } from '@app/shared/services/address.service';
import { ContactService } from '@app/shared/services/contact.service';
import { LeadService } from '@app/shared/services/lead.service';
import { Lead } from '@app/models/lead.model';
import { Contact } from '@app/models/contact.model';
import { formatMoney } from '@app/shared/helpers/util';
import { ChatService } from '@app/shared/services/chat.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterViewInit {

    constructor(
        public navServices: NavService,
        public authService: AuthenticationService,
        private router: Router,
        public ngZone: NgZone,
        private searchService: SearchService,
        private addressService: AddressService,
        private agentService: AgentService,
        private contactService: ContactService,
        private leadService: LeadService,
        private mapsApiLoader: MapsAPILoader,
        public chatService: ChatService,
    ) {
    }

    get currentUser() {
        return this.authService.currentUserValue;
    }

    get hasAddress() {
        return this.searchService.currentPlace != null;
    }

    get searchPlaceholder() {
        return this.searchService.currentLocationName || 'Search...';
    }
    @ViewChild('searchText') searchText: any;
    @ViewChild('searchDiv') searchDiv: HTMLDivElement;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();

    public menuItems: Menu[];
    public items: Menu[];
    public adminMenuItems: Menu[];
    public openNav = false;
    public rightSidebar = false;
    public text: string;
    public isOpenMobile = false;

    autocompleteInput: string;

    @Output() rightSidebarEvent = new EventEmitter<boolean>();
    private autocompleteService: google.maps.places.AutocompleteService;
    private placesService: google.maps.places.PlacesService;

    unreadLength = 0;

    ngOnInit() {
        this.navServices.items.subscribe(menuItems => {
            this.items = menuItems;
        });
        this.navServices.adminMenuItems.subscribe(menuItems => {
            this.adminMenuItems = menuItems;
        });
        this.chatService.getUnreadChats().subscribe(() => {});
        this.chatService.getUnreadMessages().subscribe(unread => this.unreadLength = unread.length);
        this.navServices.rightSidebarClosed$.subscribe(() => this.rightSidebar = false);
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
        this.detectScreenSize();
    }

    @HostListener('window:resize', [])
    public onResize() {
        this.detectScreenSize();
    }

    private detectScreenSize() {
        this.navServices.collapseSidebar = window.innerWidth < 991;
    }

    private getPlaceAutocomplete() {
        this.mapsApiLoader.load().then(() => {
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
        });
    }

    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term => {
                const isAddress = /^[\d]/.test(term);
                return forkJoin([
                    isAddress ? of<AgentSearchResult[]>([]) : this.agentService.search(term),
                    this.addressService.search(term),
                    this.getAutocompletePredictions(term),
                    this.contactService.search(term, isAddress),
                    this.leadService.search(term, isAddress),
                ]).pipe(map(([r1, r2, r3, r4, r5]) =>
                    [...r2, ...r3, ...r1, ...r4, ...r5]));
            })
        );
    }

    formatPlace = (place) => place.description;

    private getAutocompletePredictions(query: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                if (query === '') {
                    resolve([]);
                } else {
                    this.autocompleteService.getPlacePredictions({
                        types: ['address'],
                        input: query,
                        componentRestrictions: { country: 'US' }
                    }, (predictions, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            console.log(predictions);
                            resolve(predictions);
                        } else {
                            resolve([]);
                        }
                    });
                }
            } catch (e) {
                // console.log(e);
                resolve([]);
            }
        });
    }

    private getPlaceDetails(placeId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.placesService.getDetails({ placeId },
                (placeResult, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        console.log(placeResult);
                        resolve(placeResult);
                    } else {
                        reject(status);
                    }
                });
        });
    }

    invokeEvent(place: google.maps.places.PlaceResult, unit: string, price: number, address: AddressSearchResult) {
        this.autocompleteInput = '';
        this.searchText.nativeElement.blur();
        this.searchService.searchComplete({ place, unit, price, address });
    }

    logout() {
        this.authService.logout();
        this.ngZone.run(() => {
            this.router.navigate(['login']);
        });
    }

    collapseSidebar() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    }

    openMobileNav() {
        this.openNav = !this.openNav;
    }

    onTypeaheadSelected(event: NgbTypeaheadSelectItemEvent) {
        if (this.isAgentSearchResult(event.item)) {
            this.isOpenMobile = false;
            this.autocompleteInput = '';
            this.searchText.nativeElement.blur();
            this.ngZone.run(() => {
                this.router.navigate(['/agent', event.item._id]);
            });
        } else if (this.isAddressSearchResult(event.item)) {
            this.isOpenMobile = false;
            const price = event.item.price;
            this.autocompleteInput = '';
            this.searchText.nativeElement.blur();
            this.invokeEvent(null, null, price, event.item as AddressSearchResult);
        } else if (this.isContactSearchResult(event.item)) {
            this.isOpenMobile = false;
            this.autocompleteInput = '';
            this.searchText.nativeElement.blur();
            this.ngZone.run(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate(['/contact', (event.item as Contact).i_contact]);
            });
        } else if (this.isLeadSearchResult(event.item)) {
            this.isOpenMobile = false;
            this.autocompleteInput = '';
            this.searchText.nativeElement.blur();
            const lead = event.item as Lead;
            this.ngZone.run(() => {
                this.router.navigate(['/address', lead.address_key], {
                    queryParams: { price: lead.price},
                });
            });
        } else {
            const placeObj = event.item as google.maps.places.AutocompletePrediction;
            const unit = event.item.unit;
            const price = event.item.price;
            if (placeObj != null) {
                this.getPlaceDetails(event.item.place_id).then(place => {
                    this.invokeEvent(place, unit, price, event.item as AddressSearchResult);
                    this.isOpenMobile = false;
                });
            }
        }
    }

    formatTypeahead(result: AgentSearchResult | google.maps.places.AutocompletePrediction): string {
        if (this.isAgentSearchResult(result)) {
            const agent = result as AgentSearchResult;
            return agent.name;
        }

        if (this.isAddressSearchResult(result)) {
            const address = result as AddressSearchResult;
            const tokens = [address.formatted_address];
            if (address.contact != null) {
                tokens.push(`(${address.contact.first_name} ${address.contact.last_name})`);
            }
            return tokens.join(' ');
        }

        if (this.isContactSearchResult(result)) {
            const contact = result as Contact;
            let address = [
                contact.location?.address_object?.street,
                contact.location?.address_object?.city,
                contact.location?.address_object?.state,
                contact.location?.address_object?.zip_code,
            ].filter(s => s != null).join(', ');
            if (address !== '') {
                address = `(${address})`;
            }
            return `${contact.first_name} ${contact.last_name} ${address}`;
        }

        if (this.isLeadSearchResult(result)) {
            const lead = result as Lead;
            return `${lead.first_name} ${lead.last_name} (${lead.address}, ${lead.city}, ${lead.state}, ${lead.zip_code} ${formatMoney(lead.price)})`;
        }

        const place = result as google.maps.places.AutocompletePrediction;
        if (place != null) {
            return place.description;
        }
        return '';
    }

    removeAddress() {
        this.searchService.clearCurrentPlace();
    }

    moveToAddress() {
        this.searchService.gotoCurrentPlace();
    }

    typeaheadInputClick(event: MouseEvent, el: HTMLInputElement) {
        el.focus();
        event.stopPropagation();
    }

    isAgentSearchResult = (object: AgentSearchResult | AddressSearchResult | google.maps.places.AutocompletePrediction | Contact | Lead)
        : object is AgentSearchResult => {
        return 'name' in object;
    }

    isAddressSearchResult = (object: AgentSearchResult | AddressSearchResult | google.maps.places.AutocompletePrediction | Contact | Lead)
        : object is AddressSearchResult => {
        return ('address_key_normalized' in object || 'address_key' in object) && !('i_crm_lead' in object);
    }

    isContactSearchResult = (object: AgentSearchResult | AddressSearchResult | google.maps.places.AutocompletePrediction | Contact | Lead)
        : object is Contact => {
        return 'i_contact' in object && !('i_crm_lead' in object);
    }

    isLeadSearchResult = (object: AgentSearchResult | AddressSearchResult | google.maps.places.AutocompletePrediction | Contact | Lead)
        : object is Lead => {
        return 'i_crm_lead' in object;
    }

    isGoogleResult = (object: AgentSearchResult | AddressSearchResult | google.maps.places.AutocompletePrediction | Contact | Lead) =>
        !this.isAddressSearchResult(object) && !this.isAgentSearchResult(object) &&
        !this.isContactSearchResult(object) && !this.isLeadSearchResult(object);

    rightSideBarClick() {
        this.rightSidebar = !this.rightSidebar;
        this.rightSidebarEvent.emit(this.rightSidebar);
    }

    openRightSideBar() {
        this.rightSidebar = true;
        this.rightSidebarEvent.emit(this.rightSidebar);
    }
}
