import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { SavedFilter, TableFilterType } from '@app/models/saved-filter.model';
import { BehaviorSubject } from 'rxjs';

const ApiPath = '/saved_filter';

@Injectable({ providedIn: 'root' })
export class SavedFilterService {
    constructor(private http: HttpClient) {
    }

    savedFilterChangedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get savedFilterChanged() {
        return this.savedFilterChangedSubject.asObservable();
    }

    get(id: number) {
        return this.http.get<DataApiResponse<SavedFilter>>(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    list(page: number, count: number, searchText: string, type: TableFilterType) {
        return this.http.get<ListResponse<SavedFilter[]> | ApiResponse>(
            `${environment.apiUrl}${ApiPath}?page=${page}&count=${count}&search_text=${searchText ?? ''}&tz_offset=${new Date().getTimezoneOffset()}&type=${type}`)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return res as ListResponse<SavedFilter[]>;
            }), catchError(handleApiError));
    }

    update(savedFilter: SavedFilter) {
        return this.http.put<DataApiResponse<SavedFilter>>(
            `${environment.apiUrl}${ApiPath}/${savedFilter.i_saved_filter}`, savedFilter)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    this.savedFilterChangedSubject.next(true);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(id: number) {
        return this.http.delete(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    this.savedFilterChangedSubject.next(true);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(savedFilter: SavedFilter) {
        return this.http.post<DataApiResponse<SavedFilter>>(
            `${environment.apiUrl}${ApiPath}`, savedFilter)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    this.savedFilterChangedSubject.next(true);
                    return res.data;
                }),
                catchError(handleApiError));
    }
}
