import {
    Component, EventEmitter, Input, OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as ClassicEditor from 'src/ckeditor/ckeditor';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Task, TaskPriorities, TaskRelatedToOptions, TaskStatuses } from '@app/models/task.model';
import { TaskType } from '@app/models/task-type.model';
import { TaskTypeService } from '@app/shared/services/task-type.service';
import { UserService } from '@app/shared/services/user.service';
import { User } from '@app/models/user.model';
import { AuthenticationService } from '@app/shared/services/auth.service';
import { Contact } from '@app/models/contact.model';
import { Lead } from '@app/models/lead.model';
import { GoogleAddressSearchResult } from '@app/shared/components/search-address/search-address.component';
import { first } from 'rxjs/operators';
import { AddressService } from '@app/shared/services/address.service';
import { AgentSearchResult } from '@app/shared/services/agent.service';
import { EsAgent } from '@app/models/es-agent.model';
import { Chat, ChatUser } from '@app/models/chat.model';
import { ChatService } from '@app/shared/services/chat.service';
import { TaskTemplate } from '@app/models/task-template.model';
import { SweetAlertResult } from 'sweetalert2';
import { TemplateSelection } from '@app/shared/components/template-selector/template-selector.component';
import { CKEditorConfig } from '@app/shared/helpers/ckedit';
import { SelectionService } from '@app/shared/services/selection.service';
import { CandidateSelection } from '@app/models/agent-selection.model';

@Component({
    selector: 'app-task-edit',
    templateUrl: './task-edit.component.html',
})
export class TaskEditComponent implements OnInit {
    @ViewChild('taskEdit') taskEditDialog: SwalComponent;

    task: Task;
    taskTypes: TaskType[] = [];
    users: User[] = [];
    isShown = false;
    public Editor = ClassicEditor;
    config = CKEditorConfig;

    relatedToOptions = TaskRelatedToOptions;
    statuses = TaskStatuses;
    priorities = TaskPriorities;

    currentTemplate: number;
    entity: Contact | EsAgent;
    currentRelatedContact: number;
    currentRelatedAddress: string;
    currentRelatedAgent: string;

    currentChat: Chat;
    requests: CandidateSelection[];

    constructor(
        private taskTypeService: TaskTypeService,
        private userService: UserService,
        private authService: AuthenticationService,
        private addressService: AddressService,
        private toaster: ToastrService,
        private chatService: ChatService,
        private selectionService: SelectionService,
    ) {
    }

    ngOnInit(): void {
        this.taskTypeService.list().subscribe(res => {
            this.taskTypes = res;
        });
        this.userService.listAll().subscribe(res => {
            this.users = res;
        });
    }

    public show(task: Task, templateId?: number, entity?: Contact | EsAgent,
                relatedContact?: number, relatedAddress?: string): Promise<SweetAlertResult<Task>> {
        this.isShown = true;
        this.taskEditDialog.title = task?.i_task ? 'Edit Task' : 'Add Task';
        if (task.i_task_type == null) {
            task.i_task_type = this.taskTypes.find(tt => tt.is_default)?.i_task_type;
        }
        if (task.priority == null) {
            task.priority = 0;
        }
        if (task.due_date == null) {
            task.due_date = moment()
                .add(1, 'days')
                .set('hour', 9)
                .set('minute', 30)
                .set('second', 0)
                .toISOString(true)
                .replace(/\..*$/, '');
        } else {
            task.due_date = moment.utc(task.due_date).toISOString().replace(/\..*$/, '');
        }
        if (task.i_user == null) {
            task.i_user = this.authService.currentUserValue.i_user;
        }
        if (task.description == null) {
            task.description = '';
        }

        this.currentTemplate = templateId;
        this.currentRelatedContact = relatedContact;
        this.currentRelatedAddress = relatedAddress;
        this.entity = entity;

        const req: ChatUser = {};
        if ((entity as EsAgent)?._id != null) {
            this.entity = req.agent = entity as EsAgent;
        } else if ((entity as Contact)?.i_contact != null) {
            this.entity = req.contact = entity as Contact;
        } else {
            this.entity = null;
        }

        this.currentChat = null;
        if (this.entity != null) {
            this.chatService.getChatHistory(req).subscribe(res => {
                this.currentChat = res;
            });
        }

        if (task.i_candidate_selection != null && task.i_contact != null) {
            this.loadContactRequests(task.i_contact);
        }

        this.task = task;

        return this.taskEditDialog.fire();
    }

    canSubmit() {
        return (this.task?.title ?? '').trim() !== '' && this.task?.related_to != null;
    }

    cancel() {
        this.taskEditDialog.dismiss({ isConfirmed: false } as SweetAlertResult);
        this.isShown = false;
    }

    submit() {
        this.task.due_date = moment(this.task.due_date).utc().toISOString();
        this.taskEditDialog.dismiss({ isConfirmed: true, value: this.task } as SweetAlertResult);
        this.isShown = false;
    }

    setContact($event: Contact | Lead) {
        this.task.i_contact = $event.i_contact;
        this.loadContactRequests($event.i_contact);
    }

    private loadContactRequests(contactId: number) {
        this.selectionService.getByContact(contactId ?? this.task.i_contact, 'all').subscribe(
            res => this.requests = res
        );
    }

    setAddress(event: GoogleAddressSearchResult) {
        if (event.place != null) {
            this.addressService.ensurePlace(event.place, event.unit, event.price)
                .pipe(first())
                .subscribe(addressId => {
                        this.task.address_key = addressId;
                    },
                    err => this.toaster.error(err));
        } else if (event.address != null) {
            this.task.address_key = event.address.address_key;
        }
    }

    setAgent(event: AgentSearchResult) {
        this.task.agent_id = event._id;
        this.task.agent = event.name;
    }

    onTemplateComponentSelected($event: TemplateSelection) {
        this.task.title = $event.text;
        const tmpl = $event.template as TaskTemplate;
        this.task.priority = tmpl.priority;
        this.task.related_to = tmpl.related_to;
        this.task.i_task_type = tmpl.i_task_type;
        this.task.status = tmpl.status;
        if (tmpl.i_user !== 0) {
            this.task.i_user = tmpl.i_user;
        }
        if (tmpl.due_date_type === 'trigger_date') {
            let days = tmpl.due_date_days;
            if (tmpl.due_date_days_type === 'business_days') {
                days = days + Math.floor((Math.min(moment().day(), 5) + days) / 6) * 2;
            }
            this.task.due_date = moment()
                .add(days * tmpl.due_date_delta, 'days')
                .set('hour', 9)
                .set('minute', 30)
                .set('second', 0)
                .toISOString(true)
                .replace(/\..*$/, '');

        }
        if (tmpl.related_to === 'Agent') {
            if ($event.related != null) {
                this.task.agent_id = ($event.related as EsAgent)._id;
                this.task.agent = ($event.related as EsAgent).agent_name;
            }
        } else if (tmpl.related_to === 'Address') {
            this.task.address_key = $event.address.address_key;
            this.task.address = $event.address.street;
        } else if (tmpl.related_to === 'Contact') {
            if ($event.related != null) {
                this.task.i_contact = ($event.related as Contact).i_contact;
                this.task.contact = `${($event.related as Contact).first_name} ${($event.related as Contact).first_name}`;
            }
        }
    }
}
