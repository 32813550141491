import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
    @Input() riseOnHover = false;
    @Input() size = 40;
    @Input() name: string;
    @Input() src: string;
    @Input() tooltip: string;
    @Output() avatarClicked = new EventEmitter<MouseEvent>();

    initials: string;

    onClick($event: MouseEvent) {
        this.avatarClicked.emit($event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.name != null) {
            if (this.name != null) {
                const parts = this.name.split(' ');
                const fName = parts[0];
                if (parts.length > 1) {
                    const lName = parts[parts.length - 1];
                    this.initials = `${fName[0]}${lName[0]}`.toLocaleUpperCase();
                } else {
                    this.initials = `${fName[0]}`.toLocaleUpperCase();
                }
            }
        }
    }
}
