<input class="form-control m-l-10 search-address-input"
       type="text"
       autocomplete="off"
       [placeholder]="placeholder"
       [title]="placeholder"
       #searchText
       [(ngModel)]="autocompleteInput"
       [ngModelOptions]="{standalone: true}"
       [resultFormatter]="formatPlace"
       [inputFormatter]="formatPlace"
       [ngbTypeahead]="search"
       [resultTemplate]="rt"
       (selectItem)="onTypeaheadSelected($event)"
>

<ng-template #rt let-r="result" let-t="term">
    <i class="icofont icofont-social-google-map m-r-5" *ngIf="isGoogleResult(r)"></i>
    <app-feather-icons class="m-r-5" [icon]="'user'" *ngIf="isAddressSearchResult(r)"></app-feather-icons>
    <ngb-highlight [result]="formatTypeahead(r)" [term]="t"></ngb-highlight>
    <input type="number" *ngIf="isGoogleResult(r)|| isAddressSearchResult(r)" placeholder="$" class="form-control-sm price-input m-l-10 dd-input" #price
           (click)="typeaheadInputClick($event, price)" [(ngModel)]="r.price">
    <input type="text" *ngIf="isGoogleResult(r)" placeholder="Unit#" class="form-control-sm unit-input m-l-10 dd-input" #unit
           (click)="typeaheadInputClick($event, unit)" [(ngModel)]="r.unit">
</ng-template>
