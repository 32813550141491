import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@app/models/address.model';
import { formatNumber } from '@app/shared/helpers/util';

@Pipe({ name: 'bigNumber' })
export class BigNumberPipe implements PipeTransform {
    transform(value: number): string {
        if (value == null) {
            return undefined;
        }
        return formatNumber(value);
    }
}
