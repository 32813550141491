import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, ListResponse, SortModel } from '@app/shared/helpers/api';
import { TaskType } from '@app/models/task-type.model';

const ApiPath = '/task_type';

@Injectable({ providedIn: 'root' })
export class TaskTypeService {
    constructor(private http: HttpClient) {
    }

    get(id: number) {
        return this.http.get<DataApiResponse<TaskType>>(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    list() {
        return this.http.get<DataApiResponse<TaskType[]> | ApiResponse>(
            `${environment.apiUrl}${ApiPath}`,)
            .pipe(map(res => {
                handleApplicationError(res as ApiResponse);
                return (res as DataApiResponse<TaskType[]>).data;
            }), catchError(handleApiError));
    }

    update(taskType: TaskType) {
        return this.http.put<DataApiResponse<TaskType>>(
            `${environment.apiUrl}${ApiPath}/${taskType.i_task_type}`, taskType)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    delete(id: number) {
        return this.http.delete(
            `${environment.apiUrl}${ApiPath}/${id}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    insert(taskType: TaskType) {
        return this.http.post<DataApiResponse<TaskType>>(
            `${environment.apiUrl}${ApiPath}`, taskType)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError));
    }
}
