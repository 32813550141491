import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter, takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';

import { NavService } from '@app/shared/services/nav.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {

    public breadcrumbs;
    public title: string;
    public pageBreadcrumb: string;
    private destroyed$: Subject<boolean> = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private navService: NavService
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .pipe(map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }))
            .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
            .subscribe(route => {
                const snapshot = this.router.routerState.snapshot;
                const title = route.snapshot.data.title;
                const parent = route.parent.snapshot.data.breadcrumb;
                const child = route.snapshot.data.breadcrumb;
                this.breadcrumbs = {};
                this.title = title;
                this.breadcrumbs = {
                    parentBreadcrumb: parent,
                    childBreadcrumb: child
                }
                this.navService.currentPageBreadcrumb = null;
            });

        this.navService.currentBreadcrumb.pipe(
            takeUntil(this.destroyed$)
        ).subscribe(breadcrumb => {
            this.pageBreadcrumb = breadcrumb;
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
    }
}