<div class="row m-b-5" *ngIf="entity">
    <div [ngClass]="columnClass">
        <select class="form-control"
                [(ngModel)]="currentTemplate"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onTemplateSelected()">
            <option value="null">Please select template</option>
            <option *ngFor="let template of getTemplates()"
                    [disabled]="isTemplateDisabled(template)"
                    [value]="getTemplateId(template)">{{ template.name }} {{ isTemplateDisabled(template) ? ' - n/a' : '' }}</option>
        </select>
    </div>
    <div [ngClass]="columnClass" *ngIf="isRelatedAgentsShown">
        <select class="form-control"
                [(ngModel)]="currentRelatedAgent"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onTemplateSelected()">
            <option *ngFor="let agent of relatedAgents"
                    [value]="agent._id">{{ agent.agent_name }}</option>
        </select>
    </div>
    <div [ngClass]="columnClass" *ngIf="isRelatedContactsShown">
        <select class="form-control"
                [(ngModel)]="currentRelatedContact"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onTemplateSelected()">
            <option *ngFor="let contact of relatedContacts"
                    [value]="contact.i_contact">{{ contact.first_name }} {{ contact.last_name }}</option>
        </select>
    </div>
    <div [ngClass]="columnClass"
         *ngIf="isRelatedAddressShown">
        <select class="form-control"
                [(ngModel)]="currentRelatedAddress"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onTemplateSelected()">
            <option *ngFor="let address of currentRelatedAddresses"
                    [value]="address.address_key">{{ address | address }}</option>
        </select>
    </div>
    <div [ngClass]="columnClass"
         *ngIf="isRelatedRequestShown">
        <select class="form-control"
                [(ngModel)]="currentRelatedRequest"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onTemplateSelected()">
            <option *ngFor="let address of currentRelatedRequests"
                    [value]="address.i_candidate_selection">#{{ address.i_candidate_selection }}</option>
        </select>
    </div>
</div>