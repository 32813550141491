import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EsAgent } from '@app/models/es-agent.model';
import { UtilService } from '@app/shared/services/util.service';
import { AgentService } from '@app/shared/services/agent.service';

@Component({
    selector: 'app-timeline-agent',
    templateUrl: './timeline-agent.component.html',
    styleUrls: ['./timeline-agent.component.scss']
})
export class TimelineAgentComponent implements OnInit {
    @Input() agent: EsAgent;
    @Input() type: string;
    @Input() fallbackAgentName: string;
    @Input() fallbackOfficeName: string;
    @Input() fallbackAgentId: string;

    agentName: string;
    officeName: string;
    avatar: string;
    tooltip: string;

    constructor(
        private ngZone: NgZone,
        private router: Router,
        public utilService: UtilService,
        private agentService: AgentService,
    ) {
    }

    onAgentClicked(agent: EsAgent) {
        this.ngZone.run(() => {
            let url;
            if (agent == null) {
                url = this.router.createUrlTree(['/agent', this.fallbackAgentId]);
            } else {
                if (agent._id !== null) {
                    url = this.router.createUrlTree(['/agent', agent._id]);
                } else {
                    url = this.router.createUrlTree(['/agent', agent.agent_mlsid, agent.sheet]);
                }
            }
            window.open(url.toString(), '_blank');
        });
    }

    ngOnInit(): void {
        if (this.agent == null) {
            this.agentService.getByEsIdBrief(this.fallbackAgentId).subscribe(agent => {
                this.agent = agent;
                this.setAgent();
            });
        } else {
            this.setAgent();
        }
    }

    private setAgent() {
        this.agentName = this.agent?.agent_name ?? this.fallbackAgentName;
        this.officeName = this.agent?.scanner?.brokerage ?? this.fallbackOfficeName;
        this.avatar = this.utilService.getAvatar(this.agent);
        this.tooltip = `${this.agentName} (${this.type})`;
    }

    getLink(): string {
        let url;
        if (this.agent == null) {
            url = `/agent/${this.fallbackAgentId}`;
        } else {
            if (this.agent._id != null) {
                url = `/agent/${this.agent._id}`;
            } else {
                url = `/agent/${this.agent.agent_mlsid}/${this.agent.sheet}`;
            }
        }
        return url;
    }
}
