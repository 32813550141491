import { Contact } from '@app/models/contact.model';
import { EsAgent } from '@app/models/es-agent.model';
import { Address } from '@app/models/address.model';
import { currencyFormatter, formatDateTime, formatNumber, formatPhoneNumberExt, NA } from '@app/shared/helpers/util';
import { TrackedNumber } from '@app/models/twilio/tracked-number.model';
import { RequestAgent } from '@app/models/request-agent.model';
import { Config } from '@app/models/config.model';
import { CandidateSelection } from '@app/models/agent-selection.model';
import { User } from '@app/models/user.model';

export function getFirstToken(name: string) {
    if (name == null) {
        return name;
    }
    const [firstToken] = name.split(' ');
    return firstToken;
}

export function convertScoreToGenericRound(score: number) {
    const d = Math.pow(10, 1);

    const s = Math.round((score + Number.EPSILON) * d) / d;

    return s * 10;
}

export function applyTemplate(body: string, entity: Contact | EsAgent,
                              secondaryEntity?: Contact | EsAgent,
                              address?: Address,
                              trackedNumber?: TrackedNumber,
                              requestAgent?: RequestAgent, config?: Config, candidateSelection?: CandidateSelection, profile?: User) {
    const nvl = (v: string) => v ?? 'N/A';

    const url = `${window.location.protocol + '//' + window.location.host}`;

    if (candidateSelection?.meta != null) {
        for (let i = 0; i < candidateSelection?.meta.joined_score.pickedAgentsInfo.length; i++) {
            const index = i + 1;
            const agent = candidateSelection?.meta.joined_score.pickedAgentsInfo[i];

            if (agent != null) {
                body = body.replace(new RegExp(`\\\${Request.Agent First Name${index}\}`, 'g'), nvl(agent.es.first_name));
                body = body.replace(new RegExp(`\\\${Request.Agent Last Name${index}\}`, 'g'), nvl(agent.es.last_name));
                body = body.replace(
                    new RegExp(`\\\${Request.Agent Broker Name${index}\}`, 'g'), nvl(agent.es.scanner.brokerage)
                );
                body = body.replace(new RegExp(`\\\${Request.Agent Preferred Name${index}\}`, 'g'),
                    nvl(agent.es.preferred_name ?? getFirstToken(agent.es.first_name)));
                body = body.replace(new RegExp(`\\\${Request.Agent Preferred Phone${index}\}`, 'g'),
                    nvl(formatPhoneNumberExt(agent.es.primary_phone)));
                body = body.replace(new RegExp(`\\\${Contact.Agent Preferred Email${index}\}`, 'g'), nvl(agent.es.primary_email));
                body = body.replace(new RegExp(`\\\${Request.Agent Email${index}\}`, 'g'), nvl((agent.es.emails ?? [])[0]?.value));
                body = body.replace(new RegExp(`\\\${Request.Agent Profile Link${index}\}`, 'g'),
                    nvl(`${config?.website_url}/agents/${agent?.scanner?.url_pointer?.id}/${agent?.scanner?.url_pointer?.name?.replace(/ /g, '-')}`));
                body = body.replace(new RegExp(`\\\${Request.Agent Score${index}\}`, 'g'), nvl(`${convertScoreToGenericRound(agent.joined_score)}`));
                body = body.replace(new RegExp(`\\\${Request.Agent Avatar${index}\}`, 'g'), nvl((agent.es.scanner.avatar ?? [])[0] ?? `${url}/assets/images/dummy-agent.png`));
            }
        }

        body = body.replace(/\${Request.Candidates Number}/g, candidateSelection?.meta.joined_score.candidatesNumber?.toString() ?? 'N/A');
        if ((secondaryEntity as EsAgent)._id != null) {
            const agent = candidateSelection?.meta.joined_score.pickedAgentsInfo
                .find(meta => meta.id === (secondaryEntity as EsAgent)._id);
            if (agent != null) {
                body = body.replace(new RegExp(`\\\${Request.Agent First Name\}`, 'g'), nvl(agent.es.first_name));
                body = body.replace(new RegExp(`\\\${Request.Agent Last Name\}`, 'g'), nvl(agent.es.last_name));
                body = body.replace(
                    new RegExp(`\\\${Request.Agent Broker Name\}`, 'g'), nvl(agent.es.scanner.brokerage)
                );
                body = body.replace(new RegExp(`\\\${Request.Agent Preferred Name\}`, 'g'),
                    nvl(agent.es.preferred_name ?? getFirstToken(agent.es.first_name)));
                body = body.replace(new RegExp(`\\\${Request.Agent Preferred Phone\}`, 'g'),
                    nvl(formatPhoneNumberExt(agent.es.primary_phone)));
                body = body.replace(new RegExp(`\\\${Contact.Agent Preferred Email\}`, 'g'), nvl(agent.es.primary_email));
                body = body.replace(new RegExp(`\\\${Request.Agent Email\}`, 'g'), nvl((agent.es.emails ?? [])[0]?.value));
                body = body.replace(new RegExp(`\\\${Request.Agent Profile Link\}`, 'g'),
                    nvl(`${config?.website_url}/agents/${agent?.scanner?.url_pointer?.id}/${agent?.scanner?.url_pointer?.name?.replace(/ /g, '-')}`));
                body = body.replace(new RegExp(`\\\${Request.Agent Score\}`, 'g'),
                    nvl(`${convertScoreToGenericRound(agent.joined_score)}`));
                body = body.replace(new RegExp(`\\\${Request.Agent Avatar\}`, 'g'), nvl((agent.es.scanner.avatar ?? [])[0] ?? `${url}/assets/images/dummy-agent.png`));
            }
        }
    }

    if (candidateSelection != null) {
        body = body.replace(/\${Request.Price Range}/g, candidateSelection?.price_range);
        // if (candidateSelection.type === 'Buyer') {
        body = body.replace(/\${Request.Buyer Region}/g, [
            address?.street,
            address?.city,
            address?.state,
            address?.zip_code
        ].filter(Boolean).join(', '));
        // }
    }

    if (profile != null) {
        console.log(profile);
        body = body.replace(/\${Request.User First Name}/g, nvl(getFirstToken(profile?.first_name)));
        body = body.replace(/\${Request.User Last Name}/g, nvl(getFirstToken(profile?.last_name)));
        body = body.replace(/\${Request.User Phone}/g, nvl(formatPhoneNumberExt(profile?.mobile_phone)));
        body = body.replace(/\${Request.User Email}/g, nvl(profile?.email));
    }

    entity = entity ?? {};
    if ((entity as Contact).i_contact != null) {
        const contact = entity as Contact;
        body = body.replace(/\${Request.Contact Preferred Name}/g, nvl(getFirstToken(contact.first_name)));
        body = body.replace(/\${Request.Contact Preferred Phone}/g, nvl(formatPhoneNumberExt(contact.primary_phone)));
        body = body.replace(/\${Request.Contact Preferred Email}/g, nvl(contact.primary_email));
        body = body.replace(/\${Request.Address Mailing Street}/g, nvl(address?.street));
        body = body.replace(/\${Request.Address Mailing City}/g, nvl(address?.city));
        body = body.replace(/\${Request.Address Mailing Zip}/g, nvl(address?.zip_code));
        body = body.replace(/\${Request.Address Price}/g, currencyFormatter({ value: address?.price }));
        body = body.replace(/\${Request.Contact Owner First Name}/g, nvl(contact.owner_first_name));
        body = body.replace(/\${Request.Contact Owner Last Name}/g, nvl(contact.owner_last_name));
        body = body.replace(/\${Request.Contact First Name}/g, nvl(contact.first_name));
        body = body.replace(/\${Request.Contact Last Name}/g, nvl(contact.last_name));
        body = body.replace(/\${Request.Agent Tracked Number}/g, nvl(formatPhoneNumberExt(trackedNumber?.twilio_phone, false)));

        const lastUpdated = requestAgent?.datetime_status_change ? formatDateTime(requestAgent?.datetime_status_change) : 'n/a';
        body = body.replace(/\${Request.Agent Status Last Updated}/g, lastUpdated);
        body = body.replace(/\${Request.Property Type}/g,
            nvl(requestAgent?.candidate_selection?.property_type ?? candidateSelection?.property_type));
        body = body.replace(/\${Request.Unsubscribe Link}/g, `${config.contact_unsubscribe_url}/${contact.i_contact}`);
        body = body.replace(/\${Request.Home Value}/g, nvl(contact.location?.price_range));
        body = body.replace(/\${Request.Agent Results Link}/g, nvl(contact.location?.request_url));

        body = body.replace(/\${Contact.First Name}/g, nvl(contact.first_name));
        body = body.replace(/\${Contact.Last Name}/g, nvl(contact.last_name));
        body = body.replace(/\${Contact.Preferred Name}/g, nvl(getFirstToken(contact.first_name)));
        body = body.replace(/\${Contact.Preferred Phone}/g, nvl(formatPhoneNumberExt(contact.primary_phone)));
        body = body.replace(/\${Contact.Preferred Email}/g, nvl(contact.primary_email));
        body = body.replace(/\${Contact.Mailing Street}/g, nvl(address?.street));
        body = body.replace(/\${Contact.Mailing City}/g, nvl(address?.city));
        body = body.replace(/\${Contact.Zip Code}/g, nvl(address?.zip_code));
        body = body.replace(/\${Contact.Price}/g, currencyFormatter({ value: address?.price }));
        // body = body.replace(/\${Contact.Mailing Street}/g, nvl(address?.address));
        // body = body.replace(/\${Contact.Mailing City}/g, nvl(address?.city));
        // body = body.replace(/\${Contact.Zip Code}/g, nvl(address?.zip_code));
        // body = body.replace(/\${Contact.Price}/g, currencyFormatter({ value: address?.price }));
        body = body.replace(/\${Contact.Owner First Name}/g, nvl(contact.owner_first_name));
        body = body.replace(/\${Contact.Owner Last Name}/g, nvl(contact.owner_last_name));
        body = body.replace(/\${Contact.Owner Email}/g, nvl(contact.owner_email));
        body = body.replace(/\${Contact.Agent Tracked Number}/g, nvl(formatPhoneNumberExt(trackedNumber?.twilio_phone, false)));

        body = body.replace(/\${Contact.Unsubscribe Link}/g, `${config.contact_unsubscribe_url}/${contact.i_contact}`);

        if (secondaryEntity != null) {
            const agent = secondaryEntity as EsAgent;
            const [firstName, ...lastName] = agent.agent_name.split(' ');
            body = body.replace(/\${Request.Agent First Name}/g, nvl(firstName));
            body = body.replace(/\${Request.Agent Last Name}/g, nvl(lastName.join(' ')));
            body = body.replace(/\${Request.Agent Broker Name}/g, nvl(agent.scanner.brokerage));
            body = body.replace(/\${Request.Agent Preferred Name}/g, nvl(agent.preferred_name ?? getFirstToken(firstName)));
            body = body.replace(/\${Request.Agent Preferred Phone}/g, nvl(formatPhoneNumberExt(agent.primary_phone)));
            body = body.replace(/\${Contact.Agent Preferred Email}/g, nvl(agent.primary_email));
            body = body.replace(/\${Request.Agent Email}/g, nvl((agent.emails ?? [])[0]?.value));
            body = body.replace(/\${Request.Agent Profile Link}/g, nvl(`${config?.website_url}/agents/${agent?.scanner?.url_pointer?.id}/${agent?.scanner?.url_pointer?.name?.replace(/ /g, '-')}`));
            body = body.replace(/\${Request.Property Type}/g,
                nvl(requestAgent?.candidate_selection?.property_type) ?? candidateSelection?.property_type);

            body = body.replace(/\${Contact.Agent First Name}/g, nvl(firstName));
            body = body.replace(/\${Contact.Agent Last Name}/g, nvl(lastName.join(' ')));
            body = body.replace(/\${Contact.Agent Broker Name}/g, nvl(agent.scanner.brokerage));
            // body = body.replace(/\${Contact.Agent Broker City}/g, nvl(agent.scanner.agent_city));
            // body = body.replace(/\${Contact.Agent Broker Zip}/g, nvl((agent.scanner.agent_zip ?? []).join(',')));
            body = body.replace(/\${Contact.Agent Preferred Name}/g, nvl(agent.preferred_name ?? getFirstToken(firstName)));

            // backward compatibility
            body = body.replace(/\${Contact.Agent Preferred Phone}/g, nvl(formatPhoneNumberExt(agent.primary_phone)));
            body = body.replace(/\${Contact.Agent Preferred Email}/g, nvl(agent.primary_email));

            body = body.replace(/\${Contact.Agent Primary Phone}/g, nvl(formatPhoneNumberExt(agent.primary_phone)));
            body = body.replace(/\${Contact.Agent Primary Email}/g, nvl(agent.primary_email));

            body = body.replace(/\${Contact.Agent Email}/g, nvl((agent.emails ?? [])[0]?.value));
            body = body.replace(/\${Contact.Agent Profile Link}/g, nvl(`${config?.website_url}/agents/${agent?.scanner?.url_pointer?.id}/${agent?.scanner?.url_pointer?.name?.replace(/ /g, '-')}`));
        }
    } else if ((entity as EsAgent)._id != null) {
        const agent = entity as EsAgent;
        body = body.replace(/\${Request.Agent Email}/g, nvl((agent.emails ?? [])[0]?.value));
        body = body.replace(/\${Request.Agent First Name}/g, nvl(agent.first_name));
        body = body.replace(/\${Request.Agent Last Name}/g, nvl(agent.last_name));
        body = body.replace(/\${Request.Agent Broker Name}/g, nvl(agent.scanner.brokerage));
        body = body.replace(/\${Request.Agent Preferred Phone}/g, nvl(formatPhoneNumberExt(agent.primary_phone)));
        body = body.replace(/\${Request.Agent Preferred Name}/g, nvl(agent.preferred_name ?? getFirstToken(agent.first_name)));
        body = body.replace(/\${Request.Property Type}/g,
            nvl(requestAgent?.candidate_selection?.property_type) ?? candidateSelection?.property_type);

        body = body.replace(/\${Agent.First Name}/g, nvl(agent.first_name));
        body = body.replace(/\${Agent.Last Name}/g, nvl(agent.last_name));
        body = body.replace(/\${Agent.Broker Name}/g, nvl(agent.scanner.brokerage));
        // body = body.replace(/\${Agent.Broker City}/g, nvl(agent.scanner.agent_city));
        // body = body.replace(/\${Agent.Broker Zip}/g, nvl((agent.scanner.agent_zip ?? []).join(',')));
        body = body.replace(/\${Agent.Preferred Name}/g, nvl(agent.preferred_name ?? getFirstToken(agent.first_name)));

        // backward compatibility
        body = body.replace(/\${Agent.Preferred Phone}/g, nvl(formatPhoneNumberExt(agent.primary_phone)));
        body = body.replace(/\${Agent.Preferred Email}/g, nvl(agent.primary_email));

        body = body.replace(/\${Agent.Primary Phone}/g, nvl(formatPhoneNumberExt(agent.primary_phone)));
        body = body.replace(/\${Agent.Primary Email}/g, nvl(agent.primary_email));

        body = body.replace(/\${Agent.Profile Link}/g, nvl(`${config?.website_url}/agents/${agent?.scanner?.url_pointer?.id}/${agent?.scanner?.url_pointer?.name?.replace(/ /g, '-')}`));
        body = body.replace(/\${Request.Agent Profile Link}/g, nvl(`${config?.website_url}/agents/${agent?.scanner?.url_pointer?.id}/${agent?.scanner?.url_pointer?.name?.replace(/ /g, '-')}`));

        if (typeof (config) !== 'undefined') {
            body = body.replace(/\${Agent.Unsubscribe Link}/g, `${config.agent_unsubscribe_url}/${agent?.scanner?.url_pointer?.id}`);
            body = body.replace(/\${Request.Unsubscribe Link}/g, `${config.agent_unsubscribe_url}/${agent?.scanner?.url_pointer?.id}`);
        }

        if (secondaryEntity != null) {
            const contact = secondaryEntity as Contact;
            body = body.replace(/\${Request.Contact Owner First Name}/g, nvl(contact.owner_first_name));
            body = body.replace(/\${Request.Contact Owner Last Name}/g, nvl(contact.owner_last_name));
            body = body.replace(/\${Request.Contact First Name}/g, nvl(contact.first_name));
            body = body.replace(/\${Request.Contact Last Name}/g, nvl(contact.last_name));
            body = body.replace(/\${Request.Contact Preferred Name}/g, nvl(getFirstToken(contact.first_name)));
            body = body.replace(/\${Request.Contact Preferred Phone}/g, nvl(formatPhoneNumberExt(contact.primary_phone)));
            body = body.replace(/\${Request.Contact Preferred Email}/g, nvl(contact.primary_email));
            body = body.replace(/\${Request.Address Mailing Street}/g, nvl(address?.street));
            body = body.replace(/\${Request.Address Mailing City}/g, nvl(address?.city));
            body = body.replace(/\${Request.Address Mailing Zip}/g, nvl(address?.zip_code));
            body = body.replace(/\${Request.Address Price}/g, currencyFormatter({ value: address?.price }));
            body = body.replace(/\${Request.Property Type}/g,
                nvl(requestAgent?.candidate_selection?.property_type) ?? candidateSelection?.property_type);
            body = body.replace(/\${Request.Home Value}/g, nvl(contact.location?.price_range));
            body = body.replace(/\${Request.Agent Results Link}/g, nvl(contact.location?.request_url));

            body = body.replace(/\${Agent.Contact First Name}/g, nvl(contact.first_name));
            body = body.replace(/\${Agent.Contact Last Name}/g, nvl(contact.last_name));
            body = body.replace(/\${Agent.Contact Email}/g, nvl(contact.primary_email));
            body = body.replace(/\${Agent.Contact Preferred Name}/g, nvl(getFirstToken(contact.first_name)));
            body = body.replace(/\${Agent.Contact Preferred Phone}/g, nvl(formatPhoneNumberExt(contact.primary_phone)));
            // body = body.replace(/\${Agent.Contact Preferred Email}/g, nvl(contact.email));
            body = body.replace(/\${Agent.Contact Mailing Street}/g, nvl(address?.street));
            body = body.replace(/\${Agent.Contact Mailing City}/g, nvl(address?.city));
            body = body.replace(/\${Agent.Contact Zip Code}/g, nvl(address?.zip_code));
            body = body.replace(/\${Agent.Contact Price}/g, currencyFormatter({ value: address?.price }));
            body = body.replace(/\${Agent.Contact Owner First Name}/g, nvl(contact.owner_first_name));
            body = body.replace(/\${Agent.Contact Owner Last Name}/g, nvl(contact.owner_last_name));
            body = body.replace(/\${Agent.Contact Owner Email}/g, nvl(contact.owner_email));
        }
    }

    return body;
}
