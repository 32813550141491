import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MessageTemplate } from '@app/models/message-template.model';
import { EsAgent } from '@app/models/es-agent.model';
import { Contact } from '@app/models/contact.model';
import { Address } from '@app/models/address.model';
import { MessageTemplateService } from '@app/shared/services/message-template.service';
import { applyTemplate } from '@app/shared/helpers/template-util';
import { TaskTemplateService } from '@app/shared/services/task-template.service';
import { TaskTemplate } from '@app/models/task-template.model';
import { EmailTemplateService } from '@app/shared/services/email-template.service';
import { of } from 'rxjs';
import { EmailFromOption, EmailTemplate } from '@app/models/email-template.model';
import { TrackedNumber } from '@app/models/twilio/tracked-number.model';
import { RequestAgent } from '@app/models/request-agent.model';
import { SettingsService } from '@app/shared/services/settings.service';
import { Config } from '@app/models/config.model';
import { CandidateSelection } from '@app/models/agent-selection.model';
import { ProfileService } from '@app/shared/services/profile.service';
import { User } from '@app/models/user.model';

type TemplateType = MessageTemplate | TaskTemplate | EmailTemplate;

export interface TemplateSelection {
    text: string;
    to?: string;
    from?: EmailFromOption;
    subject?: string;
    template: TemplateType;
    related?: Contact | EsAgent;
    address?: Address;
}

@Component({
    selector: 'app-template-selector',
    templateUrl: './template-selector.component.html',
})
export class TemplateSelectorComponent implements OnInit, OnChanges {
    @Input() type: 'message' | 'task' | 'email' = 'message';
    @Input() relatedAgents: EsAgent[];
    @Input() relatedContacts: Contact[];
    @Input() relatedAddresses: Address[];
    @Input() entity: EsAgent | Contact;
    @Input() contact: Contact;
    @Input() requestAgent: RequestAgent;
    @Output() templateChanged: EventEmitter<TemplateSelection>
        = new EventEmitter<TemplateSelection>();

    @Input() initialTemplate: number = null;
    @Input() initialRelatedAgent: string = null;
    @Input() initialRelatedContact: number = null;
    @Input() initialRelatedAddress: string = null;

    currentTemplate: number = null;
    currentRelatedAgent: string = null;
    currentRelatedContact: number = null;
    currentRelatedAddress: string = null;
    currentRelatedRequest: number = null;
    public templates: TemplateType[];
    private config: Config;
    private profile: User;

    constructor(
        private messageTemplateService: MessageTemplateService,
        private taskTemplateService: TaskTemplateService,
        private emailTemplateService: EmailTemplateService,
        private settingsService: SettingsService,
        private profileService: ProfileService,
    ) {
    }

    ngOnInit(): void {
        this.settingsService.get().subscribe(c => this.config = c);
        this.profileService.getProfile().subscribe(res => this.profile = res);
        (this.type === 'message'
                ? this.messageTemplateService.list(0, 1000, '', { colId: 'name', sort: 'asc' })
                : this.type === 'task'
                    ? this.taskTemplateService.list(0, 1000, '', { colId: 'name', sort: 'asc' })
                    : this.type === 'email'
                        ? this.emailTemplateService.list(0, 1000, '', { colId: 'name', sort: 'asc' })
                        : of({ data: [] })
        ).subscribe(res => {
            this.templates = res.data;
            this.onTemplateSelected();
        });

        this.currentTemplate = this.initialTemplate;
        this.currentRelatedAgent = this.initialRelatedAgent;
        this.currentRelatedContact = this.initialRelatedContact;
        this.currentRelatedAddress = this.initialRelatedAddress;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.currentTemplate = this.initialTemplate;
        this.currentRelatedAgent = this.initialRelatedAgent;
        this.currentRelatedContact = this.initialRelatedContact;
        this.currentRelatedAddress = this.initialRelatedAddress;
        if (changes.relatedAgents || changes.relatedContacts) {
            this.onTemplateSelected();
        }
    }

    getTemplates(): TemplateType[] {
        if (this.entity == null || this.templates == null) {
            return [];
        }
        return this.templates.filter(t => (((this.entity as Contact).i_contact != null
            ? ['Contacts', 'Requests'] : ['Agents', 'Requests']).includes(t.type)));
    }

    onTemplateSelected() {
        if (this.currentTemplate != null) {
            const template = this.currentTemplateObject;
            if (template != null) {
                let secondaryEntity: EsAgent | Contact | undefined;
                let address: Address | undefined;
                let request: CandidateSelection | undefined;
                let trackedNumber: TrackedNumber | undefined;
                if ((this.entity as Contact).i_contact != null) {
                    secondaryEntity = this.relatedAgents?.find(t => t._id === this.currentRelatedAgent);
                    if (secondaryEntity == null && this.relatedAgents?.length > 0) {
                        secondaryEntity = this.relatedAgents[0];
                        this.currentRelatedAgent = (secondaryEntity as EsAgent)._id;
                    }
                    trackedNumber = (secondaryEntity as EsAgent)?.tracked_number;
                } else if ((this.entity as EsAgent)._id != null) {
                    secondaryEntity = this.currentRelatedContactObj;
                    if (secondaryEntity == null && this.relatedContacts?.length > 0) {
                        secondaryEntity = this.relatedContacts[0];
                        if (this.contact == null) {
                            this.currentRelatedContact = (secondaryEntity as Contact).i_contact;
                        }
                    }
                    address = (secondaryEntity as Contact)?.addresses?.find(addr => addr.address_key === this.currentRelatedAddress);
                    if (address == null && (secondaryEntity as Contact)?.addresses?.length > 0) {
                        address = (secondaryEntity as Contact).addresses[0];
                        this.currentRelatedAddress = address.address_key;
                    }
                }
                if (this.isCurrentTemplateAddressRelated) {
                    address = (this.relatedAddresses ?? this.currentRelatedContactObj?.addresses)
                        ?.find(addr => addr.address_key === this.currentRelatedAddress);
                    if (address == null && this.relatedAddresses?.length > 0) {
                        address = this.relatedAddresses[0];
                        this.currentRelatedAddress = address.address_key;
                    }
                }
                if (this.isCurrentTemplateRequestRelated) {
                    request = this.currentRelatedRequests.find(addr => addr.i_candidate_selection === Number(this.currentRelatedRequest));
                    if (request == null && this.currentRelatedRequests.length > 0) {
                        request = this.currentRelatedRequests[0];
                        this.currentRelatedRequest = request.i_candidate_selection;
                    }
                }
                let to = null;
                let from = null;
                let subject = null;
                if (this.type === 'email') {
                    to = applyTemplate(
                        (template as EmailTemplate).to_email,
                        this.entity,
                        secondaryEntity,
                        address,
                        trackedNumber,
                        null,
                        this.config,
                        request,
                        this.profile
                    );
                    from = (template as EmailTemplate).from_email;
                    subject = applyTemplate((template as EmailTemplate).subject, this.entity, secondaryEntity, address,
                        trackedNumber, this.requestAgent, this.config,
                        request, this.profile);
                }
                this.templateChanged.emit({
                    template,
                    subject,
                    to,
                    from,
                    address,
                    related: secondaryEntity,
                    text: applyTemplate((template as MessageTemplate).body ??
                        (template as TaskTemplate).title, this.entity, secondaryEntity,
                        address, trackedNumber, this.requestAgent, this.config,
                        request, this.profile),
                });
            }
        }
    }

    get currentTemplateObject(): TemplateType {
        if (this.type === 'message') {
            if (this.currentTemplate != null) {
                return this.templates?.find(
                    t => (t as MessageTemplate).i_message_template === Number(this.currentTemplate)
                );
            }
            return null;
        } else if (this.type === 'email') {
            if (this.currentTemplate != null) {
                return this.templates?.find(
                    t => (t as EmailTemplate).i_email_template === Number(this.currentTemplate)
                );
            }
            return null;
        } else {
            if (this.currentTemplate != null) {
                return this.templates?.find(
                    t => (t as TaskTemplate).i_task_template === Number(this.currentTemplate)
                );
            }
            return null;

        }
    }

    get isCurrentTemplateAgentRelated(): boolean {
        return this.isTemplateAgentRelated(this.currentTemplateObject);
    }

    isTemplateAgentRelated(template: TemplateType): boolean {
        return template != null &&
            (
                (template.type === 'Contacts' || template.type === 'Requests') && (
                    (template as MessageTemplate).body?.includes('${Contact.Agent') ||
                    (template as TaskTemplate).title?.includes('${Contact.Agent') ||
                    (template as MessageTemplate).body?.includes('${Request.Agent') ||
                    (template as TaskTemplate).title?.includes('${Request.Agent')
                )
            );
    }

    get isCurrentTemplateContactRelated(): boolean {
        return this.isTemplateContactRelated(this.currentTemplateObject);
    }

    get isCurrentTemplateRequestRelated(): boolean {
        return this.isTemplateRequestRelated(this.currentTemplateObject);
    }

    get isCurrentTemplateAddressRelated(): boolean {
        return this.isTemplateAddressRelated(this.currentTemplateObject);
    }

    isTemplateContactRelated(template: TemplateType): boolean {
        return template != null &&
            (
                (template.type === 'Agents' || template.type === 'Requests') && (
                    (template as MessageTemplate).body?.includes('${Agent.Contact') ||
                    (template as TaskTemplate).title?.includes('${Agent.Contact') ||
                    (template as MessageTemplate).body?.includes('${Request.Contact') ||
                    (template as TaskTemplate).title?.includes('${Request.Contact')
                )
            );
    }

    isTemplateAddressRelated(template: TemplateType): boolean {
        return template != null &&
            (
                (template.type === 'Contacts' || template.type === 'Requests') && (
                    (template as MessageTemplate).body?.includes('${Contact.Mailing') ||
                    (template as MessageTemplate).body?.includes('${Request.Address') ||
                    (template as MessageTemplate).body?.includes('${Contact.Zip') ||

                    (template as EmailTemplate).body?.includes('${Contact.Mailing') ||
                    (template as EmailTemplate).body?.includes('${Request.Address') ||
                    (template as EmailTemplate).body?.includes('${Contact.Zip') ||
                    (template as EmailTemplate).subject?.includes('${Contact.Mailing') ||
                    (template as EmailTemplate).subject?.includes('${Request.Address') ||
                    (template as EmailTemplate).subject?.includes('${Contact.Zip') ||

                    (template as TaskTemplate).title?.includes('${Contact.Mailing') ||
                    (template as TaskTemplate).title?.includes('${Contact.Zip') ||
                    (template as TaskTemplate).title?.includes('${Request.Address') ||

                    (template as MessageTemplate).body?.includes('${Request.Agent') ||

                    (template as EmailTemplate).body?.includes('${Request.Agent') ||
                    (template as EmailTemplate).subject?.includes('${Request.Agent') ||

                    (template as TaskTemplate).title?.includes('${Request.Agent')

                )
            );
    }

    isTemplateRequestRelated(template: TemplateType): boolean {
        return template != null &&
            (
                (template.type === 'Requests') && (
                    (template as MessageTemplate).body?.includes('${Request.Agent') ||

                    (template as EmailTemplate).body?.includes('${Request.Agent') ||
                    (template as EmailTemplate).subject?.includes('${Request.Agent') ||

                    (template as TaskTemplate).title?.includes('${Request.Agent')
                )
            );
    }

    isTemplateDisabled(template: MessageTemplate): boolean {
        return (this.isTemplateAgentRelated(template) && this.relatedAgents?.length === 0) ||
            (this.isTemplateContactRelated(template) && this.relatedContacts?.length === 0);
    }

    get currentRelatedContactObj(): Contact {
        return this.contact
            ? this.relatedContacts?.find(t => t.i_contact === this.contact.i_contact)
            : this.relatedContacts?.find(t => t.i_contact === Number(this.currentRelatedContact));
    }

    get currentRelatedAddresses(): Address[] {
        return this.isCurrentTemplateAddressRelated
            ? this.relatedAddresses ?? this.currentRelatedContactObj?.addresses
            : this.currentRelatedContactObj?.addresses;
    }

    get isRelatedAgentsShown(): boolean {
        return this.currentTemplate != null && this.isCurrentTemplateAgentRelated;
    }

    get isRelatedContactsShown(): boolean {
        return this.currentTemplate != null && this.isCurrentTemplateContactRelated && !this.contact;
    }

    get isRelatedRequestShown(): boolean {
        return this.currentTemplate != null && this.isCurrentTemplateRequestRelated;
    }

    get isRelatedAddressShown(): boolean {
        return this.currentTemplate != null && (
            (this.isCurrentTemplateContactRelated && this.currentRelatedContactObj != null)
            || this.isCurrentTemplateAddressRelated);
    }

    get columnClass(): string {
        const shownCount = (this.isRelatedAgentsShown ? 1 : 0)
            + (this.isRelatedContactsShown ? 1 : 0)
            + (this.isRelatedAddressShown ? 1 : 0)
            + (this.isRelatedRequestShown ? 1 : 0);
        return `col-sm-${12 / (shownCount === 0 ? 1 : shownCount)}`;
    }

    getTemplateId(template: TemplateType) {
        return this.type === 'message'
            ? (template as MessageTemplate).i_message_template
            : this.type === 'task'
                ? (template as TaskTemplate).i_task_template
                : this.type === 'email'
                    ? (template as EmailTemplate).i_email_template
                    : null;
    }

    get currentRelatedRequests(): CandidateSelection[] {
        return this.currentRelatedAddresses?.find(addr => addr.address_key === this.currentRelatedAddress)?.requests ?? [];
    }

}
