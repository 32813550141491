// tslint:disable:variable-name

import { Call, ChatMessage } from '@app/models/chat.model';
import { CandidateSelection } from '@app/models/agent-selection.model';
import { MlsRecord } from '@app/models/record.model';
import { RecordChange } from '@app/models/record-change.model';
import { EmailMessage } from '@app/models/gmail/email.model';

export enum PillType {
    Message,
    Call,
    Candidate,
    Listing,
    Email,
}

export interface Timeline {
    priceTitle?: string;
    statusTitle?: string;
    recordTitle?: string;
    requestTitle?: string;
    date?: string;
    type?: PillType;

    candidate_selection?: CandidateSelection;
    message?: ChatMessage;
    call?: Call;
    record?: MlsRecord;
    change?: RecordChange;
    email?: EmailMessage;
}
