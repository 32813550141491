import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { ApiResponse, DataApiResponse, handleApiError, handleApplicationError, SortModel } from '@app/shared/helpers/api';
import { Email, EsAgent} from '@app/models/es-agent.model';
import { ChatUser } from '@app/models/chat.model';
import { AgentStatus } from '@app/models/agent-status.model';
import { SavedFilter } from '@app/models/saved-filter.model';
import { GAReport } from '@app/models/ga/report.model';
import { Phone } from '@app/models/phone.model';

export interface AgentSearchResult {
    _id?: string;
    name: string;
    list_agent_mlsid: string;
    list_agent_full_name: string;
    chat_user?: ChatUser;
}

interface SearchResult {
    data: AgentSearchResult[];
}

@Injectable({ providedIn: 'root' })
export class AgentService {
    private agentCache: Map<string, EsAgent> = new Map<string, EsAgent>();

    constructor(private http: HttpClient) {
    }

    private agentChangedSource = new Subject<string>();

    public get agentChanged() {
        return this.agentChangedSource.asObservable();
    }

    public triggerAgentChanged(agentId: string) {
        this.agentChangedSource.next(agentId);
    }

    list(page: number, count: number, searchText: string, sort: SortModel, filter: SavedFilter) {
        const sortStr = sort != null ? `&sorting[${sort.colId}]=${sort.sort}` : '';
        return this.http.get<any>(
            `${environment.apiUrl}/listing_agent?i_saved_filter=${filter?.i_saved_filter ?? ''}&page=${page}&count=${count}&search_text=${searchText}${sortStr}`)
            .pipe(map(data => {
                return data;
            }));
    }

    getEsId(listAgentId: string, sheet: string): Observable<number | undefined> {
        return this.http.get<DataApiResponse<number>>(
            `${environment.apiUrl}/listing_agent_lookup/${listAgentId}/${sheet}`)
            .pipe(
                map(data => {
                    handleApplicationError(data);
                    return data.data;
                }),
                catchError(handleApiError));
    }

    getByEsId(esId: string) {
        return this.http.get<EsAgent>(`${environment.apiUrl}/agent/${esId}`);
    }

    getByEsIdBrief(esId: string) {
        if (this.agentCache.has(esId)) {
            return of(this.agentCache.get(esId));
        } else {
            return this.http.get<EsAgent>(`${environment.apiUrl}/agent/${esId}/brief`, {
                headers: { ignoreLoadingBar: '' },
            }).pipe(
                map(agent => {
                    this.agentCache.set(esId, agent);
                    return agent;
                })
            );
        }
    }

    search(searchText: string, sheet?: string, noEmptyMlsId?: boolean) {
        return this.http.get<SearchResult>(`${environment.apiUrl}/search/agent?search_text=${searchText}&mode=address_report&sheet=${sheet || ''}&noEmptyMlsId=${noEmptyMlsId ? 1 : 0}`)
            .pipe(map(data => data.data));
    }

    updateAgent(agent: EsAgent, agentData: {
        newPhone?: Phone, phones?: Phone[], emails?: Email[], props?: Partial<EsAgent>, agentStory?: {
            profile_phone: Phone
        }
    }) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/listing_agent`, { agent, data: agentData })
            .pipe(
                map(data => {
                    handleApplicationError(data);
                    return data;
                }),
                catchError(handleApiError));
    }

    setPrimaryPhone(agent: EsAgent, phoneNumber: string) {
            return this.http.put<ApiResponse>(
                `${environment.apiUrl}/listing_agent/${agent._id}/primary-phone`, { primary_phone: phoneNumber })
                .pipe(
                    map(res => {
                        handleApplicationError(res);
                        return res;
                    }),
                    catchError(handleApiError));
    }

    setPrimaryEmail(agent: EsAgent, email: string) {
            return this.http.put<ApiResponse>(
                `${environment.apiUrl}/listing_agent/${agent._id}/primary-email`, { primary_email: email })
                .pipe(
                    map(res => {
                        handleApplicationError(res);
                        return res;
                    }),
                    catchError(handleApiError));
    }

    setPrimaryPhoneForced(agent: EsAgent) {
            return this.http.put<ApiResponse>(
                `${environment.apiUrl}/listing_agent/${agent._id}/primary-phone-forced`, { })
                .pipe(
                    map(res => {
                        handleApplicationError(res);
                        return res;
                    }),
                    catchError(handleApiError));
    }

    setPrimaryEmailForced(agent: EsAgent) {
            return this.http.put<ApiResponse>(
                `${environment.apiUrl}/listing_agent/${agent._id}/primary-email-foced`, { })
                .pipe(
                    map(res => {
                        handleApplicationError(res);
                        return res;
                    }),
                    catchError(handleApiError));
    }

    setStatus(agent: EsAgent, status: AgentStatus) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/listing_agent/${agent._id}/status`, { i_es_agent_status: status.i_es_agent_status })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    setAutomation(agent: EsAgent, isAutomated: boolean) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/listing_agent/${agent._id}/automation`, { is_automation: isAutomated })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    setEmailEnabled(agent: EsAgent, isEnabled: boolean) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/listing_agent/${agent._id}/email`, { is_email_enabled: isEnabled })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError)
            );
    }

    getGAReport(urlPointer: string, selectedPeriod: string) {
        return this.http.get<DataApiResponse<GAReport[]>>(
            `${environment.apiUrl}/listing_agent/${urlPointer}/analytics?period=${selectedPeriod}`)
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res.data;
                }),
                catchError(handleApiError)
            );
    }

    activateAgentProfile(agent: EsAgent) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/listing_agent/${agent.scanner.url_pointer.id}/profile`, { profile_status: true })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }

    deactivateAgentProfile(agent: EsAgent) {
        return this.http.put<ApiResponse>(
            `${environment.apiUrl}/listing_agent/${agent.scanner.url_pointer.id}/profile`, { profile_status: false })
            .pipe(
                map(res => {
                    handleApplicationError(res);
                    return res;
                }),
                catchError(handleApiError));
    }
}
