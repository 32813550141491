import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/models/user.model';
import { DataApiResponse, handleApiError } from '@app/shared/helpers/api';

export const LocalStorageUserKey = 'currentUser';
export const LocalStorageTokenKey = 'token';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(LocalStorageUserKey)));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    updateProfile(user: Partial<User>): void {
        const current = this.currentUserValue;
        Object.keys(user).forEach(key => (current[key] = user[key]));
        this.currentUserSubject.next(current);
        localStorage.setItem(LocalStorageUserKey, JSON.stringify(current));
    }

    login(login: string, password: string) {
        return this.http.post<User>(`${environment.apiUrl}/auth2/login`, { login, password })
            .pipe(map(user => {
                    localStorage.setItem(LocalStorageUserKey, JSON.stringify(user));
                    localStorage.setItem(LocalStorageTokenKey, user.token);
                    this.currentUserSubject.next(user);
                    return user;
                }),
                catchError(handleApiError));
    }

    ping() {
        return this.http.get(`${environment.apiUrl}/ping`, {});
    }

    logout() {
        localStorage.removeItem(LocalStorageUserKey);
        localStorage.removeItem(LocalStorageTokenKey);
        this.currentUserSubject.next(null);
    }

    loginGoogle() {
        this.http.get<DataApiResponse<string>>(`${environment.apiUrl}/auth/google`).subscribe(
            res => {
                window.location.href = res.data;
            }
        );
    }

    trySetToken(token: string) {
        localStorage.setItem(LocalStorageTokenKey, token);
        return this.http.get<DataApiResponse<User>>(`${environment.apiUrl}/user/profile`)
            .pipe(map(data => {
                    const user = data.data;
                    localStorage.setItem(LocalStorageUserKey, JSON.stringify(user));
                    user.token = token;
                    this.currentUserSubject.next(user);
                    return user;
                }),
                catchError(handleApiError));
    }
}
